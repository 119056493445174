<template>
  <div>
    <div id="cp-details" class="container">
      <div class="row mb-3">
        <div class="col-12">
          <h1 class="mb-0">
            <div v-if="form.product">
              {{ form.product.sdbProduct.title }}
            </div>
            <div v-else>
              {{ $t('i18n.headline_briefingarticle') }}
            </div>
          </h1>
          <p class="mb-4">
            {{ briefingArticle.product.sdbProduct.nationalArticleIdInclCheckDigit }}
          </p>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12 col-md-4">
          <div class="editable">
            <datepicker
              :errors="errors"
              :date="form.deadline"
              :settings="deadlineSettings"
              @updateEvent="deadlineUpdated"
            />
          </div>
        </div>

        <div class="col-12 col-md-3">
          <div class="editable">
            <label>{{ $t('i18n.headline_room') }}</label>
            <select
              id="cp-room"
              v-model="form.room"
              class="form-control"
              :class="getValidationClass('room')"
              @change="roomUpdated($event)"
            >
              <option>{{ $t('i18n.select_placeholder') }}</option>
              <option v-for="room in roomTypes" :key="room" :value="room">
                {{ room }}
              </option>
            </select>

            <div class="invalid-feedback" v-html="getErrorMsg('room')" />
          </div>
        </div>

        <div class="col-3 offset-0 offset-md-2 col-md-3">
          <img
            v-if="form.product"
            width="250"
            height="250"
            class="img-fluid"
            :src="productImageURL(form.product.sdbProduct.articleNumber)"
            @error="fallbackPlaceholder"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr class="my-5" />
          <h3>{{ $t('i18n.headline_references') }}</h3>
          <span class="text-muted small"
            ><p>{{ $t('i18n.helper_references') }}</p></span
          >

          <upload
            id="upload-content-piece"
            upload-path="briefing-article"
            view-style="Grid"
            :already-uploaded-files="briefingArticle.uploads"
            :uploadable-id="briefingArticle.id"
          />
        </div>
      </div>

      <div class="row pb-8">
        <div class="col-12">
          <hr class="my-5" />
          <h3>{{ $t('i18n.information') }}</h3>
          <textarea
            v-model="form.information"
            rows="10"
            maxlength="500"
            class="mb-4 form-control"
            :placeholder="$t('i18n.information_placeholder')"
            @change="updateBriefing"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BootstrapFormValidation from '@/mixins/BootstrapFormValidation';
import Datepicker from '@/components/shared/Datepicker';
import Upload from '@/components/shared/Upload';
import SoloBriefingUpdateForm from '@/mixins/SoloBriefingUpdateForm';
import { mapState } from 'vuex';
import Image from '@/mixins/Image';
import types from '@/data/briefing-types.json';
import { ROOM_TYPES } from '@/enum/room.enum.ts';

export default {
  name: 'BriefingArticleDetailsView',
  components: {
    Datepicker,
    Upload
  },
  mixins: [BootstrapFormValidation, Image, SoloBriefingUpdateForm],
  props: {
    id: String,
    briefingArticle: Object
  },
  data() {
    return {
      briefingTypes: types,
      briefingType: '',
      errors: {},
      resultLoading: true,
      deadlineSettings: {
        validationClass: 'deadline',
        text_label: this.$t('i18n.label_deadline'),
        text_placeholder: this.$t('i18n.placeholder_deadline'),
        text_helper: this.$t('i18n.helper_deadline')
      },
      form: {},
      roomTypes: [...ROOM_TYPES]
    };
  },
  computed: {
    ...mapState({
      teams: state => state.User.groups
    })
  },
  watch: {
    briefingArticle() {
      this.initForm();
    }
  },
  created() {
    this.$on('imageSuccessfullyUploaded', () =>
      this.$store.dispatch('BriefingArticle/GET_BY_ID', this.id)
    );
    this.$on('imageSuccessfullyDeleted', () =>
      this.$store.dispatch('BriefingArticle/GET_BY_ID', this.id)
    );
    this.initForm();
  },
  methods: {
    initForm() {
      this.briefingType = this.briefingArticle.briefingType;
      this.form = {
        id: this.briefingArticle.id,
        product: this.briefingArticle.product,
        team: this.briefingArticle.team,
        deadline: this.briefingArticle.deadline,
        information: this.briefingArticle.information,
        room: this.briefingArticle.room
      };
    },
    async updateBriefing() {
      let response = await this.update();
      if (response && response.response.status === 422) {
        this.errors = response.response.data.errors;
      }
    },

    deadlineUpdated(val) {
      this.errors = {};
      this.form.deadline = val;
      this.updateBriefing();
    },

    roomUpdated(event) {
      console.log(event.target.value);
      this.errors = {};
      this.form.room = event.target.value;
      this.updateBriefing();
    }
  }
};
</script>

<i18n>
{
  "de": {
    "i18n": {
      "headline_briefingarticle": "Artikel Briefing",
      "label_name": "Name des Content Pieces",
      "label_deadline": "Deadline",
      "placeholder_deadline": "Klicken für Datumsauswahl",
      "helper_deadline": "Die Deadline muss durch einen PM bestätigt werden.",
      "label_description": "Background-Informationen",
      "headline_references": "Referenzen (Uploads)",
      "helper_references": "Lade hier Bilder oder Dokumente hoch",
      "select_placeholder": "- Bitte wählen -",
      "headline_product": "Artikel",
      "helper_product": "Von welchem Artikel soll ein digitaler Zwilling und im Anschluss ein Produktbild erstellt werden?",
      "placeholder_product": "- Keine Artikel -",
      "headline_team": "Team",
      "helper_team": "Welches Team ist hauptsächlich für diesen Artikel verantwortlich?",
      "not_available": "Nicht mehr verfügbar durch Abhängigkeit \"{dependence}\"",
      "information": "Weitere Informationen",
      "information_placeholder": "Gib hier bitte weitere Hinweise zur Asset Erstellung an, z.B. für welches Rendering Briefing der Artikel genutzt werden soll oder dass es einen verwandten Artikel gibt oder sonstige Hinweise die uns bei der Erstellung des Artikels helfen.",
      "headline_room": "Raum"
    }
  }
}
</i18n>
