export function replaceSpecialChars(input){
  if(input){
    let value = input;
    value = value.replace(/ä/g, 'ae');
    value = value.replace(/Ä/g, 'Ae');
    value = value.replace(/ö/g, 'oe');
    value = value.replace(/Ö/g, 'Oe');
    value = value.replace(/ü/g, 'ue');
    value = value.replace(/Ü/g, 'Ue');
    value = value.replace(/ß/g, 'ss');
    value = value.replace(/´/g, '');
    value = value.replace(/`/g, '');
    value = value.replace(/[^a-z0-9 -]/gi, '');
    return value;
  } else {
    return null;
  }
}

export function internalID(input){
  if(input){
    let value = input;
    value = value.replace(/[^J0-9_]/gi, '');
    return value;
  } else {
    return null;
  }
}

