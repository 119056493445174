<template>
  <div>
    <loading v-if="resultLoading" />
    <transition name="fadein">
      <div v-if="!resultLoading">
        <briefing-article-details-view
          v-if="!briefingArticle.ordered && !resultLoading"
          :id="id"
          :briefing-article="briefingArticle"
        />
        <briefing-solo-details-print
          v-else-if="!resultLoading"
          :id="id"
          :briefing="briefingArticle"
          link-back-name="BriefingArticleList"
        />
      </div>
    </transition>
    <transition name="fadein">
      <publish-briefing-solo-bar
        v-if="!resultLoading"
        :briefing-id="briefingArticle.id"
        :ordered="briefingArticle.ordered"
        :briefing-type="briefingArticle.briefingType"
        briefing-result-name="Artikelbild Rendering(s)"
      />
    </transition>
  </div>
</template>
<script>
import Loading from '@/components/shared/Loading';
import BriefingArticleDetailsView from '@/views/briefing/briefing-article/BriefingArticleDetailsView';
import BriefingSoloDetailsPrint from '@/views/briefing/BriefingSoloDetailsPrint';
import PublishBriefingSoloBar from '@/components/shared/PublishBriefingSoloBar';

export default {
  name: "BriefingArticleDetails",
  components: {
    Loading,
    BriefingArticleDetailsView,
    BriefingSoloDetailsPrint,
    PublishBriefingSoloBar
  },
  props: {
    id: String
  },
  data() {
    return {
      resultLoading: true,
    }
  },
  computed: {
    briefingArticle() {
      return this.$store.state.BriefingArticle.selected;
    },
    readyToFinalize(){
      return !!this.briefingArticle.product
    },
  },
  async created() {
    await this.$store.dispatch('BriefingArticle/GET_BY_ID', this.id);
    this.resultLoading = false;
  },
}
</script>
