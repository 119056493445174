
import Vue from "vue";
import axios from '@/axios';
import {EventBus} from "@/event";

export default Vue.extend({
  name: "AddBatchButton",
  props: {
    projectId: String,
    team: String
  },
  data() {
    return {
      error: ''
    }
  },
  methods: {
    async createBatch(): Promise<void> {
      EventBus.$emit('loadingstatus', 'show');
      try {
        const user = this.$store.getters["User/userBackend"]
        const response = await axios.post(`/project/${this.projectId}/batch-briefing-article`, {
          user: user,
          lastModifierUser: user,
          team: this.team
        });
        EventBus.$emit('loadingstatus', 'completed');
        await this.$store.dispatch('BatchBriefingArticle/GET_BY_ID', response.data.id);
        EventBus.$emit('setCollapsedView', {id: response.data.id, value: false});
        await this.$store.dispatch('Project/SAVE_SORT_ORDER');
      } catch (error) {
        EventBus.$emit('loadingstatus', 'failed');
        if ("exception" in error?.response?.data) {
          this.error = error.response.data.message;
        } else {
          this.error = this.$t('error_unknown_exception').toString();
        }
      }
    }
  }
})
