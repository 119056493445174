
import Vue from 'vue';
import {EventBus} from "@/event";
import Loading from '@/components/shared/Loading.vue';
import AddBatchButton from '@/components/project/AddBatchButton.vue';
import {Project} from "@/entity/Project";
import BootstrapFormValidation from "@/mixins/BootstrapFormValidation.vue";
import ProjectDetailsMain from '@/components/project/ProjectDetailsMain.vue';
import BatchBriefingBlock from "@/components/project/blocks/BatchBriefingBlock.vue";
import {UserGroup} from "@/auth/user-groups";

export default Vue.extend({
  name: "ProjectDetails",
  components: {
    ProjectDetailsMain,
    Loading,
    AddBatchButton,
    BatchBriefingForm: BatchBriefingBlock
  },
  mixins: [BootstrapFormValidation],
  props: {
    id: String
  },
  data() {
    return {
      errors: {},
      resultLoading: true,
      expandBatchBriefingArticleId: '',
    }
  },
  computed: {
    teams() : UserGroup[] {
      return this.$store.state.User.groups
    },
    project() : Project {
      return this.$store.state.Project.selected;
    },
    axiosError() : string {
      return this.$store.state.Project.axiosError
    }
  },
  watch: {
    id() {
       this.getByID();
    },
    project() {
      this.resultLoading = false;
    },
  },
  async mounted(): Promise<void> {
    await this.getByID();
    if (this.$route.params.blockid) {
      this.expandBatchBriefingArticleId = this.$route.params.blockid.toString();
      EventBus.$emit('setCollapsedView', {id: this.expandBatchBriefingArticleId, value: false});
      this.scrollTo(this.expandBatchBriefingArticleId);
    }
  },
  methods: {
    async getByID(){
      await this.$store.dispatch('Project/GET_BY_ID', this.id);
    },
    scrollTo(id) {
      const ele = document.getElementById(id);
      const div = document.getElementById('main');
      if(div && ele) {
        Vue.nextTick(function () {
          div.scrollTo(ele.offsetLeft, ele.offsetTop+130);
        })
      }
    }
  }
})
