import { KeycloakClient } from "./keycloak-client"
import { KeycloakBase } from "./keycloak-base"
import { userInfoHandler } from "./user-info-handler"
import { CreateVueType } from "./keycloak-vue.types"
import { config } from "./config"
import { StoreRepository } from "./store-repository"
import { getKeycloakInstance } from "./keycloak-instance"
import { keycloakMockDefaultUser } from "./keycloak-mock-default-user";

const keycloak = getKeycloakInstance(config);

const initKeycloak = (createVue: CreateVueType, repository: StoreRepository): void => {

    let keycloakClient

    if (config.keyCloakEnabled) {
        keycloakClient = keycloakFactory(createVue, repository);
    } else {
        keycloakClient = keycloakDefaultUserFactory(createVue, repository);
    }

    keycloakClient.init().then(() => { });
}

const keycloakFactory = (createVue: CreateVueType, repository: StoreRepository): KeycloakBase => {
    return new KeycloakClient(createVue, keycloak, repository, userInfoHandler(), config);
}

const keycloakDefaultUserFactory = (createVue: CreateVueType, repository: StoreRepository): KeycloakBase => {
    keycloakMockDefaultUser.setTestUser!(config)
    return new KeycloakClient(createVue, keycloakMockDefaultUser, repository, userInfoHandler(), config);
}

export { initKeycloak, keycloak }
