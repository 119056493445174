import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
import ContentPiecesList from './views/ContentPiecesList.vue';
import BriefingList from './views/briefing/BriefingList.vue';
import ContentPieceDetails from './views/ContentPieceDetails.vue';
import BriefingDetails from './views/briefing/BriefingDetails.vue';
import Maintenance from './views/Maintenance.vue';
import BypassMaintenance from '@/components/BypassMaintenance.vue';
import Liveness from './views/Liveness.vue';
import ProjectList from './views/project/ProjectList.vue';
import ProjectDetails from './views/project/ProjectDetails.vue';
import { getCookie } from '@/cookies';

let router;
router = new VueRouter({
	routes: [
		{
			path: '/',
			redirect: { name: 'ContentPiecesList' }
		},
		{
			path: '/content-piece',
			redirect: { name: 'ContentPiecesList' }
		},
		{
			path: '/briefings',
			redirect: { name: 'ContentPiecesList' }
		},
		{
			path: '/briefings/content-piece/',
			name: 'ContentPiecesList',
			component: ContentPiecesList,
			meta: {
				title: 'List Content Pieces',
				breadcrumb: [
					{
						text: 'Alle Content Pieces',
						active: true
					}
				]
			}
		},
		{
			path: '/briefings/content-piece/:cpid',
			name: 'ContentPieceDetails',
			component: ContentPieceDetails,
			meta: {
				title: 'Content Piece'
			},
			props(route) {
				const props = { ...route.params };
				props.id = props.cpid;
				return props;
			}
		},
		{
			path: '/briefings/briefing-article/',
			name: 'BriefingArticleList',
			component: BriefingList,
			meta: {
				title: 'Alle Artikel Briefings'
			},
			props: {
				briefingType: 'BriefingArticle'
			}
		},
		{
			path: '/projects',
			name: 'ProjectList',
			component: ProjectList,
			meta: {
				title: 'List Projects',
				breadcrumb: [
					{
						text: 'Alle Projekte',
						active: true
					}
				]
			}
		},
		{
			path: '/projects/:id/:blockid?',
			name: 'ProjectDetails',
			component: ProjectDetails,
			meta: {
				title: 'Project'
			},
			props(route) {
				return { ...route.params };
			}
		},
		{
			path: '/briefings/briefing-rendering/',
			name: 'BriefingRenderingList',
			component: BriefingList,
			meta: {
				title: 'Alle Artikel Briefings'
			},
			props: {
				briefingType: 'BriefingRendering'
			}
		},
		{
			path: '/briefings/:briefingType/:briefingid',
			name: 'BriefingDetails',
			component: BriefingDetails,
			meta: {
				title: 'Artikel Briefing'
			},
			props(route) {
				const props = { ...route.params };
				props.id = props.briefingid;
				return props;
			}
		},
		{
			path: '/briefings/content-piece/:cpid/:briefingType/:briefingid',
			name: 'ContentPieceBriefingDetails',
			component: BriefingDetails,
			meta: {
				title: 'Briefing'
			}
		},
    { path: '/bypass-maintenance', component: BypassMaintenance },
    { path: '/liveness', component: Liveness, meta: {
      layout: 'print'
      } },
	]
});

router.beforeEach((to, from, next) => {
	document.title = to.meta.title + ' | BriefingTool';
	next();
});

if (process.env.VUE_APP_MAINTENANCE == 'true' && !getCookie('bypass-maintenance')) {
	router = null;

	router = new VueRouter({
		routes: [
			{ path: '*', component: Maintenance },
			{ path: '/bypass-maintenance', component: BypassMaintenance }
		]
	});
}

export default router;
