<script>
import _ from "lodash";

export default {
  name: 'Cameras',
  methods: {
    camerasToVariants(cameras) {

      if (!Array.isArray(cameras) || cameras.length === 0) return [];

      let newVariants = [];

      /*      cameras.forEach (function (camera, index) {
              camera.variants.forEach (function (variant, index) {
                variant.camera = {id: camera.id, text: camera.text};
                newVariants.push(variant);
              });
            });*/


      cameras.forEach(camera => {
        camera.variants.forEach(variant => {
          let variantIndex = newVariants.findIndex(searchVariant => searchVariant.id === variant.id);
          const newCameraForVariant = {id: camera.id, text: camera.text};
          if (variantIndex !== -1) {
            newVariants[variantIndex].cameras.push(newCameraForVariant);
          } else {
            const newVariant = {...variant};
            newVariant.cameras = [newCameraForVariant];
            newVariants.push(newVariant);
          }
        });
      });

      return newVariants;
    },
  }
}
</script>
