<template>
  <div class="row">
  <div
    v-if="product"
    class="col-6 even-odd-row"
  >
    <div class="row">
      <div class="col-4">
        <strong>Name</strong>
      </div>
      <div class="col-8">
        {{ product.sdbProduct.title }}
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <strong>Artikelnummer</strong>
      </div>
      <div class="col-8">
        {{ product.sdbProduct.nationalArticleIdInclCheckDigit }}
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <strong>Bild</strong>
      </div>
      <div class="col-8">
        <img
          :src="product.sdbProduct.imagelink"
          class="border img-fluid mb-2"
          width="150"
          alt=""
          @error="fallbackPlaceholder"
        >
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import Image from "@/mixins/Image";

export default {
  name: "PrintProduct",
  mixins: [Image],
  props: {
    product: Object
  }
}
</script>
