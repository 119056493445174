<template>
  <div class="row">
    <div class="col-6 even-odd-row">
      <div class="row">
        <div class="col-5">
          <strong>{{ $t('headline_name') }}</strong>
        </div>
        <div class="col-7">
          {{ briefing.name }}
        </div>
      </div>
      <div
        v-if="briefing.topic"
        class="row"
      >
        <div class="col-5">
          <strong>{{ $t('headline_topic') }} </strong>
        </div>
        <div class="col-7">
          {{ briefing.topic }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <strong>{{ $t('headline_team') }}</strong>
        </div>
        <div class="col-7">
          {{ briefing.team }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <strong>{{ $t('headline_room') }}</strong>
        </div>
        <div class="col-7">
          {{ briefing.room }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <strong>{{ $t('headline_deadline') }}</strong>
        </div>
        <div class="col-7">
          {{ briefing.deadline | moment('DD.MM.Y') }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <strong>{{ $t('headline_creator') }}</strong>
        </div>
        <div class="col-7">
          {{ userName }}
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-5">
          <strong>{{ $t('headline_created_at') }}</strong>
        </div>
        <div class="col-7">
          {{ briefing.created_at | moment('DD.MM.Y') }}
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-5">
          <strong>{{ $t('headline_information') }}</strong>
        </div>
        <div
          class="col-7"
          v-html="formatedInformations"
        />
      </div>
    </div>
    <div class="col-6 readmore">
      <div v-if="briefing.description">
        <p>
          <strong>{{ $t('headline_background_information') }}</strong>
        </p>

        <truncate
          :clamp="$t('more')"
          :length="200"
          class="small"
          :less="$t('less')"
          type="html"
          :text="briefing.description"
        />
      </div>

      <div
        v-if="hasFormats"
        class="mt-3"
      >
        <div
          v-for="(format, index) in briefing.formats"
          :key="index"
        >
          {{ format.name }}<br />
          {{ $t('selected_orientation') }}: {{ format.orientation }}<br />
          {{ $t('format_height') }}: {{ format.height }}px<br />
          {{ $t('format_width') }}: {{ format.width }}px<br />
          {{ $t('format_filetype') }}: {{ format.extension }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import truncate from 'vue-truncate-collapsed';
export default {
  name: 'PrintBaseInfo',
  components: {
    truncate,
  },
  props: {
    briefing: Object,
  },
  computed: {
    hasFormats() {
      if (this.briefing.formats?.length == null || this.briefing.formats?.length < 1) return false;
      return true;
    },
    userName() {
      return this.briefing?.user?.name;
    },
    formatedInformations() {
      if (!this.briefing.information) return '';
      return this.briefing.information.replace(/(?:\r\n|\r|\n)/g, '<br>');
    },
  },
};
</script>

<i18n>
{
  "de": {
    "headline_name": "Name",
    "headline_topic": "Übergeordnetes Thema",
    "headline_team": "Team",
    "headline_deadline": "Deadline",
    "headline_creator": "Ersteller",
    "headline_created_at": "Datum der Erstellung",
    "headline_background_information": "Background Informationen",
    "headline_formats": "Briefing Formate",
    "format_height": "Höhe",
    "format_width": "Breite",
    "format_filetype": "Dateityp",
    "selected_orientation": "Ausgewählte Orientierung",
    "headline_information": "Weitere Informationen",
    "more": "Mehr...",
    "less": "Weniger",
    "headline_room": "Raum"
  }
}
</i18n>
