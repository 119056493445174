<template>
  <div class="h-100">
    <topbar />
    <main id="main">
      <slot />
    </main>
    <save-status />
  </div>
</template>
<script>
    import Topbar from './shared/Topbar';
    import SaveStatus from '@/components/shared//SaveStatus';
    import {EventBus} from '@/event';

    export default {
        name: "DefaultLayout",
        components: {
            Topbar,
            SaveStatus
        }
    }
</script>
