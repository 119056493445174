import { UserGroup } from "../user-groups";
import { ConfigType, CreateVueType, UserInfoType, KeycloakVueProvider } from "./keycloak-vue.types"
import { StoreRepository } from "./store-repository";
import { UserInfoHandler } from "./user-info-handler";

class KeycloakBase {

    constructor(
        protected createVue: CreateVueType,
        protected keycloak: KeycloakVueProvider,
        protected keycloakVuex: StoreRepository,
        protected userInfoHandler: UserInfoHandler,
        protected config: ConfigType
    ) {
    }

    public async init(): Promise<void> { }

    setUserGroupsFromLoadInfo(userInfo: UserInfoType): void {
        let userGroups = this.userInfoHandler.getUserGroupsForUser(userInfo?.groups, 'keycloakName');
        this.saveUserGroups(userGroups);
    }

    saveUserGroups(userGroups: UserGroup[]): void {
        userGroups.forEach(ug => {
            this.keycloakVuex.addUserGroup(ug);
        })
    }

}

export { KeycloakBase }