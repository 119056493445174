<template>
  <div>
    <ul
      v-if="viewStyle=='List'"
      class="uploaded list-group"
    >
      <li
        v-for="(file,index) in files"
        :key="index"
        v-b-tooltip.hover.right="file.hashed_name"
        class="list-group-item listitem d-flex justify-content-between align-items-center"
      >
        <img
          :src="getThumbnailPath(file.hashed_name)"
          class="border mr-1"
          style="height:50px; width:auto;"
          @error="replaceByDefaultThumb"
        >

        <span>{{ file.file_name }}</span>
        <button
          class="btn btn-sm text-danger delete-article"
          @click="deleteUpload(file.id,index)"
        >
          <font-awesome-icon :icon="['fas', 'trash']" />
        </button>
      </li>
    </ul>


    <div
      v-if="viewStyle=='Grid'"
      class="row upload-grid"
    >
      <div
        v-for="(file,index) in files"
        :key="index"
        class="col-2"
      >
        <button
          class="btn btn-sm text-danger delete-article"
          @click="deleteUpload(file.id,index)"
        >
          <font-awesome-icon :icon="['fas', 'trash']" />
        </button>

        <img
          :src="getThumbnailPath(file.hashed_name)"
          class="border img-fluid"
          style="width:170px; height:auto;"
          :alt="file.hashed_name"
          @error="replaceByDefaultThumb"
        >

        <p class="small text-break text-muted mt-2">
          {{ file.file_name }}
        </p>
      </div>
    </div>




    <div class="upload">
      <div
        v-if="!inProgress"
        class="btn btn-primary btn-block text-white"
      >
        <span>{{ $t('i18n.button_upload') }}</span>
      </div>

      <div
        v-if="inProgress"
        class="inUpload btn-third btn btn-block"
      >
        <span>{{ $t('i18n.button_uploading') }} {{ uploadPercentage }}%</span>
        <div
          :class="progressClass"
          class="progress-bar"
          role="progressbar"
          :aria-valuenow="uploadPercentage"
          aria-valuemin="0"
          aria-valuemax="100"
          :style="{width: uploadPercentage+'%'}"
        />
      </div>

      <input
        ref="fileInput"
        type="file"
        name="upload"
        @change="handleImageUpload"
      >
    </div>
    <div
      v-if="error"
      class="alert alert-danger"
    >
      {{ error }}
    </div>
    <small class="text-third">{{ $t('i18n.text_file_restrictions') }}</small>
  </div>
</template>
<script>
	import axiosAws from 'axios';
	import axios from '@/axios';
	import clonedeep from 'lodash.clonedeep';
  import {getThumbnailPath} from "@/composables/Url";

	export default {
		name: "Upload",
		props:{
			alreadyUploadedFiles: Array,
			uploadableId: String,
			uploadPath: String,
      viewStyle: {type: String, default: 'List'}
		},
		data() {
			return {
				uploadedFileName: "",
				originalFileName: "",
				inProgress: false,
				uploadPercentage: 0,
				files: [],
				latestUpload: [],
				error: null
			}
		},
		computed:{
			progressClass(){
				let cssClass = '';
				if(this.uploadPercentage > 75){
          cssClass = 'bg-success';
				} else if (this.uploadPercentage > 25){
          cssClass = 'bg-warning';
				} else if (this.uploadPercentage < 25){
          cssClass = 'bg-danger';
				}
				return cssClass;
			}
		},
    watch: {
      alreadyUploadedFiles(){
        this.files = clonedeep(this.alreadyUploadedFiles);
      }
    },
		created() {
			if(this.alreadyUploadedFiles){
				this.files = clonedeep(this.alreadyUploadedFiles);
			}
		},
		methods: {
			async handleImageUpload() {
				try {
					let uploadedFile = this.$refs.fileInput.files[0];
					if(typeof uploadedFile == 'undefined') {
						return
					}

					if (uploadedFile.size > 1024 * 1024 * 140) {
						this.error = this.$t('i18n.error_text_file_to_big');
						return;
					}

					const fileNameArray = uploadedFile.name.split('.');
					if (!fileNameArray || fileNameArray.length === 1) {
						this.error = 'Filename must contain one dot!';
						return;
					}

					if (fileNameArray.length > 2) {
						this.error = 'Filename must contain only one dot!';
						return;
					}

					this.error = null;
					this.inProgress = true;
					this.originalFileName = uploadedFile.name;
					let fileType = uploadedFile.name.split('.').pop();
					let { preSignedUrl } = await this.getPreSignedUrlAndFileName(fileType);

					await this.uploadImageToS3(preSignedUrl, uploadedFile);
				} catch (error) {
					this.error = error;
				}
			},
			async getPreSignedUrlAndFileName(fileType){
				try {
				let result = await axios.post('upload-url', {'contentType' : fileType, 'filePath' : this.originalFileName} );
					this.uploadedFileName = result.data.fileName;
					return result.data;
				} catch (error) {
          throw new Error("Getting presigned URL failed");
				}
			},
			async uploadImageToS3(presignedUrl, uploadedFile){
				try {

				   await axiosAws.put(presignedUrl, uploadedFile, {
						headers: {
							'Content-Type': 'multipart/form-data'
						},
							onUploadProgress: ( progressEvent ) => {
							  this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))
							}
					})}
					catch (error) {
            throw new Error("Uploading to S3 failed");
					}


				try {
					this.latestUpload = {
						'file_name' : this.originalFileName,
						'hashed_name' : this.uploadedFileName
					};

					let result = await axios.post('/'+this.uploadPath+'/'+this.uploadableId+'/add-upload', this.latestUpload);

          setTimeout(() => {
            this.$parent.$emit('imageSuccessfullyUploaded', this.files);
            this.$emit('updated');
            this.files.push(result.data);
            this.inProgress = false;
            this.uploadPercentage = 0;
            this.uploadedFileName = "";

          }, 5000)

					}
					catch (error) {
						throw new Error("Uploading file failed");
					}
			},
			async deleteUpload(uploadId,index){
				axios.delete('/upload/'+uploadId)
				.then(response => response.data)
				.then(result => {
					this.files.splice(index, 1);
          this.$parent.$emit('imageSuccessfullyDeleted');
          this.$emit('updated');
				})
				.catch((msg) => {
					throw new Error(msg);
				});
			},
      getThumbnailPath(hashedName) {
        return getThumbnailPath(hashedName)
      },
      replaceByDefaultThumb(e){
        e.target.src = require('@/assets/images/no-preview.jpg');
      }
		}
	}
</script>

<style lang="scss">
	@import '~bootstrap/scss/mixins';
	@import '~bootstrap/scss/functions';
	@import '@/assets/scss/_variables';

	.uploaded {
		.list-group-item {
			padding: 0.4rem;
			font-size:0.85em;

			span {
				overflow:hidden;
				text-overflow:ellipsis;
				white-space: nowrap;
			}
		}
	}

  .upload-grid .delete-article {
    position:absolute;
    right:15px;
    background:#fff;
    border-radius: 0 0 0 10px;
    opacity:0.6;
    transition: opacity 350ms;
    &:hover {
      opacity:1;
    }
  }



	.upload {
		position: relative;

		&:hover {
			background:lighten($secondary, 3);
		}

		input {
		  position: absolute;
		  opacity: 0;
		  top: 0px;
		  bottom:0px;
		  width: 100%;
		  z-index:2;
		  cursor:pointer;
		}

		.inUpload {
			position:relative;
			span {
				z-index:2;
				position:relative;
			}
			.progress-bar {
				position:absolute;
				transition:background 500ms;
				z-index:1;
				top:0px;
				left:0px;
				right:0px;
				bottom:0px;
			}
		}

	}
</style>

<i18n>
{
  "de": {
	"i18n": {
		"button_upload": "Datei hoch laden",
		"button_uploading": "Lade hoch...",
		"text_file_restrictions": "*Maximal 140MB pro Datei",
		"error_text_file_to_big": "Die Datei ist größer als 140MB!"
	}
  }
}
</i18n>
