<template>
  <div
    class="container position-relative pb-4"
  >
    <add-briefing-button
      :briefing-type="briefingType"
    />

    <search-and-pagination :vuexModuleName="briefingType" @loading="setLoading">


      <loading v-if="resultLoading"/>


      <transition-group
        v-if="!resultLoading"
        name="fade"
      >
        <briefing-single-line
          v-for="(briefing,index) in briefings"
          :key="index"
          :style="calculatedDelay(index)"
          :briefing="briefing"
          @openLowpolyModal="openLowpolyModal"
        />
      </transition-group>

      <p class="text-center text-bold mt-5" v-if="!resultLoading && briefings.length === 0">- Keine
        Einträge gefunden -</p>

    </search-and-pagination>

    <new-briefing-lowpoly-modal
      :briefing-article-id="briefingArticleId"
    />
  </div>
</template>
<script>
import BriefingSingleLine from '@/components/briefing/BriefingSingleLine';
import Loading from '@/components/shared/Loading'
import AddBriefingButton from "@/components/briefing/AddBriefingButton";
import CalculateDelay from '@/mixins/CalculateDelay'
import NewBriefingLowpolyModal from "@/components/briefing/NewBriefingLowpolyModal"
import SearchAndPagination from "@/components/shared/SearchAndPagination";


export default {
  name: "BriefingList",
  components: {
    BriefingSingleLine,
    SearchAndPagination,
    Loading,
    AddBriefingButton,
    NewBriefingLowpolyModal
  },
  mixins: [CalculateDelay],
  props: {
    briefingType: String,
  },
  data() {
    return {
      resultLoading: true,
      briefingArticleId: null
    }
  },
  computed: {
    briefings() {
      return this.$store.state[this.briefingType].items
    }
  },
  methods: {
    openLowpolyModal(briefingArticleId) {
      this.briefingArticleId = briefingArticleId;
      this.$bvModal.show('new-briefing-lowpoly');
    },
    setLoading(value) {
      this.resultLoading = value;
    }
  }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: 500ms;
  transform: translate(0px, 0px);
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
  transform: translate(-10px, -10px);
}
</style>
