
import Vue from 'vue';
import {setCookie} from '@/cookies'

export default Vue.extend({
  name: "BypassMaintenance",
  async created() : Promise<void> {
    setCookie('bypass-maintenance', true)
  }
})
