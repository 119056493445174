<template>
  <div>
    <div
      :id="contentpiece.id"
      class="row shadow bg-white mb-4 cp mx-1 mx-sm-0"
    >
      <div
        class="col-12 col-sm-2 bg-green text-white py-3 text-right cp-info"
        :class="[contentpiece.ordered ? 'bg-success':'bg-primary']"
      >
        <h4 class="d-inline d-sm-block float-left float-sm-none">
          {{ contentpiece.internal_id }}
        </h4>
        <span class="d-inline d-sm-block"><strong>{{ contentpiece.user.name }}</strong></span>
        <span class="d-inline d-sm-none"> - </span>
        <span class="d-inline d-sm-block">{{ contentpiece.team }}</span>
        <span class="d-inline d-sm-none"> - </span>
        <span class="d-inline d-sm-block mb-2 small"><small>{{
            contentpiece.created_at | moment("DD.MM.Y")
          }}</small></span>
        <font-awesome-icon
          v-if="contentpiece.ordered"
          class="ordered"
          :icon="['fas', 'check']"
        />
        <!--<span class="cp-uuid">{{ contentpiece.id }}</span>-->
        <div
          v-if="contentpiece.ordered && contentpiece.jira_export"
          class="cp-uuid"
        >
          <a
            target="_blank"
            :href="'https://jira.obi.net/browse/'+contentpiece.jira_export.response.key"
            class="link text-white"
          >{{
              contentpiece.jira_export.response.key
            }}</a>{{
            (contentpiece.jira_export.status != null) ? ' - ' + contentpiece.jira_export.status.name : ''
          }}
        </div>
      </div>
      <div class="col-12 col-sm-10 py-3 description">
        <h4
          class="cursor-pointer d-inline"
          @click="selectContentPiece()"
        >
          {{ contentpiece.name }}
        </h4>
        <div class="cp-desc">
          {{ contentpiece.description | truncate(600, ' [...]') }}
        </div>

        <div
          v-if="contentpiece.ordered"
          class="jiraStatus"
        />


        <div
          class="cp-open"
          @click="selectContentPiece()"
        >
          <font-awesome-icon :icon="['fas', 'chevron-right']"/>
        </div>

        <div
          v-if="!contentpiece.ordered"
          class="cp-delete text-danger btn"
          @click="deleteContentPiece()"
        >
          <font-awesome-icon :icon="['fas', 'trash']"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {EventBus} from '@/event';
import axios from '@/axios';

export default {
  name: "ContentPiecesSingleLine",
  props: {
    contentpiece: Object
  },
  methods: {
    selectContentPiece() {
      return this.$router.push({name: 'ContentPieceDetails', params: {cpid: this.contentpiece.id}});
    },
    async deleteContentPiece() {
      let confirmDelete = confirm("Delete this content piece with all briefings and data?");
      if (confirmDelete) {
        EventBus.$emit('loadingstatus', 'show');
        try {
          await axios.delete('/content-pieces/' + this.contentpiece.id, '')
          await this.$store.dispatch('ContentPiece/FETCH')
          EventBus.$emit('loadingstatus', 'completed');
        } catch (error) {
          EventBus.$emit('loadingstatus', 'failed');
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '@/assets/scss/_variables';

.cp {
  position: relative;
  transition: box-shadow 500ms, margin 500ms, padding 500ms;

  // Hover animation only on larger screens
  @include media-breakpoint-up(sm) {
    &:hover {
      -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3) !important;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3) !important;
    }
  }


  .description {
    h4 {
      text-decoration: underline;
    }

    .cp-desc {
      word-break: break-word;
    }

    .cp-open {
      position: absolute;
      right: 10px;
      bottom: 10px;
      color: $third;
      cursor: pointer;
      font-size: 20px;
    }

    .cp-delete {
      position: absolute;
      top: 5px;
      right: 0px;
      opacity: 0;
      transform: translate(0, 10%);
      transition: all 500ms;
      cursor: pointer;
    }

    &:hover {
      .cp-delete {
        opacity: 1;
        transform: translate(0, 0);
      }
    }
  }

  .cp-info {

    @include media-breakpoint-up(sm) {
      min-height: 169px;
    }

    .id {
      color: lighten($primary, 20);
    }

    .cp-uuid {
      font-size: 11px;
      position: absolute;
      bottom: 5px;
      right: 5px;
    }

    .ordered {
      position: absolute;
      left: 15px;
      top: 15px;
      font-size: 25px;
    }
  }

  .cp-menu {
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 5px;
    font-size: 1.3em;
    z-index: 2;

    .dropdown-item {
      svg {
        float: right;
        margin: 3px 0 0 0;
      }
    }
  }

  .badge {
    margin: 0 2px;
  }
}
</style>
