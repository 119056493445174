<template>
  <div>
    <div class="row mb-4">
      <div class="col-12">
        <div class="form-group mt-4 mb-4">
          <div class="editable">
            <label for="cp-name">{{ $t('i18n.label_name') }}</label>
            <input
              id="cp-name"
              v-model="form.name"
              type="text"
              class="form-control"
              :class="getValidationClass('name')"
              maxlength="80"
              autocomplete="off"
            />
            <div class="invalid-feedback" v-html="getErrorMsg('name')" />
            <div class="form-text text-muted small">
              {{ $t('i18n.helper_name') }}
            </div>
          </div>
        </div>

        <div v-if="teams.length > 0" class="pb-2">
          <div class="editable">
            <label>{{ $t('i18n.headline_teams') }}</label>
            <select
              id="cp-team"
              v-model="selectedTeam"
              class="form-control"
              :class="getValidationClass('team')"
            >
              <option :value="$t('i18n.select_no_team')">
                {{ $t('i18n.select_no_team') }}
              </option>
              <option v-for="(team, index) in teams" :key="index" :value="team.name">
                {{ team.name }}
              </option>
            </select>
            <div class="form-text text-muted small">
              {{ $t('i18n.helper_teams') }}
            </div>
            <div class="invalid-feedback" v-html="getErrorMsg('team')" />
          </div>
        </div>
        <div v-else class="pb-2">
          No team available
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <div class="editable">
          <label>{{ $t('i18n.headline_room') }}</label>
          <select
            id="cp-room"
            v-model="selectedRoom"
            class="form-control"
            :class="getValidationClass('room')"
          >
            <option disabled value="">
              {{ $t('i18n.select_placeholder') }}
            </option>
            <option v-for="room in roomTypes" :key="room" :value="room">
              {{ room }}
            </option>
          </select>

          <div class="invalid-feedback" v-html="getErrorMsg('room')" />
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <div class="editable">
          <datepicker
            :errors="errors"
            :date="form.deadline"
            :settings="datepickerSettings"
            @updateEvent="deadlineUpdated"
          />
        </div>
        <div class="form-text text-muted small">
          {{ $t('i18n.helper_deadline') }}
        </div>
      </div>
    </div>

    <button :disabled="submitInProgress" class="btn btn-default float-right" @click="save">
      {{ $t('i18n.btn_save') }}
    </button>
    <div class="clearfix" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import axios from '@/axios';
import BootstrapFormValidation from '@/mixins/BootstrapFormValidation';
import Datepicker from '@/components/shared/Datepicker';
import { EventBus } from '@/event';
import { replaceSpecialChars } from '@/composables/Typo';
import { RoomType, ROOM_TYPES } from '@/enum/room.enum.ts';

export default {
  name: 'BriefingRenderingForm',
  components: {
    Datepicker
  },
  mixins: [BootstrapFormValidation],
  data() {
    return {
      form: {
        name: '',
        deadline: '',
        team: '',
        sdb_product: null
      },
      selectedTeam: this.$t('i18n.select_no_team'),
      submitInProgress: false,
      datepickerSettings: {
        id: 'ba-deadline',
        validationClass: 'deadline',
        text_label: this.$t('i18n.label_deadline'),
        text_placeholder: this.$t('i18n.placeholder_deadline')
      },
      errors: {},
      roomTypes: [...ROOM_TYPES],
      selectedRoom: RoomType
    };
  },
  computed: {
    ...mapState({
      teams: state => state.User.groups
    }),
    formWithUser() {
      return {
        content_piece_id: null,
        name: this.form.name,
        deadline: this.form.deadline,
        team: this.selectedTeam,
        user: this.$store.getters['User/userBackend'],
        room: this.selectedRoom
      };
    },
    cleanCpName() {
      return this.form.name;
    }
  },
  watch: {
    cleanCpName() {
      this.form.name = replaceSpecialChars(this.form.name);
    }
  },
  methods: {
    async save() {
      this.submitInProgress = true;

      let form = { ...this.formWithUser };

      EventBus.$emit('loadingstatus', 'show');

      try {
        let result = await axios.post('/briefing-rendering', form);
        this.$emit('hideModal');
        this.form = {};
        EventBus.$emit('loadingstatus', 'completed');
        this.submitInProgress = false;
        return this.$router.push({
          name: 'BriefingDetails',
          params: {
            briefingType: 'briefing-rendering',
            briefingid: result.data.id
          }
        });
      } catch (error) {
        this.errors = error.response.data.errors;
        EventBus.$emit('loadingstatus', 'failed');
        this.submitInProgress = false;
      }
    },
    deadlineUpdated(val) {
      this.errors = {};
      this.form.deadline = val;
    }
  }
};
</script>

keine Teamzuordnung

<i18n>
{
  "de": {
    "i18n": {
      "label_name": "Name des Briefings",
      "helper_name": "Der Name dieses Renderings (Umlaute werden automatisch ersetzt). Maximal 80 Zeichen.",
      "btn_save": "speichern",
      "label_deadline": "Deadline",
      "helper_deadline": "Die Deadline für das Rendering.",
      "placeholder_deadline": "Klicken für Datumsauswahl",
      "select_placeholder": "- Bitte wählen -",
      "select_no_team": "Keine Teamzuordnung",
      "headline_teams": "Team",
      "helper_teams": "Welches Team ist hauptsächlich für dieses Rendering verantwortlich?",
      "headline_room": "Raum"
    }
  }
}
</i18n>
