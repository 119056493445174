<template>
  <div>
    <div id="cp-details" class="container">
      <div class="container">
        <div class="row">
          <div id="br-details" class="container">
            <div class="row">
              <div class="col-12">
                <div class="mb-4">
                  <h1 v-if="briefingRendering.hasContentPiece" class="mb-4">
                    {{ $t('i18n.headline_briefing') }} Szene
                    {{ briefingRendering.scene }}
                  </h1>
                  <h1 v-else class="mb-4">
                    {{ $t('i18n.headline_briefing') }} - {{ briefingRendering.name }}
                  </h1>
                </div>
              </div>
            </div>
            <div v-if="!briefingRendering.hasContentPiece" class="mb-4">
              <div class="editable">
                <label>{{ $t('i18n.label_name') }}</label>
                <input
                  id="cp-title"
                  v-model="form.name"
                  class="form-control w-100"
                  :class="getValidationClass('name')"
                  type="text"
                  maxlength="80"
                  @change="update"
                />
                <div class="form-text text-muted small">
                  {{ $t('i18n.helper_name') }}
                </div>
                <div class="invalid-feedback" v-html="getErrorMsg('name')" />
              </div>
            </div>

            <div v-if="!briefingRendering.hasContentPiece" class="mb-4">
              <div class="editable">
                <label>{{ $t('i18n.label_topic') }}</label>
                <input
                  v-model="form.topic"
                  class="form-control w-100"
                  type="text"
                  @change="update"
                />
                <div class="form-text text-muted small">
                  {{ $t('i18n.helper_topic') }}
                </div>
                <div class="invalid-feedback" v-html="getErrorMsg('topic')" />
              </div>
            </div>

            <div class="row mb-6">
              <div class="col-4">
                <div v-if="!briefingRendering.hasContentPiece" class="pb-2">
                  <div>
                    <label>{{ $t('i18n.label_team') }}</label>
                    <select class="form-control readonly" disabled>
                      <option>{{ briefingRendering.team }}</option>
                    </select>
                    <div class="form-text text-muted small">
                      {{ $t('i18n.helper_team') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <briefing-deadline
                  :deadline-prop="form.deadline"
                  :datepicker-settings="datepickerSettings"
                  @deadlineUpdated="deadlineUpdated"
                />
              </div>
              <div class="col-4">
                <div class="editable">
                  <label>{{ $t('i18n.headline_room') }}</label>
                  <select
                    id="cp-room"
                    v-model="form.room"
                    class="form-control"
                    :class="getValidationClass('room')"
                    @change="roomUpdated($event)"
                  >
                    <option disabled value="">
                      {{ $t('i18n.select_placeholder') }}
                    </option>
                    <option v-for="room in roomTypes" :key="room" :value="room">
                      {{ room }}
                    </option>
                  </select>

                  <div class="invalid-feedback" v-html="getErrorMsg('room')" />
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <h2>{{ $t('i18n.headline_global_parameters') }}</h2>
                <p>{{ $t('i18n.description_global_parameters') }}</p>
              </div>
            </div>

            <image-formats-briefing-rendering
              :image-format-values="briefingRendering.formats"
              @updateFormats="updateFormats"
            />

            <div class="row mb-7">
              <div class="col-12">
                <label>{{ $t('i18n.label_description') }}</label>
              </div>
              <div class="col-12 mb-6">
                <div class="editable mb-4 h-100">
                  <editor
                    :initial-content="form.description"
                    :placeholder="$t('i18n.placeholder_description')"
                    :active-buttons="['bold', 'strike', 'underline', 'bullet_list', 'ordered_list']"
                    @blur="editorUpdated"
                  />
                  <span class="text-muted small"
                    ><p>{{ $t('i18n.helper_description') }}</p></span
                  >
                </div>
              </div>

              <div class="col-12 col-md-4">
                <products-bundle
                  :product-bundle="briefingRendering.product_bundle"
                  :parent-i-d="id"
                  model-to-sync-with="BriefingRendering"
                  :settings="productBundleSettings"
                />
              </div>

              <div class="col-12 col-md-4 references">
                <items-list
                  :items-prop="briefingRendering.references"
                  :settings="referenceSettings"
                />
              </div>

              <div class="col-12 col-md-4">
                <h3>{{ $t('i18n.headline_references_upload') }}</h3>
                <span class="text-muted small"
                  ><p>{{ $t('i18n.helper_references_upload') }}</p></span
                >
                <upload
                  id="upload-briefing-rendering"
                  upload-path="briefing-rendering"
                  :already-uploaded-files="briefingRendering.uploads"
                  :uploadable-id="id"
                />
              </div>

              <div class="col-12 mt-5">
                <h2>{{ $t('i18n.cameras_variants_headline') }}</h2>
                <p class="mb-5">
                  {{ $t('i18n.cameras_variants_desc') }}
                </p>
              </div>

              <div class="col-12">
                <cameras-and-variants
                  :cameras-and-variants="form.camera"
                  @updateCamerasAndVariants="updateCamerasAndVariants"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BootstrapFormValidation from '@/mixins/BootstrapFormValidation';
import Upload from '@/components/shared/Upload';
import { mapState } from 'vuex';
import Image from '@/mixins/Image';
import SoloBriefingUpdateForm from '@/mixins/SoloBriefingUpdateForm';
import ProductsBundle from '@/components/shared/ProductsBundle';
import ItemsList from '@/components/shared/ItemsList.vue';
import CamerasAndVariants from '@/components/shared/CamerasAndVariants';
import Editor from '@/components/shared/Editor.vue';
import BriefingDeadline from '@/components/shared/BriefingDeadline';
import ImageFormatsBriefingRendering from '@/views/briefing/briefing-rendering/ImageFormats';
import { replaceSpecialChars } from '@/composables/Typo';
import types from '@/data/briefing-types.json';
import { ROOM_TYPES } from '@/enum/room.enum.ts';

export default {
  name: 'BriefingRenderingDetailsView',
  components: {
    BriefingDeadline,
    Upload,
    ProductsBundle,
    ItemsList,
    CamerasAndVariants,
    Editor,
    ImageFormatsBriefingRendering
  },
  mixins: [BootstrapFormValidation, Image, SoloBriefingUpdateForm],
  props: {
    id: String,
    briefingRendering: Object
  },
  data() {
    return {
      briefingTypes: types,
      briefingType: '',
      errors: {},
      resultLoading: true,
      newBundle: false,
      items: [],
      referenceInput: '',
      referenceSettings: {
        headlinePlaceholder: this.$t('i18n.headline_references'),
        description: '<p>' + this.$t('i18n.helper_references') + '</p>',
        itemName: this.$t('i18n.placeholder_references'),
        validationClass: 'reference',
        eventName: 'referencesChanged',
        validationRules: 'required'
      },
      productBundleSettings: {
        headlinePlaceholder: this.$t('i18n.headline_additonal_articles'),
        description: '<p>' + this.$t('i18n.helper_additonal_articles') + '</p>',
        productBundleType: '0',
        canBePublished: false
      },
      datepickerSettings: {
        validationClass: 'deadline',
        text_label: this.$t('i18n.label_deadline'),
        text_helper: this.$t('i18n.helper_deadline')
      },
      roomTypes: [...ROOM_TYPES],
      form: {}
    };
  },
  computed: {
    ...mapState({
      teams: state => state.User.groups
    }),
    cleanName() {
      return this.form.name;
    },
    cleanTopic() {
      return this.form.topic;
    }
  },
  watch: {
    briefingRendering() {
      this.initForm();
    },
    cleanName() {
      this.form.name = replaceSpecialChars(this.form.name);
    },
    cleanTopic() {
      this.form.topic = replaceSpecialChars(this.form.topic);
    }
  },
  created() {
    this.$on('imageSuccessfullyUploaded', () =>
      this.$store.dispatch('BriefingRendering/GET_BY_ID', this.id)
    );
    this.$on('imageSuccessfullyDeleted', () =>
      this.$store.dispatch('BriefingRendering/GET_BY_ID', this.id)
    );
    this.$on('updateCamerasAndVariants', cameras => this.updateCameras(cameras));
    this.$on('referencesChanged', references => this.updateReferences(references));
    this.initForm();
  },
  methods: {
    initForm() {
      this.briefingType = this.briefingRendering.briefingType;
      this.form = { ...this.briefingRendering };
      this.resultLoading = false;
    },
    deadlineUpdated(val) {
      this.errors = {};
      this.form.deadline = val;
      this.update();
    },
    roomUpdated(event) {
      this.errors = {};
      this.form.room = event.target.value;
      this.update();
    },
    addReference() {
      if (this.form.references == null) {
        this.form.references = [];
      }
      this.form.references.push(this.referenceInput);
      this.referenceInput = '';
      this.update();
    },
    deleteReference(index) {
      this.form.references.splice(index, 1);
      //WIP should be removed after successful axios call
    },
    updateCamerasAndVariants(value) {
      this.form.camera = value;
      this.update();
    },
    editorUpdated(val) {
      this.form.description = val;
      this.update();
    },
    updateCameras(cameras) {
      this.form.camera = cameras;
      this.update();
    },
    updateReferences(references) {
      this.form.references = references;
      this.update();
    },
    dateFormatter(date) {
      return this.$moment(date).format('DD.MM.YYYY');
    },
    updateFormats(formats) {
      this.form.formats = formats;
      this.update();
    }
  }
};
</script>

<i18n>
{
  "de": {
    "i18n": {
      "headline_briefing": "Rendering Briefing",
      "label_name": "Name",
      "helper_name": "Der Name dieses Renderings (Umlaute werden automatisch ersetzt). Maximal 80 Zeichen.",
      "label_topic": "Übergeordnetes Thema",
      "helper_topic": "Das übergeordnete Thema dieses Renderings (Umlaute werden automatisch ersetzt)",
      "label_team": "Team",
      "helper_team": "Das Team kann  hier nicht mehr geändert werden.",
      "headline_global_parameters": "Allgemeine Parameter für alle resultierenden Briefings",
      "description_global_parameters": "Bitte wähle nachfolgend, für welchen Zweck das Rendering eingesetzt werden soll. Du kannst bei Bedarf beide Optionen auswählen und definieren.",
      "label_description": "Weitere Anweisungen:",
      "helper_description": "Hier kannst Du weitere Details oder Abweichungen für die angefragten Renderings hinzufügen (bspw. Randzuschnitt).",
      "placeholder_description": " ",
      "headline_references": "Referenzen (Links / Text)",
      "placeholder_references": "Referenz",
      "helper_references": "Welche Referenzen helfen uns dabei das bestmögliche Ergebnis für das Rendering zu erreichen?",
      "headline_additonal_articles": "Zusätzliche Artikel in der Szene",
      "helper_additonal_articles": "Welche Artikel sollen zusätzlich in die Szene eingefügt werden?",
      "placeholder_additonal_articles": "- Keine Artikel -",
      "headline_references_upload": "Referenzen (Bilder / PDF)",
      "helper_references_upload": "Lade hier Bilder oder Dokumente hoch die uns helfen diese Szene zu entwickeln",
      "cameras_variants_headline": "Beschreibe deine Szene und wähle aus, wieviele Varianten du erstellen möchtest",
      "cameras_variants_desc": "Übersicht deiner gebrieften Content Bilder.",
      "label_deadline": "Deadline",
      "helper_deadline": "Die Deadline für das Rendering.",
      "headline_room": "Raum",
      "select_placeholder": "- Bitte wählen -"
    }
  }
}
</i18n>
