
import axios from '@/axios';
import BootstrapFormValidation from '@/mixins/BootstrapFormValidation.vue';
import Datepicker from '@/components/shared/Datepicker.vue';
import {EventBus} from '@/event';
import {CreateBriefingLowPoly} from "@/entity/BriefingLowPoly";
import {UserKeycloak} from "@/entity/User";
import {UserGroup} from "@/auth/user-groups";
import Vue from "vue";

export default Vue.extend({
  name: "BriefingLowPolyForm",
  components: {
    Datepicker
  },
  mixins: [BootstrapFormValidation],
  props: {
    briefingArticleId: {type: String, default: null}
  },
  data() {
    return {
      form: {
        deadline: "",
        name: "",
      },
      selectedTeam: "",
      submitInProgress: false,
      datepickerSettings: {
        'id': 'ba-deadline',
        'validationClass': 'deadline',
        'text_label': this.$t('i18n.label_deadline'),
        'text_placeholder': this.$t('i18n.placeholder_deadline')
      },
      briefingNotExportableError: null,
      errors: {}
    }
  },
  computed: {
    teams(): UserGroup[] {
      return this.$store.state.User.groups;
    },
    user(): UserKeycloak {
      return this.$store.state.User.user;
    },
    createBriefingLowPoly(): CreateBriefingLowPoly {
      return {
        deadline: this.form.deadline,
        team: this.selectedTeam,
        user: this.$store.getters["User/userBackend"],
        type: 'PLANNING_TOOL'
      }
    },
  },
  created() {
    if (this.teams.length === 1) {
      this.selectedTeam = this.teams[0].name;
    }
  },
  methods: {
    async save(): Promise<void> {
      this.submitInProgress = true;
      EventBus.$emit('loadingstatus', 'show');

      try {
        await axios.post(`/briefing-article/${this.briefingArticleId}/create-briefing-lowpoly`, {...this.createBriefingLowPoly});
        this.$emit('hideModal');
        this.resetForm();
        EventBus.$emit('loadingstatus', 'completed');

        await this.$store.dispatch('BriefingArticle/FETCH');
        this.submitInProgress = false;
      } catch (error) {
        if (Object.prototype.hasOwnProperty.call(error?.response?.data, 'message')) {
          this.briefingNotExportableError = error.response.data.message;
        } else if (Object.prototype.hasOwnProperty.call(error.response.data, 'errors')) {
          this.errors = error.response.data.errors;
        }
        EventBus.$emit('loadingstatus', 'failed');
        this.submitInProgress = false;
      }
    },
    resetForm(): void {
      this.form = {deadline: "", name: ""};
    },
    deadlineUpdated(val: string): void {
      this.errors = {};
      this.form.deadline = val;
    }
  }
});
