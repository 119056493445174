import Joi from "joi";

export const articleSchema = Joi.object({

    locale: Joi.string(),

    '_id':  Joi.string(),

    countryArticles: Joi.array().items(
        Joi.object({
            articleNumber: Joi.string()
                .empty('')
                .default(''),

            articleNumberInclCheckDigit: Joi.string()
                .pattern(new RegExp('[0-9]{7}'))
                .required(),

            nationalArticleIdInclCheckDigit: Joi.string()
                .pattern(new RegExp('[A-Z]{2}[0-9]{7}'))
                .required(),

            articleTitle: Joi.string()
                .empty('')
                .default(''),

            country: Joi.string()
                .pattern(new RegExp('[A-Z]{2}'))
                .min(2)
                .max(2)
                .uppercase()
                .required(),

            imagelink: Joi.string(),

        })
    ),

})
