/* eslint-disable */
import { ConfigType } from "./keycloak-vue.types"

const config: ConfigType = {
    url: process.env.VUE_APP_KEYCLOAK_URL ?? "",
    realm: process.env.VUE_APP_KEYCLOAK_REALM ?? "",
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID ?? "",
    keyCloakEnabled: (typeof process.env.VUE_APP_KEYCLOAK_ENABLED === 'undefined' ? false : Boolean(JSON.parse(process.env.VUE_APP_KEYCLOAK_ENABLED as string))),
    adminAccessRole: process.env.VUE_APP_ADMIN_ACCESS_ROLE ?? 'access-cgi-service',
    useKeycloakRolesWithMappingToUserGroups: (typeof process.env.VUE_APP_USE_ROLES_WITH_MAPPING === 'undefined' ? false : Boolean(JSON.parse(process.env.VUE_APP_USE_ROLES_WITH_MAPPING as string))),
    defaultUserJsonKey: process.env.VUE_APP_DEFAULT_USER_JSON_KEY ?? "",
    refreshTokenMilliseconds: (typeof process.env.VUE_APP_REFRESH_TOKEN_MSECONDS === 'undefined' ? 5000 : parseInt(JSON.parse(process.env.VUE_APP_REFRESH_TOKEN_MSECONDS as string)))
}

const validateConfig = (config: ConfigType): string[] => {
    let errors: string[] = [];
    let keys = Object.keys(config);

    keys.forEach(key => {
        // validate empty strings
        if ((config as { [key: string]: any })[key] === '') {
            errors.push(`error in config ${key}`)
        }
    })

    return errors;

}

export { config, validateConfig }
