
import ImageFormatSelect from "@/components/shared/ImageFormatSelect.vue";
import {ImageFormat} from "@/entity/ImageFormat";
import Vue from "vue";
import ImageFormatSettings from "@/data/image-format-settings.json";

export default Vue.extend({
  name: "ImageFormats",
  components: {
    ImageFormatSelect
  },
  props: {
    imageFormatValues: {
      type: Array as () => ImageFormat[]
    }
  },
  data() {
    return {
      imageFormats: [] as ImageFormat[],
      dataLoaded: false,
      formatSettings: ImageFormatSettings
    }
  },
  watch: {
    imageFormats: {
         handler: function () {
           if (this.dataLoaded) {
             this.$emit('updateFormats', this.imageFormats)
           }
         }
       },
  },
  created() {
    this.$nextTick(() => {
      this.dataLoaded = true
    })
  },
  methods: {
    createOrUpdateFormat(imageFormat:ImageFormat): void {
      let index = this.imageFormats.findIndex((format: ImageFormat) => format.name === imageFormat.name);
      if (index === -1) {
        this.imageFormats.push(imageFormat);
      } else {
        this.imageFormats.splice(index, 1, imageFormat);
      }
    },
    deleteFormat(imageFormat:ImageFormat): void {
      let index = this.imageFormats.findIndex((format: ImageFormat) => format.name === imageFormat.name);
      if (index !== -1) {
        this.imageFormats.splice(index, 1);
      }
    },

    getValuesForFormat(formatName:string): ImageFormat | undefined {
      if(!this.imageFormatValues) return undefined;
      return this.imageFormatValues.find((format: ImageFormat) => format.name === formatName);
    }
  }
})
