<template>
  <div class="h-100 d-flex flex-column">
    <div>
      <h3>{{ settings.headlinePlaceholder }}</h3>
      <span
        class="text-muted small"
        v-html="settings.description"
      />
    </div>

    <div
      class="big-border px-3 py-3 productBundle"
      :class="[{'published':publishedStatus>0}, 'productBundle_type_' + settings.productBundleType]"
    >
      <div v-if="!productBundle">
        <span
          class="link form-control transparent create-product-bundle"
          @click="createProductsBundle()"
        >Create new product bundle +</span>
      </div>

      <div
        v-if="productBundle"
        class="product-bundle"
      >
        <div class="row mb-3">
          <div class="col-3">
            <strong>Image</strong>
          </div>
          <div class="col-3">
            <strong>Article</strong>
          </div>
          <div class="col-3 p-0 text-center">
            <strong>Qty</strong>
          </div>
        </div>

        <div
          v-for="(article,index) in items"
          :key="index"
          class="row listitem"
        >
          <div class="col-3">
            <div
              v-b-modal="''+settings.productBundleType+article.sdb_product.articleNumber+''"
              class="article-img-wrapper d-flex"
            >
              <div class="justify-content-center align-self-center">
                <img
                  class="img-fluid"
                  :src="productImageURL(article.sdb_product.articleNumber)"
                  @error="fallbackPlaceholder"
                >
                <b-modal
                  :id="settings.productBundleType+article.sdb_product.articleNumber"
                  size="lg"
                  centered
                  hide-footer
                  :title="article | getIsoSku"
                >
                  <img
                    class="img-fluid"
                    :src="productImageURL(article.sdb_product.articleNumber)"
                    @error="fallbackPlaceholder"
                  >
                </b-modal>
              </div>
            </div>
          </div>


          <div class="col-4">
            <div class="form-group">
              <p
                class="form-control transparent"
                readonly
              >
                {{ article | getIsoSku }}
              </p>
            </div>
          </div>
          <div class="col-2 p-0">
            <div class="form-group">
              <div
                v-if="publishedStatus!=2"
                class="editable"
              >
                <input
                  v-model="article.qty"
                  class="form-control"
                  type="number"
                  min="1"
                  @change="updateArticleInProductBundle(article)"
                >
              </div>
              <p
                v-else
                class="form-control"
              >
                {{ article.qty }}
              </p>
            </div>
          </div>
          <div class="col-3">
            <div
              v-if="publishedStatus!=2"
              class="form-group"
            >
              <button
                class="btn text-danger delete-article"
                @click="deleteArticleFromProductBundle(article,index)"
              >
                <font-awesome-icon :icon="['fas', 'trash']" />
              </button>
            </div>
          </div>
        </div>

        <div
          class="btn btn-block btn-sm text-white btn-primary cursor-pointer edit-productbundle"
          @click="modalShow = !modalShow"
        >
          Artikel bearbeiten
        </div>


        <b-modal
          v-model="modalShow"
          size="xl"
          centered
          :title="'Wähle deine '+ settings.headlinePlaceholder"
        >
          <div class="row">
            <div class="col-8">
              <h3>Alle {{ settings.headlinePlaceholder }}</h3>
              <iass-wrapper @articleAdded="addProductFromSearch" />
            </div>
            <div class="col-4">
              <h3>Ausgewählte {{ settings.headlinePlaceholder }}</h3>

              <div
                v-if="errors"
                class="row mb-3"
              >
                <div
                  v-for="(error,index) in errors"
                  :key="index"
                >
                  {{ error }}
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-3">
                  <strong>Image</strong>
                </div>
                <div class="col-3">
                  <strong>Article</strong>
                </div>
                <div class="col-4 p-0 text-center">
                  <strong>Qty</strong>
                </div>
              </div>


              <div
                v-for="(article,index) in items"
                :key="index"
                class="row listitem"
              >
                <div class="col-3">
                  <div
                    v-b-modal="''+settings.productBundleType+article.sdb_product.articleNumber+''"
                    class="article-img-wrapper d-flex"
                  >
                    <div class="justify-content-center align-self-center">
                      <img
                        class="img-fluid"
                        :src="productImageURL(article.sdb_product.articleNumber)"
                        @error="fallbackPlaceholder"
                      >
                      <b-modal
                        :id="'inmodal'+settings.productBundleType+article.sdb_product.articleNumber"
                        size="lg"
                        centered
                        hide-footer
                        :title="article | getIsoSku"
                      >
                        <img
                          class="img-fluid"
                          :src="productImageURL(article.sdb_product.articleNumber)"
                          @error="fallbackPlaceholder"
                        >
                      </b-modal>
                    </div>
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group">
                    <p
                      class="form-control transparent"
                      readonly
                    >
                      {{ article | getIsoSku }}
                    </p>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <div class="editable">
                      <input
                        v-model="article.qty"
                        class="form-control"
                        type="number"
                        min="1"
                        @change="updateArticleInProductBundle(article)"
                      >
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <button
                      class="btn text-danger delete-article"
                      @click="deleteArticleFromProductBundle(article,index)"
                    >
                      <font-awesome-icon :icon="['fas', 'trash']" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-slot:modal-footer="{ ok}">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <div
              class="btn btn-primary text-white"
              @click="ok()"
            >
              OK
            </div>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios';
import {EventBus} from '@/event';
import BootstrapFormValidation from '@/mixins/BootstrapFormValidation';
import clonedeep from 'lodash.clonedeep';
import IassWrapper from "@/components/shared/article-broker/iass-wrapper";
import Image from "@/mixins/Image";

export default {
  name: "ProductsBundle",
  components: {IassWrapper},
  mixins: [BootstrapFormValidation, Image],
  props: {
    productBundle: Object,
    parentID: String,
    modelToSyncWith: String,
    settings: {
      type: Object,
      default: () => ({
        productBundleType: null,
        headlinePlaceholder: null,
        description: null,
        canBePublished: false
      })
    }
  },
  data() {
    return {
      items: [],
      newItem: {'sdbProduct': {}, 'qty': 0},
      errors: [],
      publishedStatus: 0,
      modalShow: false
    }
  },
  created() {
    if (this.productBundle != null) {
      this.items = JSON.parse(JSON.stringify(this.productBundle.products));
      this.publishedStatus = this.productBundle.published;
    }
  },
  methods: {
    async createProductsBundle() {
      let params = {
        'syncWithModel': this.modelToSyncWith,
        'id': this.parentID,
        'type': this.settings.productBundleType
      }
      EventBus.$emit('loadingstatus', 'show');
      try {
        await axios.post('/product-bundles', params);
        EventBus.$emit('loadingstatus', 'completed');
        await this.$store.dispatch(`${this.modelToSyncWith}/GET_BY_ID`, this.parentID);
      } catch (error){
        EventBus.$emit('loadingstatus', 'failed');
      }
    },
    async saveArticleInProductBundle() {
      EventBus.$emit('loadingstatus', 'show');
      this.errors = [];
      try {
        let result = await axios.post('/product-bundles/' + this.productBundle.id + '/add-product', this.newItem);
        this.items = result.data;
        this.resetItem();
        EventBus.$emit('loadingstatus', 'completed');
      } catch (error) {
        this.errors = error.response.data.errors;
        EventBus.$emit('loadingstatus', 'failed');
      }
    },
    async deleteArticleFromProductBundle(article, index) {
      EventBus.$emit('loadingstatus', 'show');
      try {
        await axios.delete('/product-bundles/' + this.productBundle.id + '/delete-product/' + article.id);
        this.$delete(this.items, index);
        EventBus.$emit('loadingstatus', 'completed');
      } catch (err) {
        EventBus.$emit('loadingstatus', 'failed');
      }
    },
    async updateArticleInProductBundle(article) {
      EventBus.$emit('loadingstatus', 'show');
      try {
        await axios.put('/product-bundles/' + this.productBundle.id + '/update-product/' + article.id, article)
        EventBus.$emit('loadingstatus', 'completed');
      } catch {
        EventBus.$emit('loadingstatus', 'failed');
      }
    },
    setNewItem(articleData) {
      this.newItem.sdbProduct = clonedeep(articleData);
      this.newItem.qty = 1;
    },
    async addProductFromSearch(article) {
      this.errors = [];
      try {
        this.setNewItem(article);
        await this.saveArticleInProductBundle();
      } catch (err) {
        this.errors.push(err);
      }
    },
    resetItem() {
      this.newItem.sdbProduct = {};
      this.newItem.qty = 1;
      this.errors = [];

    }
  }
}
</script>

<style lang="scss" scoped>

.productBundle {
  position: relative;

  .lock-layer {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background: transparentize($body-bg, 0.3);
    z-index: 10;
    font-size: 40px;
    color: $third;
  }

  &.published {
    .form-control {
      border-color: transparent;
    }
  }
}

.article-broker {
  max-height: 60vh;
  overflow: auto;
}

.modal-backdrop {
  opacity: 0.5;
}

.article-img-wrapper {
  max-height: 36px;
  overflow: hidden;
  background: #fff;
  height: 100%;
  cursor: pointer;
}

</style>

<i18n>
{
"de": {
"i18n": {
"btn_save": "Speichern und veröffentlichen",
"btn_edit": "Artikel bearbeiten",
"btn_unlock": "Entsperren und bearbeiten",
"helper_locked" : "Dieses Bundle wurde bereits veröffentlicht"
}
}
}
</i18n>
