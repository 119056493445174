<template>
  <div
    class="briefing-tile col-12 col-md-4 col-lg-3"
  >
    <div
      class="tile p-2 mb-4"
      @click="openBriefing"
    >
      <div
        class="briefing-tile-wrapper mb-2 bg-third"
        :class="'briefing-'+briefingType"
        data-test="rendering-wrap"
      >
        <img
          :src="require(`@/assets/images/${briefingTypes[briefingType].img}`)"
          class="img-fluid img-top"
          alt=""
        >
        <div class="info name">
          {{ briefingTypes[briefingType].title }}
        </div>
        <div class="info date">
          <small>{{ briefing.created_at | moment("D.MM.Y") }}</small>
        </div>

        <div
          class="briefing-delete btn-danger btn"
          @click.stop="deleteBriefing()"
        >
          <font-awesome-icon :icon="['fas', 'trash']"/>
        </div>

        <div
          v-if="briefing.send==1"
          v-tooltip.top="$t('i18n.helper_locked')"
          class="lock-layer"
        >
          <div class="row h-100 align-items-center">
            <div class="col-12 text-center">
              <font-awesome-icon :icon="['fas', 'lock']"/>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="briefingType=='rendering' || briefingType=='show_flat'"
        class="p-2"
        data-test="br-wrap"
      >
        <h5>
          {{ briefing.name ? briefing.name : briefingTypes[briefingType].title }} Szene
          {{ briefing.scene }}
        </h5>
        <div v-html="description"/>
      </div>
    </div>
  </div>
</template>

<script>
import types from "@/data/briefing-types.json";
import {EventBus} from '@/event';
import axios from '@/axios';

export default {
  name: "BriefingTile",
  props: {
    briefing: Object,
    briefingType: String
  },
  data() {
    return {
      briefingTypes: types
    }
  },
  computed: {
    description() {
      if (!this.briefing.description || this.briefing.description == "<p></p>") {
        return "<p>" + this.$t('i18n.placeholder_no_description') + "</p>"
      } else {
        return this.$options.filters.truncate(this.briefing.description, 100, ' [...]')
      }
    }
  },
  methods: {
    openBriefing() {
      return this.$router.push({
        name: 'ContentPieceBriefingDetails',
        params: {
          cpid: this.briefing.content_piece_id,
          briefingType: this.briefingTypes[this.briefingType].createRoute,
          briefingid: this.briefing.id
        }
      })
    },
    async deleteBriefing() {
      let confirmDelete = confirm("Delete this briefing?");
      if (confirmDelete === true) {
        EventBus.$emit('loadingstatus', 'show');
        try {
          await axios.delete(`/${this.briefingTypes[this.briefingType].createRoute}/${this.briefing.id}`);
          EventBus.$emit('loadingstatus', 'completed');
          this.$emit("reloadContentpiece", true);

        } catch (e) {
          EventBus.$emit('loadingstatus', 'failed');
        }
      }
    }
    ,
  }
}
</script>

<style lang="scss">

@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '@/assets/scss/_variables';

.tile {
  cursor: pointer;
  transition: 500ms;

  .briefing-tile-wrapper {
    position: relative;

    div.info {
      position: absolute;
      color: #fff;

      &.name {
        top: 5px;
        left: 10px;
      }

      &.date {
        bottom: 5px;
        right: 10px;
      }
    }

    .briefing-delete {
      position: absolute;
      top: 0px;
      right: 0px;
      opacity: 0;
      transition: opacity 500ms;
      cursor: pointer;
    }

    &:hover {
      .briefing-delete {
        opacity: 1;
      }
    }

    .lock-layer {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
      background: rgba(0, 0, 0, 0.4);
      font-size: 40px;
      color: $secondary;
    }
  }
}
</style>

<i18n>
{
  "de": {
    "i18n": {
      "helper_locked": "Das Briefing wurde abgesendet und kann nicht mehr länger editiert werden",
      "placeholder_no_title": "Kein Titel",
      "placeholder_no_description": "Dieses Briefing hat keinen bisher noch keinen Text."
    }
  }
}
</i18n>
