import { UserInfoType } from './keycloak-vue.types';
import { KeycloakBase } from './keycloak-base';
import { KeycloakResourceAccess } from 'keycloak-js';

class KeycloakClient extends KeycloakBase {
  setKeycloakRoles = true;
  setKeycloakGroups = false;

  async init(): Promise<void> {
    this.keycloakVuex.setKeycloakError('');

    try {
      let auth = await this.keycloak.init({
        promiseType: 'native',
        onLoad: 'login-required',
        checkLoginIframe: false
      });

      if (!auth) {
        this.keycloakVuex.deleteUserFromStore();
        this.keycloakVuex.setKeycloakError('Keycloak authentication failed. No auth.');
        this.createVue();
      } else {
        await this.extractUserInfoAndSetItToStore();
        this.setKeycloakRefreshInterval();
      }
    } catch (error) {
      this.keycloakVuex.setKeycloakError('Keycloak authentication failed.');
    }
  }

  setUserGroupsFromAccessResource(resourceAccess: KeycloakResourceAccess): void {
    let clientId = this.config.clientId;

    if (
      !resourceAccess.hasOwnProperty(clientId) ||
      !resourceAccess![clientId].hasOwnProperty('roles')
    ) {
      this.keycloakVuex.setKeycloakError('Your role is not configured in keycloak.');
      this.createVue();
      return;
    }

    let roles: string[] | undefined = resourceAccess![clientId].roles;

    if (roles.findIndex(role => role === this.config.adminAccessRole) === -1) {
      this.keycloakVuex.setKeycloakError('Your access role is not configured in keycloak.');
      this.createVue();
      return;
    }

    if (roles) {
      let userGroups;

      if (this.config.useKeycloakRolesWithMappingToUserGroups) {
        userGroups = this.userInfoHandler.getUserGroupsForUser(roles, 'name');
      } else {
        userGroups = this.userInfoHandler.formatRolesToUserGroupsWithoutMapping(roles);
      }

      this.saveUserGroups(userGroups);
    }
  }

  async extractUserInfoAndSetItToStore(): Promise<void> {
    let userInfo: UserInfoType = await this.keycloak.loadUserProfile();

    this.keycloakVuex.setUserInfo(
      this.userInfoHandler.formatUserObject(userInfo, this.keycloak.subject as string)
    );

    this.keycloakVuex.setToken(this.keycloak.token as string, this.keycloak.refreshToken as string);

    if (this.setKeycloakRoles && this.keycloak.hasOwnProperty('resourceAccess')) {
      this.setUserGroupsFromAccessResource(this.keycloak.resourceAccess!);
    }

    if (this.setKeycloakGroups) {
      let userInfo: UserInfoType = await this.keycloak.loadUserInfo();
      this.setUserGroupsFromLoadInfo(userInfo);
    }

    this.createVue();
  }

  async refreshToken(): Promise<void> {
    let refreshed = await this.keycloak.updateToken(5);

    if (refreshed) {
      this.keycloakVuex.setToken(
        this.keycloak.token as string,
        this.keycloak.refreshToken as string
      );
    } else {
      if (this.keycloak.tokenParsed?.exp !== undefined && this.keycloak?.timeSkew !== undefined) {
        //console.log('Token not refreshed, valid for ' + Math.round(this.keycloak.tokenParsed.exp + this.keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
      }
    }
    setTimeout(async () => {
      await this.refreshToken();
    }, this.config.refreshTokenMilliseconds);
  }

  setKeycloakRefreshInterval(): void {
    this.refreshToken().then();
  }
}

export { KeycloakClient };
