import { StoreFactory } from './BriefingBase';

const briefingURL = 'briefing-rendering';

const formatSingle = briefing => {
  briefing.listTitle = briefing.name;
  briefing.briefingType = 'BriefingRendering';
  briefing.apiURL = `/${briefingURL}/${briefing.id}`;
  briefing.detailViewURL = `/briefings/${briefingURL}/${briefing.id}`;
  briefing.listURL = `/briefings/${briefingURL}`;
};

const baseStore = StoreFactory.get(briefingURL, formatSingle);

export default {
  ...baseStore
};
