
import Vue from 'vue';
import { ProductList } from '@/entity/Product';
import axios from '@/axios';
import { EventBus } from '@/event';
import { BatchBriefingArticle } from '@/entity/BatchBriefingArticle';
import Uploads from '@/components/shared/Uploads.vue';
import { ImageFormat } from '@/entity/ImageFormat';

export default Vue.extend({
  name: 'ConfirmModal',
  components: {
    Uploads
  },
  props: {
    briefing: { type: Object as () => BatchBriefingArticle },
    modalOpen: { type: Boolean, default: false }
  },
  data() {
    return {
      productList: [] as ProductList[],
      showModal: false
    };
  },
  computed: {
    productListError() {
      let error = false;
      this.productList.forEach(product => {
        if (product.briefingArticleId || product.briefingLowPolyId) {
          error = true;
        }
      });
      return error;
    },
    errors() {
      let errors: Array<string> = [];

      if (!this.briefing.deadline) {
        errors.push(this.$t('error_deadline').toString());
      }

      if (this.productListError) {
        errors.push(this.$t('article_briefing_exists_error').toString());
      }

      if (this.productList.length === 0) {
        errors.push(this.$t('error_product_list_empty').toString());
      }

      if (!this.briefing.room) {
        errors.push(this.$t('error_room').toString());
      }

      return errors;
    }
  },
  watch: {
    modalOpen(newValue, oldValue) {
      if (newValue === true) {
        this.triggerShowModal();
      }
    },
    showModal(newValue, oldValue) {
      if (newValue === false) {
        this.$emit('closeConfirmModal');
      }
    }
  },
  methods: {
    async triggerShowModal() {
      await this.fetchProjectList();
      this.showModal = true;
    },
    imgUrl(articleNumber) {
      return (
        '//images.obi.info/file_db/services/serve_product.php?firma=6&article_nr=' + articleNumber
      );
    },
    imgPlaceholder(e) {
      e.target.src = require('@/assets/images/fallback.jpg');
    },
    async orderBatchBriefingArticle() {
      EventBus.$emit('loadingstatus', 'loading');
      if (this.errors.length === 0) {
        try {
          await axios.get<ProductList[]>(`/batch-briefing-article/${this.briefing.id}/export`);
          await this.$store.dispatch('BatchBriefingArticle/GET_BY_ID', this.briefing.id);
          this.showModal = false;
          EventBus.$emit('loadingstatus', 'completed');
        } catch (error) {
          EventBus.$emit('loadingstatus', 'failed');
        }
      }
    },
    async fetchProjectList(): Promise<void> {
      try {
        const { data } = await axios.get<ProductList[]>(
          `/batch-briefing-article/${this.briefing.id}/product-list`
        );
        this.productList = data;
        EventBus.$emit('loadingstatus', 'completed');
      } catch (error) {
        // this.errors = error.response.data.errors;
        EventBus.$emit('loadingstatus', 'failed');
      }
    }
  }
});
