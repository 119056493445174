<template>
  <div>
    <div
      v-if="errorMessage"
      class="alert alert-danger"
    >
      {{ errorMessage }}
    </div>
    <draggable
      v-model="instructionList"
      handle=".handle"
      @change="instructionsSorted"
    >
      <transition-group>
        <div
          v-for="(instruction,index) in instructionList"
          :key="instruction.id"
          class="mb-3 instruction-item"
        >
          <div class="row instruction">
            <div class="col-7">
              <div class="editable mb-3">
                <h5>
                  <input
                    v-model="instruction.title"
                    class="transparent w-100"
                    :class="{'my-3': instruction.title}"
                    name="title"
                    type="text"
                    @change="update(instruction)"
                  >
                </h5>
              </div>
            </div>
          </div>
          <div class="row instruction">
            <div class="col-1 text-center my-auto">
              <span class="bg-third step text-white">{{ index + 1 }}</span>
            </div>
            <div class="col-6">
              <div class="editable h-100">
                <resizeable-textarea>
                  <textarea
                    v-model="instruction.description"
                    class="w-100 h-100 resize-none"
                    :placeholder="$t('i18n.helper_description')"
                    @change="update(instruction)"
                  />
                </resizeable-textarea>
              </div>
            </div>
            <div class="col-3">
              <upload
                upload-path="instructions"
                :already-uploaded-files="instruction.uploads"
                :uploadable-id="instruction.id"
              />
            </div>
            <div class="col-1 text-center">
              <button
                class="btn text-white btn-danger delete-instruction"
                @click="deleteInstruction(instruction.id, index)"
              >
                <font-awesome-icon :icon="['fas', 'trash']" />
              </button>
            </div>
            <div class="col-1 text-center">
              <div class="btn btn-outline-third handle">
                <font-awesome-icon :icon="['fas', 'bars']" />
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </draggable>
    <hr>
    <!-- New entry -->
    <div class="row instruction new-instruction">
      <div class="col-9 offset-1">
        <div class="editable mb-3">
          <input
            id="new_headline"
            v-model="newInstruction.title"
            class="w-100"
            type="text"
            :placeholder="$t('i18n.helper_headline')"
          >
        </div>
        <div class="editable">
          <resizeable-textarea>
            <textarea
              id="new_instruction"
              v-model="newInstruction.description"
              class="w-100 resize-none"
              :placeholder="$t('i18n.helper_description')"
            />
          </resizeable-textarea>
        </div>
      </div>
      <div class="col-2 my-auto">
        <button
          class="btn btn-primary text-white add_new_instruction"
          @click="addInstruction"
        >
          {{ $t('i18n.button_add_instruction') }}
          <font-awesome-icon :icon="['fas', 'plus']" />
        </button>
      </div>
    </div>
  </div>
</template>


<style lang="scss">
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '@/assets/scss/_variables';

.instruction-item {
  .handle {
    cursor: move;
  }

  &.sortable-ghost {
    border: 1px dashed $third;

    > div {
      opacity: 0.0
    }
  }

  &.sortable-chosen {
    background: $gray-100;
  }
}
</style>

<script>
import axios from '@/axios';
import clonedeep from 'lodash.clonedeep';
import ResizeableTextarea from '@/components/shared/ResizeableTextarea.vue';
import Upload from '@/components/shared/Upload.vue'
import {EventBus} from '@/event';
import draggable from 'vuedraggable'
import SanitizeInput from "@/mixins/SanitizeInput";

export default {
  name: "Instruction",
  components: {
    ResizeableTextarea,
    Upload,
    draggable
  },
  mixins: [SanitizeInput],
  props: {
    instructions: Array,
    contentPieceID: String
  },
  data() {
    return {
      'errorMessage': null,
      'newInstruction': {
        'title': null,
        'description': null,
        'sort_order': null
      },
      'instructionList': [],
    }
  },
  computed: {
    newSortPosition() {
      if (this.instructionList.length > 0) {
        return this.instructionList.slice(-1).pop().sort_order + 1;
      } else {
        return 0;
      }
    }
  },
  watch: {
    instructions() {
      this.cloneInstructions(this.instructions);
    }
  },
  created() {
    this.cloneInstructions(this.instructions);
    this.$on('imageSuccessfullyUploaded', (imagePath) => this.setUploadedImageName(imagePath));
  },
  methods: {
    async instructionsSorted(event) {
      await this.updateSortOrder();
    },
    async updateSortOrder() {
      this.errorMessage = '';
      EventBus.$emit('loadingstatus', 'show');
      try {
        await axios.post('/instructions/sort-order', this.buildSortOrderParams());
        EventBus.$emit('loadingstatus', 'completed');
      } catch (e) {
        this.errorMessage = "An error occured while updating the sort order of instructions.";
      }
    },
    buildSortOrderParams() {
      let params = {};
      params.instructions = this.instructionList.map((instruction, index) => {
        return {id: instruction.id, sort_order: index};
      });
      return params;
    },
    async update(instruction) {
      EventBus.$emit('loadingstatus', 'show');

      let data = instruction;
      try {
        await axios.put('/instructions/' + instruction.id, data);
        EventBus.$emit('loadingstatus', 'completed');
      } catch (e) {
        EventBus.$emit('loadingstatus', 'failed');
      }
    },
    async deleteInstruction(instructionID, index) {
      EventBus.$emit('loadingstatus', 'show');

      try {
        await axios.delete('/instructions/' + instructionID)
        this.$delete(this.instructionList, index);
        EventBus.$emit('loadingstatus', 'completed');
        await this.updateSortOrder();
      } catch (e) {
        EventBus.$emit('loadingstatus', 'failed');
      }
    },
    async addInstruction() {
      EventBus.$emit('loadingstatus', 'show');

      this.newInstruction.sort_order = this.newSortPosition;

      this.newInstruction.title = this.sanitize(this.newInstruction.title)
      this.newInstruction.description = this.sanitize(this.newInstruction.description)

      let data = {...this.newInstruction};

      try {
        let result = await axios.post('/content-pieces/' + this.contentPieceID + '/add-instruction', data);
        EventBus.$emit('loadingstatus', 'completed');
        this.instructionList.push(result.data);
        this.resetNewInstruction();

      } catch (e) {
        EventBus.$emit('loadingstatus', 'failed');
      }
    },
    resetNewInstruction() {
      this.newInstruction.title = null;
      this.newInstruction.description = null;
      this.newInstruction.sort_order = null;
    },
    cloneInstructions(instructions) {
      if (this.instructions) {
        this.instructionList = clonedeep(instructions);
      }
    },
    setUploadedImageName(file) {
      this.newImage = file;
    }
  }
}
</script>

<i18n>
{
  "de": {
    "i18n": {
      "helper_headline": "Überschrift für den / die nächsten Schritt(e)",
      "helper_description": "Was muss in diesem Schritt getan werden?",
      "button_add_instruction": "Schritt anlegen"
    }
  }
}
</i18n>
