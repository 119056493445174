import { ConfigType, UserInfoType } from "../keycloak-vue.types";
import { UserReader } from "./user-reader";

export interface UserHandler {
    readUser(): void;
    getTestUser(): UserInfoType
}

export class TestUserHandler implements UserHandler {

    protected testUser: UserInfoType = {} as UserInfoType;
    protected roles: string[] = [];

    constructor(protected userReader: UserReader, protected config: ConfigType) { }

    readUser(): void {
        const user = this.userReader.fetchSelectedUser(this.config.defaultUserJsonKey);
        if (typeof user == "undefined") {
            throw new Error("Check your config. No matching default user by key in json file found.")

        }
        this.testUser = user;
        this.roles = user.roles as string[];
    }

    getTestUser(): UserInfoType {
        return { ...this.testUser };
    }

    getRoles(): string[] {
        return [...this.roles];
    }
}