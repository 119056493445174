import Vue from 'vue'
import Vuex from 'vuex'
import ContentPiece from './modules/ContentPiece';
import BriefingArticle from './modules/BriefingArticle';
import BriefingRendering from './modules/BriefingRendering'
import User from './modules/User';
import Project from './modules/Project'
import BatchBriefingArticle from './modules/BatchBriefingArticle'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    keycloakError: null
  },
  mutations: {
    SET_KEYCLOAK_ERROR(state, payload) {
      state.keycloakError = payload;
    }
  },
  actions: {},
  modules: {
    Project: Project,
    ContentPiece: ContentPiece,
    BriefingArticle: BriefingArticle,
    BriefingRendering: BriefingRendering,
    User: User,
    BatchBriefingArticle: BatchBriefingArticle
  },
  strict: true
})
