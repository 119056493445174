<template>
  <div>
    <div class="row">
      <div
        v-for="(image,index) in resultedImages"
        :key="index"
        class="col-3 mb-4"
      >
        <div class="border h-100 p-2 image">
          <div class="icons-left">
            <div
              v-b-tooltip.hover
              class="btn btn-sm mr-2 btn-primary"
              :title="$t('i18n.edit_image')"
              @click="openEditImageModal(image)"
            >
              <font-awesome-icon
                class="text-white"
                :icon="['fas', 'pencil-alt']"
              />
            </div>

            <div
              v-b-tooltip.hover
              class="btn btn-sm btn-primary"
              :title="$t('i18n.duplicate_image')"
              @click="openClonedImageModal(image)"
            >
              <font-awesome-icon
                class="text-white"
                :icon="['fas', 'copy']"
              />
            </div>
          </div>
          <div class="icons-right">
            <div
              v-b-tooltip.hover
              class="btn btn-sm mr-2 btn-danger"
              :title="$t('i18n.delete_image')"
              @click="deleteVariantInCamera(image)"
            >
              <font-awesome-icon
                class="text-white"
                :icon="['fas', 'times']"
              />
            </div>
          </div>

          <img
            class="img-fluid mb-2"
            src="@/assets/images/image-placeholder.jpg"
            alt=""
          >
          <div class="h5">
            <div
              v-b-tooltip.hover
              class="badge badge-warning mr-2"
            >
              {{ $t('i18n.variant') }} {{ image.variant.id }}
            </div>
            <div
              v-b-tooltip.hover
              :title="image.camera.text"
              class="badge badge-info"
            >
              {{ $t('i18n.camera') }} {{ image.camera.id }}
            </div>
          </div>
        </div>
      </div>


      <div class="col-3 mb-4">
        <div
          class="h-100 p-2 border d-flex text-center mb-4"
          style="outline:none !important"
          @click="openNewImageModal()"
        >
          <div class="flex-grow-1 align-self-center cursor-pointer">
            <font-awesome-icon
              class="text-third"
              style="font-size:5em;"
              :icon="['fas', 'plus']"
            />
            <div class="mt-3 d-block text-third">
              {{ $t('i18n.new_image') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="new-image"
      :title="$t('i18n.new_image_headline')"
      hide-footer
      no-close-on-backdrop
      scrollable
      centered
    >
      <p v-if="disabledCamera">
        {{ $t('i18n.new_image_desc_without_camera') }}
      </p>
      <p v-else>
        {{ $t('i18n.new_image_desc_with_camera') }}
      </p>

      <select
        v-if="variants.length>0"
        v-model="form.variant.id"
        class="form-control mb-4"
      >
        <option
          v-for="(variant,index) in variants"
          :key="index"
          :value="variant.id"
        >
          {{ $t('i18n.variant') }} {{ variant.id }}
        </option>
        <option :value="maxVariant">
          {{ $t('i18n.create_new_variant') }}
        </option>
      </select>

      <div v-if="maxVariant==1 || form.variant.id==maxVariant">
        <label>Variante {{ maxVariant }}</label>
        <textarea
          v-model="form.variant.text"
          rows="10"
          class="mb-4 form-control"
          :placeholder="$t('i18n.new_variant_desc')"
        />
      </div>

      <select
        v-if="clonedCamerasAndVariants.length>0"
        v-model="form.camera.id"
        class="form-control mb-4"
        :class="{'d-none':disabledCamera}"
        :disabled="disabledCamera"
      >
        <option
          v-for="(camera,index) in clonedCamerasAndVariants"
          :key="index"
          :value="camera.id"
        >
          {{ $t('i18n.camera') }} {{ camera.id }}: {{ camera.text }}
        </option>
        <option :value="maxCamera">
          {{ $t('i18n.create_new_camera') }}
        </option>
      </select>

      <div v-if="(maxCamera==1 || form.camera.id==maxCamera) && !disabledCamera">
        <label>{{ $t('i18n.camera') }} {{ maxCamera }}</label>
        <input
          v-model="form.camera.text"
          type="text"
          class="form-control"
          :placeholder="$t('i18n.new_camera_desc')"
        >
      </div>

      <b-button
        v-if="!combinationExists"
        class="mt-4 float-right text-white"
        size="sm"
        variant="primary"
        :disabled="!newImageFormValidated"
        @click="addVariantAndCamera()"
      >
        {{ $t(saveButtonText) }}
      </b-button>
      <div
        v-else
        class="alert alert-warning"
      >
        {{ $t('i18n.combination_exists') }}
      </div>
    </b-modal>


    <b-modal
      id="edit-image"
      :title="$t('i18n.edit_image')"
      hide-footer
      no-close-on-backdrop
      scrollable
      centered
    >
      <div class="form-group">
        <label>{{ $t('i18n.variant') }} {{ form.variant.id }}</label>
        <textarea
          v-model="form.variant.text"
          rows="10"
          class="form-control"
          :placeholder="$t('i18n.new_variant_desc')"
        />
        <small>{{ $t('i18n.edit_variant_desc') }}</small>
      </div>

      <div
        class="form-group"
        :class="{'d-none':disabledCamera}"
      >
        <label>{{ $t('i18n.camera') }} {{ form.camera.id }}</label>
        <input
          v-model="form.camera.text"
          type="text"
          class="form-control"
          :placeholder="$t('i18n.new_camera_desc')"
          :disabled="disabledCamera"
        >
        <small>{{ $t('i18n.edit_camera_desc') }}</small>
      </div>

      <b-button
        class="mt-4 float-right text-white"
        size="sm"
        variant="primary"
        :disabled="!editImageFormValidated"
        @click="saveChangesAfterEdit()"
      >
        {{
          (!editImageFormValidated) ? $t('i18n.fill_out_mendatory_fields') : $t('i18n.save_changes')
        }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "CamerasAndVariants",
  props: {
    camerasAndVariants: {
      type: Array,
      default: Array
    },
    disabledCamera: {
      type: Boolean,
      default: false
    },
    standardCameraName: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      images: [],
      dataLoaded: false,
      form: {
        camera: {
          'id': 0,
          'text': null
        },
        variant: {
          'id': 0,
          'text': null
        }
      },
      items: [],
      inputField: '',
      clonedCamerasAndVariants: []
    }
  },
  computed: {
    resultedImages() {
      let images = [];

      this.clonedCamerasAndVariants.forEach(function (camera) {
        camera['variants'].forEach(function (variant) {

          let cameraAndVariant = {
            'camera': {
              'id': camera['id'],
              'text': camera['text']
            },
            'variant': {
              'id': variant['id'],
              'text': variant['text']
            }
          }
          images.push(cameraAndVariant)
        })

      }.bind(this));

      return images;
    },
    variants() {
      let variants = [];
      this.clonedCamerasAndVariants.forEach(function (camera) {
        camera['variants'].forEach(function (variant) {

          if (!_.find(variants, {id: variant.id})) {
            variants.push({'id': variant.id, 'text': variant.text})
          }
        })
      }.bind(this));
      return variants
    },
    maxCamera() {
      let cameras = this.clonedCamerasAndVariants;
      if (cameras.length === 0) return 1;
      let assetWithMaxCamera = _.maxBy(cameras, 'id');
      return assetWithMaxCamera.id + 1;
    },
    maxVariant() {
      if (this.variants.length === 0) return 1;
      let highestVariant = _.maxBy(this.variants, 'id');
      return highestVariant.id + 1;
    },
    saveButtonText(){

        if(!this.newImageFormValidated && this.disabledCamera){
          return 'i18n.fill_out_mendatory_fields_without_camera';
        } else if(!this.newImageFormValidated && !this.disabledCamera){
          return 'i18n.fill_out_mendatory_fields_with_camera';
        } else {
          return 'i18n.save_image';
        }

    },
    combinationExists() {
      let found = false
      this.clonedCamerasAndVariants.forEach(function (camera) {
        if (camera.id == this.form.camera.id) {
          camera.variants.forEach(function (variant) {
            if (variant.id == this.form.variant.id) {
              found = true
            }
          }.bind(this))
        }
      }.bind(this));
      return found;
    },
    newImageFormValidated() {

      if (this.form.camera.id == this.maxCamera && !this.form.camera.text) {
        return false;
      }

      if (this.form.variant.id == this.maxVariant && !this.form.variant.text) {
        return false;
      }

      return true;
    },
    editImageFormValidated() {

      if (!this.form.camera.text) {
        return false;
      }

      if (!this.form.variant.text) {
        return false;
      }
      return true;
    }
  },
  watch: {
    /*
    clonedCamerasAndVariants: {
      handler: function() {
          if(this.dataLoaded){
            this.$emit("updateCamerasAndVariants", this.clonedCamerasAndVariants);
          }
      },
      deep: true
    },
    */
    standardCameraName: {
      handler: function () {
        if (this.dataLoaded) {
          this.updateStandardCameraInCamerasAndVariants();
        }
      },
    },
    camerasAndVariants() {
      this.cloneCamerasAndVariants();
    }
  },
  created() {
    this.cloneCamerasAndVariants();
    this.$nextTick(() => {
      this.dataLoaded = true
    })
  },
  methods: {
    addVariantAndCamera() {
      let variant

      // Variant is new
      if (this.form.variant.id === this.maxVariant) {
        variant = {
          "id": this.form.variant.id,
          "text": this.form.variant.text
        }
      } else {
        // Variant exists and must be cloned
        variant = _.find(this.variants, {id: this.form.variant.id})
      }

      let newVariantAndCamera = {
        "id": this.form.camera.id,
        "text": this.form.camera.text,
        "variants": [
          variant
        ]
      }

      let cameraIndex = _.findIndex(this.clonedCamerasAndVariants, {id: this.form.camera.id});

      if (cameraIndex === -1) {
        this.clonedCamerasAndVariants.push(newVariantAndCamera);
      } else {
        this.clonedCamerasAndVariants[cameraIndex].variants.push(variant);
      }

      this.resetForm();

      this.$bvModal.hide('new-image')

      this.$emit("updateCamerasAndVariants", this.clonedCamerasAndVariants);
    },
    saveChangesAfterEdit() {
      let cameraIndex = _.findIndex(this.clonedCamerasAndVariants, {id: this.form.camera.id});
      this.clonedCamerasAndVariants[cameraIndex].text = this.form.camera.text;

      this.updateVariantInCameras(this.form.variant);
      this.resetForm();

      this.$bvModal.hide('edit-image')

      this.$emit("updateCamerasAndVariants", this.clonedCamerasAndVariants);
    },
    openNewImageModal() {
      this.form.variant.id = this.maxVariant;

      if (this.disabledCamera) {
        this.form.camera.id = 1;
        this.form.camera.text = this.standardCameraName;
      } else {
        this.form.camera.id = this.maxCamera;
      }

      this.$bvModal.show('new-image')
    },
    openClonedImageModal(image) {
      this.form.variant.text = image.variant.text;
      this.form.camera.text = image.camera.text;
      this.form.variant.id = this.maxVariant;

      if (this.disabledCamera) {
        this.form.camera.id = 1;
        this.form.camera.text = this.standardCameraName;
      } else {
        this.form.camera.id = this.maxCamera;
      }

      this.$bvModal.show('new-image')
    },
    openEditImageModal(image) {
      this.form.variant.id = image.variant.id;
      this.form.camera.id = image.camera.id;
      this.form.variant.text = image.variant.text;
      this.form.camera.text = image.camera.text;
      this.$bvModal.show('edit-image')
    },
    updateVariantInCameras(variant) {
      let newVariantData = variant;
      this.clonedCamerasAndVariants.forEach(function (camera) {
        camera['variants'].forEach(function (variant) {
          if (variant.id === newVariantData.id) {
            variant.text = newVariantData.text
          }
        })
      }.bind(this));
    },
    deleteVariantInCamera(image) {
      let cameraIndex = _.findIndex(this.clonedCamerasAndVariants, {id: image.camera.id});

      let variantIndex = _.findIndex(this.clonedCamerasAndVariants[cameraIndex].variants, {id: image.variant.id});

      this.clonedCamerasAndVariants[cameraIndex].variants.splice(variantIndex, 1);

      // Delete camera if no other variants are available
      if (this.clonedCamerasAndVariants[cameraIndex].variants.length === 0) {
        this.clonedCamerasAndVariants.splice(cameraIndex, 1);
      }

      this.rearrangeCameraAndVariantIds();

    },
    rearrangeCameraAndVariantIds() {
      this.clonedCamerasAndVariants.forEach(function (camera, index) {
        camera.id = index + 1;
      }.bind(this))

      this.$emit("updateCamerasAndVariants", this.clonedCamerasAndVariants);
    },
    updateStandardCameraInCamerasAndVariants() {
      this.clonedCamerasAndVariants.forEach(function (camera, index) {
        camera.text = this.standardCameraName;
      }.bind(this))
    },
    resetForm() {
      this.form.camera.id = 0;
      this.form.variant.id = 0;
      this.form.camera.text = null;
      this.form.variant.text = null;
      this.edit_index = null;
    },
    cloneCamerasAndVariants() {
      if (this.camerasAndVariants !== null) {
        this.clonedCamerasAndVariants = _.cloneDeep(this.camerasAndVariants);
      } else {
        this.clonedCamerasAndVariants = []
      }
    }
  }
}
</script>

<i18n>
{
  "de": {
    "i18n": {
      "new_image": "Neues Bild",
      "new_image_headline": "Neues Bild erstellen",
      "new_image_desc_without_camera": "Erstelle eine neue Variante von dem Referenzbild. Beschreibe was du ändern möchtest.",
      "new_image_desc_with_camera": "Erstelle eine neue Variante & Kamera oder wähle separat von bereits bestehenden Kameras und Varianten um ein Bild zu erstellen.",
      "variant": "Variante",
      "create_new_variant": "Neue Variante erstellen",
      "new_variant_desc": "Beschreibe deine Variante mit folgenden Informationen: Inhalt DIY Produkte, Größe, Farbe, Zimmer, Stil/Wandfarbe, Position",
      "variant_desc": "Variante Beschreibung",
      "camera": "Kamera",
      "create_new_camera": "Neue Kamera erstellen",
      "new_camera_desc": "Beschreibe deine Kamera z.B. Closeup",
      "camera_desc": "Kamera Beschreibung",
      "edit_image": "Bild ändern",
      "duplicate_image": "Kopie erstellen",
      "delete_image": "Bild löschen",
      "save_image": "Bild speichern",
      "save_changes": "Änderungen speichern",
      "fill_out_mendatory_fields": "Füllen Sie Felder aus",
      "fill_out_mendatory_fields_without_camera": "Füllen Sie die Variantenbeschreibung aus",
      "fill_out_mendatory_fields_with_camera": "Füllen Sie die Kamera- und Variantenbeschreibung aus",
      "edit_variant_desc": "Die Beschreibung wird in allen Bildern die diese Variante nutzen geändert",
      "edit_camera_desc": "Der Name wird in allen Bildern die diese Kamera nutzen geändert",
      "combination_exists": "Diese Kombination existiert bereits"
    }
  }
}
</i18n>


<style lang="scss">

.image {

  position: relative;

  .icons-left {
    position: absolute;
    left: -5px;
    top: -5px;
  }

  .icons-right {
    position: absolute;
    right: -10px;
    top: -5px;
  }

}
</style>
