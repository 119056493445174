<template>
  <b-modal
    id="new-contentpiece"
    ref="new-contentpiece-modal"
    hide-footer
    centered
    :title="$t('i18n.headline_create_contentpiece')"
  >
    <form @submit.prevent="save">
      <div class="form-group mt-4 mb-4">
        <div class="editable">
          <label for="cp-name">{{ $t('i18n.label_name') }}</label>
          <input
            id="cp-name"
            v-model="form.name"
            type="text"
            class="form-control"
            :class="getValidationClass('name')"
            maxlength="80"
            autocomplete="off"
          />
          <div
            class="invalid-feedback"
            v-html="getErrorMsg('name')"
          />
          <div class="form-text text-muted small">
            {{ $t('i18n.helper_name') }}
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12">
          <div
            v-if="teams.length > 0"
            class="pb-2"
          >
            <div class="editable">
              <label>{{ $t('i18n.headline_teams') }}</label>
              <select
                id="cp-team"
                v-model="selectedTeam"
                class="form-control"
                :class="getValidationClass('team')"
              >
                <option>{{ $t('i18n.select_placeholder') }}</option>
                <option
                  v-for="(team, index) in teams"
                  :key="index"
                  :value="team.name"
                >
                  {{ team.name }}
                </option>
              </select>

              <div
                class="invalid-feedback"
                v-html="getErrorMsg('team')"
              />
            </div>
          </div>
          <div
            v-else
            class="pb-2"
          >
            No team available
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12">
          <div class="editable">
            <label>{{ $t('i18n.headline_room') }}</label>
            <select
              id="cp-room"
              v-model="selectedRoom"
              class="form-control"
              :class="getValidationClass('room')"
            >
              <option>{{ $t('i18n.select_placeholder') }}</option>
              <option
                v-for="room in roomTypes"
                :key="room"
                :value="room"
              >
                {{ room }}
              </option>
            </select>

            <div
              class="invalid-feedback"
              v-html="getErrorMsg('room')"
            />
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12">
          <div class="editable">
            <datepicker
              :errors="errors"
              :date="form.deadline"
              :settings="datepickerSettings"
              @updateEvent="deadlineUpdated"
            />
          </div>
        </div>
      </div>

      <button
        id="save-cp"
        :disabled="submitInProgress"
        class="btn btn-default float-right"
      >
        {{ $t('i18n.btn_save') }}
      </button>
      <div class="clearfix" />
    </form>
  </b-modal>
</template>
<script>
import { mapState } from 'vuex';
import axios from '@/axios';
import BootstrapFormValidation from '@/mixins/BootstrapFormValidation';
import Datepicker from '@/components/shared/Datepicker';
import { EventBus } from '@/event';
import { replaceSpecialChars } from '@/composables/Typo';
import { RoomType, ROOM_TYPES } from '@/enum/room.enum.ts';

export default {
  name: 'NewContentPieceModal',
  components: {
    Datepicker,
  },
  mixins: [BootstrapFormValidation],
  data() {
    return {
      form: {
        name: '',
        deadline: '',
        team: {},
      },
      selectedTeam: {},
      submitInProgress: false,
      datepickerSettings: {
        validationClass: 'deadline',
        text_label: this.$t('i18n.label_deadline'),
        text_placeholder: this.$t('i18n.placeholder_deadline'),
        text_helper: this.$t('i18n.helper_deadline'),
      },
      errors: {},
      selectedRoom: RoomType | undefined,
      roomTypes: [...ROOM_TYPES],
    };
  },
  computed: {
    ...mapState({
      teams: (state) => state.User.groups,
    }),
    formWithUser() {
      return {
        name: this.form.name,
        deadline: this.form.deadline,
        team: this.selectedTeam,
        room: this.selectedRoom,
        user: this.$store.getters['User/userBackend'],
      };
    },
    cleanCpName() {
      return this.form.name;
    },
  },
  watch: {
    cleanCpName() {
      this.form.name = replaceSpecialChars(this.form.name);
    },
  },
  created() {
    if (this.teams.length === 1) {
      this.selectedTeam = this.teams[0];
    }
  },
  methods: {
    async save() {
      this.submitInProgress = true;

      let form = { ...this.formWithUser };

      EventBus.$emit('loadingstatus', 'show');

      try {
        let result = await axios.post('/content-pieces', form);
        this.hideModal();
        this.form = {};
        EventBus.$emit('loadingstatus', 'completed');
        this.submitInProgress = false;
        return this.$router.push({
          name: 'ContentPieceDetails',
          params: {
            cpid: result.data.id,
          },
        });
      } catch (error) {
        this.errors = error.response.data.errors;
        EventBus.$emit('loadingstatus', 'failed');
        this.submitInProgress = false;
      }
    },
    hideModal() {
      this.$refs['new-contentpiece-modal'].hide();
    },
    deadlineUpdated(val) {
      this.errors = {};
      this.form.deadline = val;
    },
  },
};
</script>

<i18n>
{
"de": {
"i18n": {
"headline_create_contentpiece": "Neues Content Piece erstellen",
"label_name": "Name des Content Pieces",
"helper_name" : "Der Name dieses Content Pieces. Sonderzeichen sind nicht erlaubt. Maximal 30 Zeichen.",
"btn_save" : "speichern",
"label_deadline": "Deadline",
"placeholder_deadline": "Klicken für Datumsauswahl",
"helper_deadline": "Die Deadline für das ContentPiece. Wirkt sich auch auf Briefings aus, wenn diese keine separate Deadline haben.",
"label_topic": "Übergeordnetes Thema",
"helper_topic" : "Das übergeordnete Thema dieses Content Pieces",
"label_deadline": "Deadline",
"placeholder_deadline": "Klicken für Datumsauswahl",
"helper_deadline": "Die Deadline für das ContentPiece. Wirkt sich auch auf Briefings aus, wenn diese keine separate Deadline haben.",
"select_placeholder": "- Bitte wählen -",
"headline_teams" : "Team",
"headline_room" : "Room"
}
}
}
</i18n>
