export function briefingTypeToURL(briefingType: string): string {
  switch (briefingType) {
    case 'BriefingArticle':
    case 'briefing-article':
      return '/briefings/briefing-article';

    default:
    case 'BriefingRendering':
    case 'briefing-rendering':
      return '/briefings/briefing-rendering';
  }
}
