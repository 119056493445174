import axios from '@/axios';
import { Project } from '@/entity/Project';
import { ProjectsList } from '@/entity/Response';

export default {
	namespaced: true,
	state: {
		items: [],
		pagination: { current_page: null, total: null, per_page: null },
		selected: {},
		filter: {},
		axiosError: ''
	},
	mutations: {
		SET_ITEMS(state, items) {
			state.items = items;
		},
		SET_PAGINATION(state, pagination) {
			state.pagination.current_page = pagination.current_page;
			state.pagination.total = pagination.total;
			state.pagination.per_page = pagination.per_page;
		},
		SET_SELECTED(state, selected) {
			state.selected = selected;
		},
		SET_AXIOS_ERROR(state, message) {
			state.axiosError = message;
		},
		ADD_OR_UPDATE_BATCH_BRIEFING(state, batchBriefingArticle) {
			const briefingArticleIndex = state.selected.batchBriefingArticles.findIndex(
				id => batchBriefingArticle.id == id
			);

			if (briefingArticleIndex === -1) {
				state.selected.batchBriefingArticles.splice(0, 0, batchBriefingArticle);
			} else {
				state.selected.batchBriefingArticles.splice(briefingArticleIndex, 1, batchBriefingArticle);
			}

			const batchSortOrderIndex = state.selected.batchSortOrder.findIndex(
				id => batchBriefingArticle.id == id
			);
			if (batchSortOrderIndex == -1) {
				state.selected.batchSortOrder.splice(0, 0, batchBriefingArticle.id);
			}
		},
		BATCH_MOVE_UP(state, batchId) {
			const index = state.selected.batchSortOrder.findIndex(id => batchId == id);

			if (index > -1) {
				const tmp = state.selected.batchSortOrder[index - 1];
				state.selected.batchSortOrder.splice(index - 1, 1);
				state.selected.batchSortOrder.splice(index, 0, tmp);
			}
		},
		BATCH_MOVE_DOWN(state, batchId) {
			const index = state.selected.batchSortOrder.findIndex(id => batchId == id);

			if (index > -1) {
				const tmp = state.selected.batchSortOrder[index];
				state.selected.batchSortOrder.splice(index, 1);
				state.selected.batchSortOrder.splice(index + 1, 0, tmp);
			}
		},
		BATCH_DELETE(state, batchId) {
			const briefingArticleIndex = state.selected.batchBriefingArticles.findIndex(
				batch => batch.id == batchId
			);
			if (briefingArticleIndex > -1) {
				state.selected.batchBriefingArticles.splice(briefingArticleIndex, 1);
			}

			const index = state.selected.batchSortOrder.findIndex(id => batchId == id);
			if (index > -1) {
				state.selected.batchSortOrder.splice(index, 1);
			}
		},
		SET_FILTER(state, filter) {
			state.filter = filter;
		}
	},
	actions: {
		AXIOS_ERROR_HANDLER({ commit }, error) {
			if (error?.response?.data?.message != null) {
				commit('SET_AXIOS_ERROR', error?.response?.data?.message);
			} else {
				commit('SET_AXIOS_ERROR', error);
			}
		},
		async FETCH({ commit, state }) {
			const page = state?.filter?.params == null ? 1 : state?.filter?.params;
			try {
				const response = await axios.post<ProjectsList>(
					`/project-filtered?page=${page}`,
					state.filter
				);

				commit('SET_ITEMS', response?.data.data);
				commit('SET_PAGINATION', response?.data.meta);
			} catch (e) {
				commit('SET_ITEMS', []);
			}
		},
		async GET_BY_ID({ commit, dispatch }, id) {
			try {
				const { data } = await axios.get<Project>(`/project/${id}`);

				if (data.batchSortOrder === null || data.batchSortOrder.length === 0) {
					data.batchSortOrder = [];
					data.batchBriefingArticles.forEach(b => data.batchSortOrder.push(b.id));
				}

				if (data.batchSortOrder?.length !== data.batchBriefingArticles?.length) {
					commit(
						'SET_AXIOS_ERROR',
						'Batch Sort Order or Batch Briefing Articles are not valid. Please change sort order.'
					);
					data.batchSortOrder = [];
					data.batchBriefingArticles.forEach(b => data.batchSortOrder.push(b.id));
				}

				commit('SET_SELECTED', data);
			} catch (e) {
				commit('SET_SELECTED', []);
			}
		},
		async BATCH_MOVE_UP({ commit, dispatch }, id) {
			commit('BATCH_MOVE_UP', id);
			dispatch('SAVE_SORT_ORDER');
		},
		async BATCH_MOVE_DOWN({ commit, dispatch }, id) {
			commit('BATCH_MOVE_DOWN', id);
			dispatch('SAVE_SORT_ORDER');
		},
		async BATCH_DELETE({ commit, dispatch }, id) {
			commit('BATCH_DELETE', id);
			dispatch('SAVE_SORT_ORDER');
		},
		async SAVE_SORT_ORDER({ dispatch, state }) {
			try {
				await axios.put<void>(`/project/${state.selected.id}/batch-sort-order`, {
					batchSortOrder: state.selected.batchSortOrder
				});
			} catch (error) {
				dispatch('AXIOS_ERROR_HANDLER', error);
			}
		}
	}
};
