import { render, staticRenderFns } from "./ImageFormats.vue?vue&type=template&id=388b2b16&"
import script from "./ImageFormats.vue?vue&type=script&lang=ts&"
export * from "./ImageFormats.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports