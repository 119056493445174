<template>
  <transition name="fadeicon">
    <div
      v-if="showIcon"
      class="circle-loader"
      :class="loaderClass"
    >
      <div
        v-if="showCompleted"
        class="checkmark draw"
      />
      <div
        v-if="showFailed"
        class="failed"
      >
        !
      </div>
    </div>
  </transition>
</template>
<script>
    import {EventBus} from '@/event';

    export default {
        name: "SaveStatus",
        data(){
            return {
                showIcon: false,
                showCompleted: false,
                showFailed: false,
                loaderClass: ''
            }
        },
         created() {
                EventBus.$on('loadingstatus', (status) =>  this.showHideIcon(status));
        },
        methods: {
            showHideIcon(status){
                this.resetIcon();
                switch(status){
                    case 'show':
                    this.showIcon = true;
                    this.showCompleted = false;
                    this.showFailed = false;
                    break;
                    case 'hide':
                    this.resetIcon()
                    break;
                    case 'completed':
                    this.showIcon = true;
                    this.showCompleted = true;
                    this.loaderClass = 'load-complete';
                    //let autosavedata = {'status': true, 'datetime' : ''};
                    setTimeout(() => { this.resetIcon() }, 2000)
                    break;
                    case 'failed':
                    this.showIcon = true;
                    this.showFailed = true;
                    this.loaderClass = 'load-failed';
                    setTimeout(() => { this.resetIcon() }, 2000)
                    break;
                }
            },
            resetIcon(){
                this.showIcon = false;
                this.showCompleted = false;
                this.loaderClass = '';
            }
        }
    }
</script>

<style lang="scss">
    @import '~bootstrap/scss/functions';
    @import '@/assets/scss/_variables';

    $loader-size: 3em;
    $check-height: $loader-size/2;
    $check-width: $check-height/2;
    $check-left: ($loader-size/8 + $loader-size/12);
    $check-thickness: 3px;
    $check-color: $success;


    .circle-loader {
      margin-bottom: $loader-size/2;
      border: 2px solid $gray-400;
      border-left-color: $primary;
      animation: loader-spin 1.2s infinite linear;
      position: fixed;
      top:120px;
      left:20px;
      z-index:99999;
      display: inline-block;
      vertical-align: top;
      border-radius: 50%;
      width: $loader-size;
      height: $loader-size;
    }

    .load-complete {
      -webkit-animation: none;
      animation: none;
      border-color: $check-color;
      transition: border 500ms ease-out;
    }

    .load-failed {
        animation:none;
        border-color: $danger;
        transition: border 500ms ease-out;
    }

    .failed {
        color: $danger;
        text-align:center;
        width: 100%;
        height: 100%;
        font-size:30px;
        line-height:1.3em;
    }

    .checkmark {
      &.draw:after {
        animation-duration: 800ms;
        animation-timing-function: ease;
        animation-name: checkmark;
        transform: scaleX(-1) rotate(135deg);
      }

      &:after {
        opacity: 1;
        height: $check-height;
        width: $check-width;
        transform-origin: left top;
        border-right: $check-thickness solid $check-color;
        border-top: $check-thickness solid $check-color;
        content: '';
        left: $check-left;
        top: $check-height;
        position: absolute;
      }
    }

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}



.fadeicon-enter-active, .fadeicon-leave-active {
  transition: 500ms;
  transform: translate(0px,0px);
}
.fadeicon-enter, .fadeicon-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translate(0px,0px);
}
</style>

