<template>
  <header class="fixed-top">
    <nav class="py-0  bg-primary">
      <div class="d-inline float-left mt-3 ml-3">
        <router-link
          id="logo"
          class="text-white pointer"
          :to="{ path: '/' }"
        >
          <img
            src="@/assets/images/obi-logo.svg"
            alt=""
          ><span>BriefingTool</span>
        </router-link>
      </div>

      <ul class="navbar-nav flex-row float-right mt-2 mr-2">
        <li class="nav-item">
          <div class="btn btn-primary text-white">
            <i class="fa fa-user"/> {{ username }}
          </div>
        </li>
        <li class="nav-item">
          <div
            v-tooltip.bottom="'Logout'"
            class="btn btn-primary px-2 text-white"
            @click="logout"
          >
            <font-awesome-icon :icon="['fas', 'sign-out-alt']"/>
          </div>
        </li>
      </ul>

      <div class="container" id="mainnav">
        <div class="row">
          <div class="col-6">
            <nav class="navbar p-0">
              <ul class="navbar-nav flex-row">
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    :to="{ path: '/projects' }"
                  >
                    Projects
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    :to="{ path: '/briefings' }"
                  >
                    Briefings
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>


    </nav>

    <div class="bg-secondary">
      <div class="container">
        <div class="row ">
          <div class="col-6">
            <nav
              v-if="this.$route.fullPath.includes('briefings')"
              id="subnav"
              class="navbar navbar-expand p-0"
            >
              <ul class="navbar-nav">
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'ContentPiecesList' }"
                  >
                    Content Pieces
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'BriefingArticleList' }"
                  >
                    Article Briefings
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'BriefingRenderingList' }"
                  >
                    Rendering Briefings
                  </router-link>
                </li>
              </ul>
            </nav>
            <div v-else class="navbar-height-fix">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="container">
      <div class="row">
        <div class="col-12">
          <b-breadcrumb
            class="mb-0"
            :items="this.$route.meta.breadcrumb"
          />
        </div>
      </div>
    </div>-->
  </header>
</template>

<script>
import Vue from 'vue';
import {mapState} from 'vuex'

export default {
  name: 'Topbar',
  computed: {
    ...mapState({
      username: state => state.User.user.userName,
    })
  },
  methods: {
    logout() {
      Vue.prototype.$keycloak.logout();
    }
  }
}
</script>


<style lang="scss" scoped>
#logo {
  img {
    width: 76px;
    vertical-align: top;
  }

  span {
    line-height: 0.9em;
    vertical-align: bottom;
    margin: 0 0 0 5px;
    font-weight: 800;
    font-size: 1.2em;
    font-family: "ObiSansMLBold";
  }
}


#mainnav {
  .nav-link {
    padding: 1rem;
    color: #fff !important;


    &.router-link-active {
      background: $secondary;
      color: $body-color !important;
    }

  }
}

#subnav {
  .nav-link {
    padding: 0.8rem 0.8rem 0.5rem 0.8rem;
    color: $body-color !important;
    border-bottom:2px solid transparent;

    &.router-link-active {
      color: $primary !important;
      border-bottom:2px solid $primary;
    }

  }
}

.navbar-height-fix {
  height:44px;
}

</style>


<i18n>
{
  "de": {
    "i18n": {
      "keycloak": "Die Sitzung ist nicht über Keycloak gesichert!"
    }
  }
}
</i18n>
