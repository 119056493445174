<template>
  <div id="app">
    <div
      v-if="keycloakError"
      class="alert-danger alert"
    >
      {{ keycloakError }}
    </div>
    <component
      :is="layout"
      v-else
    >
      <transition
        :name="transitionName"
        mode="out-in"
      >
        <router-view :key="$route.fullPath"/>
      </transition>
    </component>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "App",
  data() {
    return {
      transitionName: ''
    }
  },
  computed: {
    ...mapState({
      keycloakError: state => state.keycloakError,
    }),
    layout() {
      return (this.$route.meta.layout || "default") + "-layout";
    }
  },
  watch: {
    '$route'(to, from) {

      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length

      if (toDepth > fromDepth) {
        this.transitionName = "slide-left";
      } else {
        this.transitionName = "slide-right";
      }

    }
  },
  beforeMount() {
    // this needs to be implemented outside the routes context to avoid multiple loading of js script
    this.addOneIdScript();
  },
  methods: {
    addOneIdScript() {
      const oneIdScript = document.createElement('script');
      oneIdScript.setAttribute('id', 'one-id-js')
      const env = process.env.VUE_APP_ENVIRONMENT ?? 'dev';
      oneIdScript.setAttribute('src', `https://oneid.onecms-${env}.obi.solutions/assets/oneid-gen.js`);
      document.head.appendChild(oneIdScript);
    },
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/app.scss';

.slide-right-enter-active, .slide-right-leave-active {
  transition: opacity 500ms, transform 500ms;
}

.slide-right-leave-to {
  opacity: 0;
  transform: translateX(70%);
}

.slide-right-enter {
  opacity: 0;
  transform: translateX(-70%);
}

.slide-left-enter-active, .slide-left-leave-active {
  transition: opacity 500ms, transform 500ms;
}

.slide-left-leave-to {
  opacity: 0;
  transform: translateX(-70%);
}

.slide-left-enter {
  opacity: 0;
  transform: translateX(70%);
}


.slide-right-leave-to,
.slide-right-enter,
.slide-left-leave-to,
.slide-left-enter {
  .back-arrow {
    display: none;
  }
}

</style>
