<template>
  <div>
    <div class="container pb-8">
      <div class="row mb-4">
        <div class="col-12">
          <!--           <span
            class="btn btn-outline-default d-print-none float-right"
            @click="printPage"
          >Drucken</span> -->
          <h2>Zusammenfassung aktueller Stand</h2>
        </div>
      </div>

      <div class="row ">
        <div class="col-12">
          <div class="card w-100">
            <div class="card-header">
              <strong>Briefing Zusammenfassung</strong>
              <print-jira :briefing="briefing" />
            </div>
            <div class="card-body py-2">
              <print-base-info :briefing="briefing" />
              <print-product
                v-if="briefing.product"
                :product="briefing.product"
              />
            </div>
          </div>
        </div>

        <div
          v-if="briefing.uploads && briefing.uploads.length > 0"
          class="col-4 mt-4"
        >
          <print-uploads :uploads="briefing.uploads" />
        </div>

        <div
          v-if="briefing.camera && briefing.camera.length > 0"
          class="col-4  mt-4"
        >
          <print-camera-variants :cameras="briefing.camera" />
        </div>

        <div
          v-if="briefing.product_bundle && briefing.product_bundle.products.length > 0"
          class="col-4  mt-4"
        >
          <print-product-bundle :product-bundle="briefing.product_bundle" />
        </div>

        <div
          v-if="briefing.briefingLowPoly"
          class="col-12 mt-4"
        >
          <div class="card w-100">
            <div class="card-header">
              <strong>Briefing Low Poly</strong>
              <print-jira :briefing="briefing" />
            </div>
            <div class="card-body mb-2">
              <briefing-lowpoly-single :briefing="briefing" />
            </div>
          </div>
        </div>
        <div
          v-if="briefing.camAssets && briefing.camAssets.length > 0"
          class="col-12 mt-4"
        >
          <h3>Cam Assets</h3>
          <div
            v-for="(camAsset,index) in briefing.camAssets"
            :key="index"
          >
            <a
              :href="camAsset.url"
              target="_blank"
            >{{ camAsset.url }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PrintUploads from "@/views/briefing/print/PrintUploads";
import PrintProduct from "@/views/briefing/print/PrintProduct";
import PrintJira from "@/views/briefing/print/PrintJira";
import PrintBaseInfo from "@/views/briefing/print/PrintBaseInfo";
import PrintCameraVariants from "@/views/briefing/print/PrintCameraVariants";
import PrintProductBundle from "@/views/briefing/print/PrintProductBundle";
import BriefingLowpolySingle from '@/components/shared/BriefingLowpolySingle';

export default {
  name: "BriefingSoloDetailsPrint",
  components: {
    PrintProduct,
    PrintUploads,
    PrintJira,
    PrintBaseInfo,
    PrintCameraVariants,
    PrintProductBundle,
    BriefingLowpolySingle
  },
  props: {
    id: String,
    briefing: Object
  },
  methods: {
    printPage() {
      window.print();
    },
  }
}
</script>

<style lang="scss">
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/functions';
@import '@/assets/scss/_variables';

.readmore {
  button {
    border: 0px;
    text-decoration: underline;
    background: none;
    outline: none;
  }
}
</style>
