<template>
  <div class="row">
    <div class="col-12">
      <div class="card w-100">
        <div class="card-header">
          <strong>Kameras und Varianten</strong>
        </div>
        <div class="card-body py-2">
          <div
            v-if="cameras.length > 0"
            class="row"
          >
            <div
              v-for="(variant, variantIndex) in camerasToVariants(cameras)"
              :key="'variant'+variantIndex"
            >
              <p><b>Variante {{ variant.id }}</b></p>

              <div
                class="mb-3"
                v-html="variant.text"
              />

              <ul>
                <li
                  v-for="(camera,cameraIndex) in variant.cameras"
                  :key="'camera'+cameraIndex"
                >
                  Kamera {{ camera.id }} - {{ camera.text }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Cameras from "@/mixins/Cameras";
export default {
  name: "PrintCameraVariants",
  mixins: [Cameras],
  props: {
    cameras: Array
  }
}
</script>
