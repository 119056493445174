<template>
  <div class="newbreifingtile p-2 mb-4">
    <div class="flip-card mb-2">
      <div class="flip-card-inner">
        <div class="flip-card-front bg-primary text-white text-center">
          +
        </div>
        <div class="flip-card-back bg-third p-3">
          <ValidationObserver ref="observer">
            <validation-provider
              v-slot="{ errors }"
              rules="is_not:default"
              name="briefing type"
              mode="passive"
            >
              <select
                v-model="briefingType"
                class="form-control mb-2"
                :class="errors[0]?'is-invalid':''"
              >
                <option value="default">
                  {{ $t('i18n.placeholder_select_briefing') }}
                </option>
                <option
                  v-for="(briefing,key) in briefingTypes"
                  :key="key"
                  :value="key"
                >
                  {{ briefing.title }}
                </option>
              </select>
            </validation-provider>
            <button
              class="btn btn-primary btn-block text-white"
              @click="createBriefing"
            >
              {{ $t('i18n.btn_create') }}
            </button>
          </ValidationObserver>
        </div>
      </div>
    </div>

    <div class="p-2">
      <h5>{{ $t('i18n.headline_add') }}</h5>
      <p>{{ $t('i18n.helper_add') }}</p>
    </div>
  </div>
</template>


<script>
import types from "@/data/briefing-types.json";
import axios from '@/axios';
import {EventBus} from '@/event';
import {ValidationProvider, ValidationObserver} from "vee-validate";

export default {
  name: "AddBriefingTile",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    contentPiece: Object,
  },
  data() {
    return {
      briefingTypes: types,
      briefingType: 'default'
    }
  },
  methods: {
    async createBriefing() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return
      }

      let params = {
        'content_piece_id': this.contentPiece.id,
        'team': this.contentPiece.team,
        'deadline': this.contentPiece.deadline,
        'name': this.contentPiece.name,
        'internal_id': this.contentPiece.internal_id,
        'description': this.contentPiece.description,
        'user': this.contentPiece.user,
        'room': this.contentPiece.room
      }
      EventBus.$emit('loadingstatus', 'show');
      try {
        let result = await axios.post(`/${this.briefingTypes[this.briefingType].createRoute}`, params)
        EventBus.$emit('loadingstatus', 'completed');
        return this.$router.push({
          name: 'ContentPieceBriefingDetails',
          params: {
            cpid: this.contentPiece.id,
            briefingType: this.briefingTypes[this.briefingType].createRoute,
            briefingid: result.data.id,
          }
        });
      } catch (error) {
        EventBus.$emit('loadingstatus', 'failed');
      }
    },
  }
}
</script>


<style lang="scss">
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '@/assets/scss/_variables';

.flip-card {
  background-color: transparent;
  width: auto;
  height: 158px;
  @include media-breakpoint-down(lg) {
    height: 129px;
  }
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

.newbreifingtile:hover .flip-card-inner {
  transform: rotateX(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-front {
  line-height: 158px;
  @include media-breakpoint-down(lg) {
    line-height: 129px;
  }
  font-size: 90px;
}

.flip-card-back {
  transform: rotateX(180deg);
  padding-top: 40px !important;

  select.form-control {
    background: #fff !important;
  }
}

</style>

<i18n>
{
  "de": {
    "i18n": {
      "headline_add": "Briefing hinzufügen",
      "helper_add": "Wähle einen Briefingtyp aus und erstelle ein neues Briefing.",
      "placeholder_select_briefing": "Wähle einen Briefingtyp",
      "btn_create": "Briefing erstellen"
    }
  }
}
</i18n>
