<template>
  <div class="col-12 col-md-6">
    <img
      v-if="favorite"
      :src="favorite.url"
      :alt="favorite.baseName"
      class="w-100 img-fluid pl-3 mt-0 mt-md-9"
    >
  </div>
</template>
<script>

export default {
  name: "FavoriteSelectedImage",
  props: {
    favorite: {
      type: Object,
      default:  function () {
        return {
          baseName: "",
          url: ""
        }
      }
    }
  },
  data() {
    return {
      LoadingThumb: require('@/assets/images/lazy.jpg'),
    }
  },
}
</script>
