import axios from 'axios';
import store from '@/store/store';

const instance = axios.create({
	baseURL: process.env.VUE_APP_API_URL
});

instance.interceptors.request.use(
	config => {
		// @ts-ignore
		const token = store.state?.User.accessToken;
		if (token) {
			// @ts-ignore
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

export default instance;
