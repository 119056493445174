<template>
  <div>
    <div class="row mb-4">
      <div class="col-12">
        <div
          v-if="teams.length > 0"
          class="pb-2"
        >
          <div class="editable">
            <label>{{ $t('i18n.headline_teams') }}</label>
            <select
              id="cp-team"
              v-model="selectedTeam"
              class="form-control"
              :class="getValidationClass('team')"
            >
              <option value="0">
                {{ $t('i18n.select_placeholder') }}
              </option>
              <option
                v-for="(team, index) in teams"
                :key="index"
                :value="team.name"
              >
                {{ team.name }}
              </option>
            </select>
            <div
              class="invalid-feedback"
              v-html="getErrorMsg('team')"
            />
          </div>
        </div>
        <div
          v-else
          class="pb-2"
        >
          No team available
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <div class="editable">
          <label>{{ $t('i18n.headline_room') }}</label>
          <select
            id="cp-room"
            v-model="selectedRoom"
            class="form-control"
            :class="getValidationClass('room')"
          >
            <option>{{ $t('i18n.select_placeholder') }}</option>
            <option
              v-for="room in roomTypes"
              :key="room"
              :value="room"
            >
              {{ room }}
            </option>
          </select>

          <div
            class="invalid-feedback"
            v-html="getErrorMsg('room')"
          />
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <div class="editable">
          <datepicker
            :errors="errors"
            :date="form.deadline"
            :settings="datepickerSettings"
            @updateEvent="deadlineUpdated"
          />
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <label>{{ $t('i18n.information') }}</label>
        <textarea
          v-model="form.information"
          rows="6"
          maxlength="500"
          class="form-control"
          :placeholder="$t('i18n.information_placeholder')"
        />
      </div>
    </div>

    <div
      class="row mb-4"
      :class="getValidationClass('sdbProduct')"
    >
      <div class="col-12">
        <div class="form-group">
          <single-article
            :settings="singleArticleSettings"
            :single-article="form.sdb_product"
            :errors="errors"
            @articleAdded="setFormArticle"
          />
        </div>
      </div>
    </div>

    <button
      :disabled="submitInProgress"
      class="btn btn-default float-right"
      @click="save()"
    >
      {{ $t('i18n.btn_save') }}
    </button>
    <div class="clearfix" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import axios from '@/axios';
import BootstrapFormValidation from '@/mixins/BootstrapFormValidation';
import Datepicker from '@/components/shared/Datepicker';
import { EventBus } from '@/event';
import SingleArticle from '@/components/shared/SingleArticle';
import BriefingDetails from '@/views/briefing/BriefingDetails';
import { RoomType, ROOM_TYPES } from '@/enum/room.enum.ts';

export default {
  name: 'BriefingArticleForm',
  components: {
    Datepicker,
    SingleArticle,
  },
  mixins: [BootstrapFormValidation],
  data() {
    return {
      form: {
        deadline: '',
        team: '',
        sdb_product: null,
        information: '',
      },
      selectedTeam: '0',
      submitInProgress: false,
      datepickerSettings: {
        validationClass: 'deadline',
        text_label: this.$t('i18n.label_deadline'),
        text_placeholder: this.$t('i18n.placeholder_deadline'),
      },
      singleArticleSettings: {
        headlinePlaceholder: this.$t('i18n.headline_article'),
        description: '<p>' + this.$t('i18n.helper_article') + '</p>',
      },
      errors: {},
      roomTypes: [...ROOM_TYPES],
      selectedRoom: RoomType,
    };
  },
  computed: {
    ...mapState({
      teams: (state) => state.User.groups,
    }),
    formWithUser() {
      return {
        deadline: this.form.deadline,
        team: this.selectedTeam,
        user: this.$store.getters['User/userBackend'],
        sdbProduct: this.form.sdb_product,
        information: this.form.information,
        room: this.selectedRoom,
      };
    },
  },
  created() {
    if (this.teams.length === 1) {
      this.selectedTeam = this.teams[0];
    }
  },
  methods: {
    async save() {
      this.submitInProgress = true;
      let form = { ...this.formWithUser };
      EventBus.$emit('loadingstatus', 'show');

      try {
        let result = await axios.post('/briefing-article', form);
        this.$emit('hideModal');
        this.form = {};
        EventBus.$emit('loadingstatus', 'completed');
        this.submitInProgress = false;
        return this.$router.push({
          name: 'BriefingDetails',
          params: {
            briefingType: 'briefing-article',
            briefingid: result.data.id,
          },
        });
      } catch (error) {
        this.errors = error?.response?.data?.errors;
        EventBus.$emit('loadingstatus', 'failed');
        this.submitInProgress = false;
      }
    },
    deadlineUpdated(val) {
      this.errors = {};
      this.form.deadline = val;
    },
    setFormArticle(article) {
      this.form.sdb_product = article;
    },
  },
};
</script>

<i18n>
{
  "de": {
    "i18n": {
      "btn_save": "speichern",
      "label_deadline": "Deadline",
      "placeholder_deadline": "Klicken für Datumsauswahl",
      "select_placeholder": "- Bitte wählen -",
      "headline_teams": "Team",
      "headline_article": "Artikel",
      "helper_article": "Wähle den Artikel der digitalisiert werden soll",
      "information": "Weitere Informationen",
      "information_placeholder": "Gib hier bitte weitere Hinweise zur Asset Erstellung an, z.B. für welches Rendering Briefing der Artikel genutzt werden soll oder dass es einen verwandten Artikel gibt oder sonstige Hinweise die uns bei der Erstellung des Artikels helfen.",
      "headline_room": "Raum"
    }
  }
}
</i18n>
