
import Vue from 'vue';
import {mapState} from "vuex";
import BootstrapFormValidation from '@/mixins/BootstrapFormValidation.vue';

export default Vue.extend({
  name: "TeamSelect",
  props: {
    team: {
      type: String,
      default: ""
    },
    errors: {
      type: Object,
      default: () => {return {}}
    }
  },
  mixins: [BootstrapFormValidation],
  data() {
    return {
      selectedTeam: "",
    }
  },
  computed: {
    ...mapState({
      teams: (state: any) => state.User.groups
    }),
  },
  created(): void {
    this.selectedTeam = this.team
  },
});
