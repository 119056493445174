<template>
  <div class="h-100 d-flex flex-column">
    <div>
      <span class="font-weight-bold">{{ settings.headlinePlaceholder }}</span>
      <span
        class="text-muted small"
        v-html="settings.description"
      />
    </div>

    <div
      class="border px-3 py-3 singleProduct"
      :class="{'border-danger': getErrorMsg('sdbProduct')}"
    >
      <div>
        <div class="row mb-3">
          <div class="col-3">
            <strong>Bild</strong>
          </div>
          <div class="col-6">
            <strong>Name</strong>
          </div>
          <div class="col-3">
            <strong>Nummer</strong>
          </div>
        </div>

        <div
          v-if="singleArticleClone"
          class="row listitem"
        >
          <div class="col-3 mb-3">
            <div
              v-b-modal="singleArticleClone.articleNumber+''"
              class="d-flex"
            >
              <div class="justify-content-center align-self-center">
                <img
                  v-if="singleArticleClone.articleNumber"
                  class="img-fluid"
                  :src="productImageURL(singleArticleClone.articleNumber)"
                  @error="fallbackPlaceholder"
                >
                <b-modal
                  :id="singleArticleClone.articleNumber"
                  size="lg"
                  centered
                  hide-footer
                  :title="singleArticleClone | getIsoSku"
                >
                  <img
                    class="img-fluid"
                    :src="productImageURL(singleArticleClone.articleNumber)"
                    @error="fallbackPlaceholder"
                  >
                </b-modal>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <p
                class="form-control transparent"
                readonly
              >
                {{ singleArticleClone.title }}
              </p>
            </div>
          </div>

          <div class="col-3">
            <div class="form-group">
              <p
                class="form-control transparent"
                readonly
              >
                {{ isoSku }}
              </p>
            </div>
          </div>
        </div>

        <div
          class="btn btn-block btn-sm text-white btn-primary cursor-pointer edit-productbundle"
          @click="modalShow = !modalShow"
        >
          {{ $t('i18n.btn_change') }}
        </div>


        <b-modal
          v-model="modalShow"
          size="xl"
          centered
          :title="settings.headlinePlaceholder+' wählen'"
        >
          <div class="row">
            <div class="col-12">
              <h3>Alle {{ settings.headlinePlaceholder }}</h3>
              <iass-wrapper @articleAdded="addArticleFromSearch" />
            </div>
          </div>
          <template v-slot:modal-footer="{ ok}">
            <div />
          </template>
        </b-modal>
      </div>
    </div>
    <div
      class="text-danger small"
      v-html="getErrorMsg('sdbProduct')"
    />
  </div>
</template>

<script>
import axios from '@/axios';
import {EventBus} from '@/event';
import BootstrapFormValidation from '@/mixins/BootstrapFormValidation';
import clonedeep from 'lodash.clonedeep';
import IassWrapper from "@/components/shared/article-broker/iass-wrapper";
import Image from "@/mixins/Image";

export default {
  name: "ArticlesBundle",
  components: {IassWrapper},
  mixins: [BootstrapFormValidation, Image],
  props: {
    singleArticle: Object,
    parentId: String,
    errors: Object,
    settings: {
      type: Object,
      default: () => ({
        headlinePlaceholder: null,
        description: null
      })
    }
  },
  data() {
    return {
      singleArticleClone: {},
      modalShow: false
    }
  },
  computed: {
    isoSku() {
      if(!this.singleArticleClone || ! Object.hasOwn(this.singleArticleClone, 'country')){
        return "";
      }
      return this.singleArticleClone.country.toLowerCase() + '-' + this.singleArticleClone.articleNumberInclCheckDigit;
    }
  },
  watch: {
    singleArticle() {
      this.singleArticleClone = clonedeep(this.singleArticle);
    }
  },
  methods: {
    async saveOrUpdateArticle() {
      let result;
      if (this.singleArticleClone) {
        await this.deleteArticle();
        result = await this.saveArticle();
      } else {
        result = await this.saveArticle();
      }
      this.$store.dispatch('BriefingArticle/GET_BY_ID', this.parentId);
    },
    async addArticleFromSearch(article) {
      try {
        this.$emit('articleAdded', article);
        this.modalShow = false;
      } catch (err) {
        console.log(err)
      }
    },
    resetItem() {
      this.newItem.sdbArticle = {};
    }
  }
}
</script>

<style lang="scss">

.singleProduct {
  position: relative;

  .lock-layer {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background: transparentize($body-bg, 0.3);
    z-index: 10;
    font-size: 40px;
    color: $third;
  }

  &.published {
    .form-control {
      border-color: transparent;
    }
  }
}

.article-broker {
  max-height: 60vh;
  overflow: auto;
}


</style>

<i18n>
{
  "de": {
    "i18n": {
      "btn_save": "Speichern und veröffentlichen",
      "btn_change": "Artikel wählen"
    }
  }
}
</i18n>
