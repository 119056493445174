import { extend, localize } from "vee-validate";
import * as rules from 'vee-validate/dist/rules';
import en from "vee-validate/dist/locale/en.json";

// loop over all rules
for (let rule in rules) {
  // add the rule
  extend(rule, rules[rule]);
}


// extend('example', value => {
//   if (value === 'no example') {
//     return 'That is wrong!';
//   }

//   return true;
// });

// Install messages
localize({
  en
});
