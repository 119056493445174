<script>
export default {
  name: 'BootstrapFormValidation',
  methods: {
    getValidationClass(value) {
      if (value in this.errors) {
        return 'is-invalid';
      }
    },
    getErrorMsg(value) {
      let msg = '<ul class="m-0 list-unstyled">';
      if (value in this.errors) {
        this.errors[value].forEach(function (error) {
          msg += '<li>' + error + '</li>';
        });
        msg += '</ul>'
        return msg
      }
    },
  }
}
</script>
