<template>
  <div>
    OK
  </div>
</template>
<script>
export default {
  name: "Liveness",
}
</script>
