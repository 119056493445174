

import NewBriefingModal from "@/components/briefing/NewBriefingModal.vue";
import { mapState } from 'vuex'
import Vue from "vue";
export default Vue.extend({
  name: 'AddBriefingButton',
  components: {
    NewBriefingModal
  },
  props: {
    briefingType: String,
  },
  computed:{
    ...mapState({
      teams: (state: any) => state.User.groups
    })
  }
})
