import { UserGroups, UserGroup } from '../user-groups'
import { UserInfoType, UserVuex } from './keycloak-vue.types'

class UserInfoHandler {

    formatUserObject(userInfo: UserInfoType, id: string): UserVuex {
        return {
            id: id,
            email: userInfo.email ?? "",
            fullName: userInfo.firstName + ' ' + userInfo.lastName,
            userName: userInfo.username ?? "",
            attributes: userInfo?.attributes ?? {},
        };
    }

    getUserGroupsForUser(userGroupsKeycloak: string[] | undefined, matchAttribute: 'keycloakName' | 'name' = 'keycloakName'): UserGroup[] {
        const defaultUserGroup: UserGroup = UserGroups.find(userGroup => userGroup.isDefault === true) as UserGroup;

        if (userGroupsKeycloak === undefined || userGroupsKeycloak.length === 0) {
            return [defaultUserGroup];
        }

        let foundUserGroups: UserGroup[] = [];
        userGroupsKeycloak.forEach(ugK => {
            let foundUG = UserGroups.find((ug: UserGroup) => ugK === ug[matchAttribute])
            if (foundUG) {
                foundUserGroups.push(foundUG);
            }
        })

        if (foundUserGroups.length === 0) {
            return [defaultUserGroup];
        }

        return foundUserGroups;
    }

    formatRolesToUserGroupsWithoutMapping(roles: string[]): UserGroup[] {
        return roles.map(role => new UserGroup(role, ""));
    }

}

const userInfoHandlerInstance = new UserInfoHandler();
const userInfoHandler = () => userInfoHandlerInstance;
export { userInfoHandler, UserInfoHandler }