<template>
  <span
    v-if="briefing.jira_export"
    class="float-right"
  >
    <a
      target="_blank"
      :href="jiraURL(briefing)"
      class="link text-default"
    >
      {{ briefing.jira_export.response.key }}
    </a>
    {{ jiraStatus(briefing) }}
  </span>
</template>
<script>

import Jira from "@/mixins/Jira";

export default {
  name: "PrintJira",
  mixins: [Jira],
  props: {
    briefing: Object
  }
}
</script>
