<template>
  <div>
    <ValidationObserver ref="observer">
      <h3>{{ settings.headlinePlaceholder }}</h3>
      <span
        class="text-muted small"
        v-html="settings.description"
      />
      <div class="big-border px-3 pt-3">
        <div
          v-for="(item,index) in items"
          :key="index"
          class="row listitem"
        >
          <div class="col-9 pr-0">
            <div class="form-group mb-2">
              <p
                class="transparent text-break"
                readonly
              >
                {{ item }}
              </p>
            </div>
          </div>
          <div class="col-3 text-right">
            <div class="form-group">
              <button
                class="btn text-danger"
                @click="deleteItem(index)"
              >
                <font-awesome-icon :icon="['fas', 'trash']" />
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-9 pr-0">
            <div class="form-group">
              <validation-provider
                v-slot="{ errors }"
                :rules="settings.validationRules"
                :name="settings.itemName"
                mode="passive"
              >
                <div class="editable">
                  <input
                    v-model="inputField"
                    class="form-control"
                    type="text"
                    :placeholder="$t('i18n.placeholder_add_new', {value: settings.itemName})"
                    :class="errors[0]?'is-invalid':''"
                    @keyup.enter="addItem()"
                  >
                </div>
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </div>
          <div class="col-3 text-right">
            <div class="form-group">
              <button
                class="btn additem"
                @click="addItem()"
              >
                <font-awesome-icon :icon="['fas', 'plus']" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from "vee-validate";

export default {
  name: "ItemsList",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    settings: {
      type: Object
    },
    itemsProp: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      form: null,
      items: [],
      inputField: ''
    }
  },
  watch: {
    itemsProp(){
      this.setItems()
    }
  },
  created() {
    this.setItems()
  },
  methods: {
    setItems(){
      if (this.itemsProp != null) {
        this.items = JSON.parse(JSON.stringify(this.itemsProp));
      }
    },
    async addItem() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return
      }

      if (this.items == null) {
        this.items = [];
      }
      this.items.push(this.inputField);
      this.inputField = '';
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
      this.$parent.$emit(this.settings.eventName, this.items);
    },
    deleteItem(index) {
      this.items.splice(index, 1);
      this.$parent.$emit(this.settings.eventName, this.items);
    },
  }
}
</script>

<i18n>
{
  "de": {
    "i18n": {
      "placeholder_add_new": "{value} hinzufügen"
    }
  }
}
</i18n>
