<template>
  <main>
    <slot />
  </main>
</template>
<script>
    export default {
        name: "PrintLayout",

    }
</script>
