export const ROOM_TYPES = [
  'BALCONY',
  'BATHROOM',
  'BEDROOM',
  'DINING-ROOM',
  'GARDEN',
  'HALLWAY',
  'KIDS-ROOM',
  'KITCHEN',
  'LIVING-ROOM',
  'OFFICE',
  'OTHER-INDOOR',
  'OTHER-OUTDOOR',
  'TERRACE',
] as const;

export type RoomType = (typeof ROOM_TYPES)[number];
