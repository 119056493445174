<template>
  <div
    id="briefing-navigation"
    class="text-white"
    :class="setBackground()"
  >
    <div class="container">
      <div class="row  py-3">
        <div
          v-if="!ordered && readyToFinalize"
          class="col-12 text-center"
        >
          <p
            v-if="!error"
            class="m-0"
          >
            {{ $t('i18n.text_done') }}
            <button
              class="mx-2 btn btn-dark text-white btn-sm"
              @click="exportContentPieces"
            >
              {{ $t('i18n.btn_send') }}
              <font-awesome-icon
                class="mr-2"
                :icon="['fas', 'paper-plane']"
              />
            </button>
            <span v-html="$t('i18n.text_continue_process')" />
          </p>
          <p
            v-else
            class="m-0"
          >
            Error: {{ error }}
          </p>
        </div>
        <div
          v-else-if="ordered"
          class="col-12 text-center"
        >
          <p class="m-0">
            {{ $t('i18n.text_ordered') }}
          </p>
        </div>
        <div
          v-else
          class="col-12 text-center"
        >
          <p class="m-0">
            {{ $t('i18n.not_ready_to_finalize') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {EventBus} from '@/event';
import axios from '@/axios';

export default {
  name: 'PublishContentPieceBar',
  props: {
    ordered: Boolean,
    contentPieceID: String,
    readyToFinalize: Boolean
  },
  data() {
    return {
      autosave: {
        'status': null,
        'datetime': ''
      },
      error: null
    }
  },
  methods: {
    async exportContentPieces() {
      EventBus.$emit('loadingstatus', 'show');
      try {
        await axios.get('/content-pieces/' + this.contentPieceID + '/export');
        EventBus.$emit('loadingstatus', 'completed');
        this.error = null;
        return this.$router.go();
      } catch (error) {
        this.error = error.response.data.message;
        EventBus.$emit('loadingstatus', 'failed');
      }
    },
    setBackground() {
      if (this.ordered === true) {
        return 'bg-success';
      } else if (this.ordered === false && this.error) {
        return 'bg-danger';
      } else {
        return 'bg-primary';
      }
    }
  }
}
</script>


<style lang="scss">
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '@/assets/scss/_variables';

#briefing-navigation {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index:3;
}
</style>

<i18n>
{
  "de": {
    "i18n": {
      "text_done": "Ist dieses Content Piece und die Briefings fertig?",
      "btn_send": "Content Piece final bestellen",
      "text_continue_process": "damit die einzelnen Teams den Content produzieren können.",
      "text_ordered": "Dieses Content Piece wurde bereits in Auftrag gegeben.",
      "not_ready_to_finalize": "Dieses Content Piece kann noch nicht bestellt werden. Es existiert mindestens ein 3D-Briefing ohne Kamera / Varianten!"
    }
  }
}
</i18n>
