import Vue from 'vue';

import App from './App.vue';
import router from './router';
import store from './store/store';
import VueLazyload from 'vue-lazyload';
import './vee-validate';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faEllipsisV,
  faSearch,
  faPlus,
  faLink,
  faTimes,
  faPencilAlt,
  faBars,
  faTrash,
  faCopy,
  faPrint,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faLock,
  faCheck,
  faCheckCircle,
  faFileUpload,
  faLockOpen,
  faPaperPlane,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { initKeycloak, keycloak, KeycloakVuex } from './auth';

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['one-id-select'];

library.add(
  faEllipsisV,
  faSearch,
  faPlus,
  faLink,
  faTimes,
  faPencilAlt,
  faBars,
  faTrash,
  faCopy,
  faPrint,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faLock,
  faCheck,
  faCheckCircle,
  faFileUpload,
  faLockOpen,
  faPaperPlane,
  faSignOutAlt
);
Vue.component('font-awesome-icon', FontAwesomeIcon);

import DefaultLayout from '@/layouts/Default.vue';
Vue.component('default-layout', DefaultLayout);

import PrintLayout from '@/layouts/Print.vue';
Vue.component('print-layout', PrintLayout);

import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

import VTooltip from 'v-tooltip';
Vue.use(VTooltip);

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

import VueMoment from 'vue-moment';
Vue.use(VueMoment);

const i18n = new VueI18n({
  locale: 'de'
});

import {
  applyPolyfills,
  defineCustomElements
} from '@solutiondatabase/international-article-search/loader';
applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.use(VueLazyload, {
  preLoad: 1,
  attempt: 1
});

Vue.filter('truncate', function(text: string, length: number, clamp: string) {
  clamp = clamp || '...';
  let node = document.createElement('div');
  node.innerHTML = text;
  let content = node.textContent;
  if (content === null || content.length === 0) return '';
  return content.length > length ? content.slice(0, length) + clamp : content;
});

Vue.filter('getIsoSku', function(article: any) {
  switch (article.version) {
    case 1:
      return article.isoSku;
    case 2:
      return article.sdb_product.country.toLowerCase() + '-' + article.sdb_product.articleNumber;
    case 3:
      return (
        article.sdb_product.country.toLowerCase() +
        '-' +
        article.sdb_product.articleNumberInclCheckDigit
      );
    default:
      return 'Unknown Version';
  }
});

const createVue = () => {
  new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
};

Object.defineProperty(Vue.prototype, '$keycloak', { value: keycloak });

let fullURL = window.location.href;
let path = fullURL.split('#/');

if(path[1] === 'liveness') {
  createVue();
} else {
  initKeycloak(createVue, new KeycloakVuex(store));
}
