<template>
  <div class="editable">
    <datepicker
      :errors="errors"
      :date="deadline"
      :settings="datepickerSettings"
      @updateEvent="deadlineUpdated"
    />
  </div>
</template>
<script>
import Datepicker from '@/components/shared/Datepicker';

export default {
  name: "BriefingDeadline",
  components: {
    Datepicker,
  },
  props: {
    deadlineProp: String,
    datepickerSettings: {
      type: Object, default() {
        return {
          validationClass: 'deadline',
          text_label: this.$t('i18n.label_deadline'),
          text_helper: null
        }
      }
    }
  },
  data() {
    return {
      errors: {},
      deadline: null
    }
  },
  watch: {
    deadlineProp() {
      this.deadline = this.deadlineProp
    }
  },
  created() {
    this.deadline = this.deadlineProp
  },
  methods: {
    deadlineUpdated(val) {
      this.$emit("deadlineUpdated", val)
    }
  }

}
</script>


<i18n>
{
  "de": {
    "i18n": {
      "label_deadline": "Deadline"
    }
  }
}
</i18n>
