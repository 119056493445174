<template>
  <div>
    <loading v-if="resultLoading" />
    <transition name="fadein">
      <div v-if="!resultLoading">
        <div>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <h1 class="mb-4">
                  {{ $t('i18n.headline_briefing') }}
                </h1>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="mb-4">
                  <div class="editable">
                    <datepicker
                      :errors="errors"
                      :date="form.deadline"
                      :settings="datepickerSettings"
                      @updateEvent="deadlineUpdated"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                id="br-details"
                class="container"
              >
                <div class="row">
                  <div class="col-12 col-md-4">
                    <div class="mb-4">
                      <div class="mb-0">
                        <label>{{ $t('i18n.label_uploads') }}</label>
                        <upload
                          upload-path="briefing-stock-images"
                          :already-uploaded-files="briefing.uploads"
                          :uploadable-id="id"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label>{{ $t('i18n.label_annotation') }}</label>
              </div>
              <div class="col-12 col-lg-7">
                <div class="editable mb-4 annotation">
                  <editor
                    :initial-content="form.annotation"
                    :placeholder="$t('i18n.placeholder_annotation')"
                    :active-buttons="['bold', 'strike','underline', 'bullet_list', 'ordered_list']"
                    @blur="editorUpdated"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from '@/axios';
import {EventBus} from '@/event';
import clonedeep from 'lodash.clonedeep';
import Datepicker from '@/components/shared/Datepicker';
import Editor from '@/components/shared/Editor.vue';
import Loading from '@/components/shared/Loading';
import Upload from '@/components/shared/Upload.vue'


export default {
    name: "BriefingStockImage",
    components: {
        Editor,
        Datepicker,
        Loading,
        Upload
    },
    props: {
        contentPieceID: String,
        id: String
    },
    data() {
        return {
            errors: {},
            resultLoading: true,
            briefing: Object,
            form: Object
        }
    },
    computed: {
        datepickerSettings() {
            let values = {
                'validationClass': 'deadline',
                'text_label': this.$t('i18n.label_deadline'),
                'text_placeholder': this.$t('i18n.placeholder_deadline', [this.briefing.content_piece.deadlineFormated]),
                'text_helper': this.$t('i18n.helper_deadline')
            }
            return values;
        }
    },
    watch: {
        id() {
            this.getbriefing()
        },
        briefing() {
            this.initForm();
        }
    },
    created() {
        this.getbriefing();
        this.initForm();
    },
    methods: {
        initForm() {
            this.form = clonedeep(this.briefing);
        },
        async getbriefing() {
            axios.get('/briefing-stock-image/' + this.id).then((result) => {
                this.briefing = result.data;
                EventBus.$emit('autosave', {'status': true, 'datetime': ''});
                this.resultLoading = false;
            })
        },
        async updateBriefing() {

            let params = {
                ...this.form
            };

            EventBus.$emit('loadingstatus', 'show');
            try {
                await axios.put('/briefing-stock-image/' + this.id, params);
                EventBus.$emit('loadingstatus', 'completed');
                this.errors = {};
            } catch (error) {
                if (error.response.data.hasOwnProperty('errors')) {
                    this.errors = error.response.data.errors;
                }
                EventBus.$emit('loadingstatus', 'failed');
            }
        },
        deadlineUpdated(val) {
            this.errors = {};
            this.form.deadline = val;
            this.updateBriefing();
        },
        editorUpdated(val) {
            this.form.annotation = val;
            this.updateBriefing();
        }
    }
}
</script>

<style lang="scss">
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '@/assets/scss/_variables';

.annotation {
    height: 100%;
    min-height: 150px;
}
</style>


<i18n>
{
"de": {
"i18n": {
"headline_briefing": "Stock-Bild",
"label_deadline": "Deadline",
"placeholder_deadline": "{0} (übernommen vom Content Piece)",
"helper_deadline": " ",
"label_uploads": "Referenzbilder",
"label_annotation": "Anmerkungen",
"placeholder_annotation": "Anmerkungen zu diesem Briefing"
}
}
}
</i18n>
