
import Vue from 'vue';
import { EventBus } from '@/event';

export default Vue.extend({
  name: 'PublishBriefingSoloBar',
  props: {
    ordered: Boolean,
    briefingId: String,
    briefingType: String,
    briefingResultName: String
  },
  data() {
    return {
      error: null
    };
  },
  computed: {
    translatedError(): String {
      if (!this.error) return '';
      if (this.error == 'camera') {
        return this.$t('error_camera').toString();
      } else if (this.error == 'formats') {
        return this.$t('error_formats').toString();
      } else {
        return this.$t('error_general').toString();
      }
    },
    isSelectedExportable(): Boolean {
      return this.$store.state.BriefingRendering.isSelectedExportable;
    }
  },
  methods: {
    async exportBriefing(): Promise<void> {
      this.error = null;
      EventBus.$emit('loadingstatus', 'show');
      try {
        await this.$store.dispatch(this.briefingType + '/EXPORT', this.briefingId);
        EventBus.$emit('loadingstatus', 'completed');

        // reload same route after export to get to print view
        // you cannot call router push to same url
        this.$router.go(0);
      } catch (error) {
        this.error = error?.response?.data?.message;
        EventBus.$emit('loadingstatus', 'failed');
      }
    },
    setBackground(): string {
      if (this.ordered === true) {
        return 'bg-success';
      } else if (this.ordered === false && this.error) {
        return 'bg-danger';
      } else {
        return 'bg-primary';
      }
    }
  }
});
