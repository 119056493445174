<template>
  <div class="row">
    <div class="col-12">
      <div class="card w-100">
        <div class="card-header">
          <strong>Referenzen</strong>
        </div>
        <div class="card-body py-2">
          <div
            v-if="uploads.length > 0"
            class="row"
          >
            <div
              v-for="(upload,cpUploadIndex) in uploads"
              :key="cpUploadIndex"
              class="col-3"
            >
              <img
                :src="getThumbnailPath(upload.hashed_name)"
                class="border img-fluid mb-2"
                :alt="upload.file_name"
                :title="upload.file_name"
                @error="fallbackPlaceholder"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getThumbnailPath} from "@/composables/Url";
import Image from "@/mixins/Image.vue";

export default {
  name: "PrintUploads",
  mixins: [Image],
  props: {
    uploads: Array
  },
  methods: {
    // can be removed when migrating to Vue3
    getThumbnailPath(hashedName) {
      return getThumbnailPath(hashedName)
    }
  }
}
</script>
