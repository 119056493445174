<template>
  <div class="h-100 d-flex flex-column">
    <div>
      <h3>{{ settings.headlinePlaceholder }}</h3>
      <span class="text-muted small" v-html="settings.description" />
    </div>

    <div>
      <div class="product-bundle">
        <div v-if="items.length === 0" class="text-muted small mb-4">
          <p>{{ $t('info_no_articles') }}</p>
        </div>

        <div v-else>
          <div class="row mb-3">
            <div class="col-3 text-center">
              <strong>{{ $t('th_image') }}</strong>
            </div>
            <div class="col-3">
              <strong>{{ $t('th_sku') }}</strong>
            </div>
          </div>
          <div v-for="(article, index) in items" :key="article.articleNumber" class="row listitem">
            <div class="col-3 text-center">
              <div
                v-b-modal="'' + settings.productBundleType + article.articleNumber + ''"
                class="article-img-wrapper"
              >
                <img
                  class="img-fluid"
                  :src="imgUrl(article.sdbProduct.articleNumber)"
                  @error="imgPlaceholder"
                />
                <b-modal
                  :id="settings.productBundleType + article.sdbProduct.articleNumber"
                  size="lg"
                  centered
                  hide-footer
                  :title="article.sdbProduct.nationalArticleIdInclCheckDigit"
                >
                  <img
                    class="img-fluid"
                    :src="imgUrl(article.sdbProduct.articleNumber)"
                    @error="imgPlaceholder"
                  />
                </b-modal>
              </div>
            </div>

            <div class="col-9">
              <div class="form-group">
                <p class="form-control transparent" readonly>
                  {{
                    article.sdbProduct.country.toLowerCase() +
                      '-' +
                      article.sdbProduct.articleNumberInclCheckDigit
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="editable"
          class="btn btn-block text-white btn-primary cursor-pointer edit-productbundle"
          @click="showModal()"
        >
          {{ $t('btn_edit_articles') }}
        </div>

        <b-modal
          id="assign-articles"
          v-model="modalShow"
          size="xl"
          centered
          :no-close-on-backdrop="true"
          :no-close-on-esc="true"
        >
          <template #modal-header="">
            <h5>{{ $t('headline_select_articles') }}</h5>
          </template>
          <div class="row">
            <div class="col-8">
              <iass-wrapper @articleAdded="addProductFromSearch" />
            </div>
            <div class="col-4">
              <h3>{{ settings.headlinePlaceholder }}</h3>

              <div v-if="errors" class="row mb-3">
                <div v-for="(error, index) in errors" :key="index" class="alert alert-danger">
                  {{ error[0] }}
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-3">
                  <strong>{{ $t('th_image') }}</strong>
                </div>
                <div class="col-3">
                  <strong>{{ $t('th_sku') }}</strong>
                </div>
              </div>

              <div
                v-for="(article, index) in items"
                :key="article.articleNumber"
                class="row listitem"
              >
                <div class="col-3">
                  <div
                    v-b-modal="
                      '' + settings.productBundleType + article.sdbProduct.articleNumber + ''
                    "
                    class="article-img-wrapper d-flex"
                  >
                    <div class="justify-content-center align-self-center">
                      <img
                        class="img-fluid border"
                        :src="imgUrl(article.sdbProduct.articleNumber)"
                        @error="imgPlaceholder"
                      />
                      <b-modal
                        :id="
                          'inmodal' + settings.productBundleType + article.sdbProduct.articleNumber
                        "
                        size="lg"
                        centered
                        hide-footer
                        :title="article.sdbProduct.nationalArticleIdInclCheckDigit"
                      >
                        <img
                          class="img-fluid"
                          :src="imgUrl(article.sdbProduct.articleNumber)"
                          @error="imgPlaceholder"
                        />
                      </b-modal>
                    </div>
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group">
                    <p class="form-control transparent" readonly>
                      {{
                        article.sdbProduct.country.toLowerCase() +
                          '-' +
                          article.sdbProduct.articleNumberInclCheckDigit
                      }}
                    </p>
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <button
                      class="btn text-danger delete-article"
                      @click="deleteProduct(article.id, index)"
                    >
                      <font-awesome-icon :icon="['fas', 'trash']" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-slot:modal-footer="{ ok }">
            <b-button variant="primary text-white" @click="closeModal()">
              {{ $t('btn_ok') }}
            </b-button>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios';
import BootstrapFormValidation from '@/mixins/BootstrapFormValidation';
import IassWrapper from '@/components/shared/article-broker/iass-wrapper';
import { EventBus } from '@/event';
import _ from 'lodash';
import clonedeep from 'lodash.clonedeep';
import { articleSchema } from '@/components/shared/article-broker/article-schema-v4';

export default {
  name: 'Products',
  components: { IassWrapper },
  mixins: [BootstrapFormValidation],
  props: {
    editable: { type: Boolean, default: true },
    products: { type: Array, default: () => [] },
    briefingId: { type: String, default: '' },
    settings: {
      type: Object,
      default: () => ({
        headlinePlaceholder: null,
        description: null
      })
    }
  },
  data() {
    return {
      items: [],
      errors: [],
      modalShow: false
    };
  },
  created() {
    if (this.products != null) {
      this.items = clonedeep(this.products);
    }
  },
  methods: {
    async addProductFromSearch(article) {
      this.errors = [];

      let exists = _.findIndex(this.items, { nationalId: article.nationalArticleIdInclCheckDigit });

      if (exists === -1) {
        try {
          const validatedArticle = await articleSchema.validateAsync(article);
          await this.addProduct(validatedArticle);
        } catch (err) {
          this.errors.push({ 0: err });
        }
      } else {
        this.errors.push({ 0: 'Product already added!' });
      }
    },
    imgUrl(articleNumber) {
      return (
        '//images.obi.info/file_db/services/serve_product.php?firma=6&article_nr=' + articleNumber
      );
    },
    imgPlaceholder(e) {
      e.target.src = require('@/assets/images/fallback.jpg');
    },
    showModal() {
      this.modalShow = true;
    },
    closeModal() {
      this.errors = [];
      this.modalShow = false;
    },
    async addProduct(article) {
      EventBus.$emit('loadingstatus', 'show');
      this.errors = [];
      try {
        let result = await axios.post(`/batch-briefing-article/${this.briefingId}/add-product`, {
          sdbProduct: article
        });
        this.items.push(result.data);
        this.$emit('updated');
        EventBus.$emit('loadingstatus', 'completed');
      } catch (error) {
        EventBus.$emit('loadingstatus', 'failed');
        this.errors = error.response.data.errors;
      }
    },
    async deleteProduct(articleId, index) {
      EventBus.$emit('loadingstatus', 'show');
      this.errors = [];
      try {
        await axios.delete(
          `/batch-briefing-article/${this.briefingId}/delete-product/${articleId}`
        );
        this.$delete(this.items, index);
        this.$emit('updated');
        EventBus.$emit('loadingstatus', 'completed');
      } catch (error) {
        EventBus.$emit('loadingstatus', 'failed');
        this.errors = error.response.data.errors;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.article-img-wrapper {
  display: inline-block;
  text-align: center;
  max-height: 36px;
  max-width: 36px;
  overflow: hidden;
  background: #fff;
  height: 100%;
  cursor: pointer;
}
</style>

<i18n>
{
  "de": {
    "th_image": "Bild",
    "th_sku": "Artikel",
    "headline_select_articles": "Artikel auswählen",
    "btn_edit_articles": "Artikel bearbeiten",
    "btn_ok": "OK",
    "info_no_articles": "Aktuell sind noch keine Artikel gepflegt. Bitte füge noch die benötigten Artikel hinzu."
  }
}
</i18n>
