<template>
  <sd-search-wrapper
    :key="language"
    :namespace="getIassNamespace"
    class="article-broker hydrated"
    :language="language"
    @selectedArticles="addProductFromSearch"
  />
</template>
<script>
import {articleSchema} from "@/components/shared/article-broker/article-schema.iass.v1";

export default {
  name: "IassWrapper",
  data() {
    return {
      language: "de",
    }
  },
  computed:{
    getIassNamespace(){
      if(process.env.VUE_APP_IASS_NAMESPACE !== undefined){
        return process.env.VUE_APP_IASS_NAMESPACE;
      } else {
        console.error("env variable VUE_APP_IASS_NAMESPACE is not defined")
      }
      return "";
    }
  },
  methods:{
    async addProductFromSearch(event) {
      let article = await this.validateAndConvertArticle(event.detail);
      this.$emit("articleAdded", article)
    },
    async validateAndConvertArticle(article) {
      const validatedArticle = await articleSchema.validateAsync(article, {stripUnknown: true});
      return this.convertArticleFromIassToArticleBrokerSchema(validatedArticle);
    },
    getRootArticle(article) {
      const countryFromLocale = article.locale.substr(3, 2);
      return article.countryArticles.find(c => {
        return c.country === countryFromLocale;
      })
    },
    buildHyphenatedArticleNumberForCountryArticle(countryArticle) {
      return countryArticle.country.toLowerCase() + '-' + countryArticle.articleNumberInclCheckDigit
    },
    convertArticleFromIassToArticleBrokerSchema(article) {
      let rootArticle = {...this.getRootArticle(article)}
      rootArticle.title = rootArticle.articleTitle
      delete rootArticle.articleTitle

      return {
        ...rootArticle,
        ...article
      }
    },
  }
}
</script>
