<template>
  <div>
    <loading v-if="resultLoading" />
    <transition name="fadein">
      <div v-if="!resultLoading">
        <content-piece-details-view
          v-if="!contentPiece.ordered"
          :id="id"
          :content-piece="contentPiece"
        />
        <content-piece-details-print
          v-else
          :id="id"
          :content-piece="contentPiece"
        />
      </div>
    </transition>
    <transition name="fadein">
      <publish-content-piece-bar
        v-if="!resultLoading"
        :content-piece-i-d="contentPiece.id"
        :ordered="contentPiece.ordered"
        :ready-to-finalize="readyToFinalize"
      />
    </transition>
  </div>
</template>
<script>
import Loading from '@/components/shared/Loading';
import ContentPieceDetailsView from '@/views/ContentPieceDetailsView';
import ContentPieceDetailsPrint from '@/views/ContentPieceDetailsPrint';
import PublishContentPieceBar from '@/components/shared/PublishContentPieceBar';

export default {
    name: "ContentPieceDetails",
    components: {
        Loading,
        ContentPieceDetailsView,
        ContentPieceDetailsPrint,
        PublishContentPieceBar
    },
    props: {
        id: String
    },
    data() {
        return {
            errors: [],
            resultLoading: true,
        }
    },
    computed: {
        contentPiece() {
            return this.$store.state.ContentPiece.selected;
        },
        readyToFinalize(){

          let ready = true;

          if(this.contentPiece.briefing_renderings.length > 0){
            this.contentPiece.briefing_renderings.forEach(function(briefingRendering){
                if(briefingRendering.camera==null || briefingRendering.camera.length === 0){
                  ready=false;
                }
            })
          }

          if(this.contentPiece.briefing_show_flats.length > 0){
            this.contentPiece.briefing_show_flats.forEach(function(briefingShowFlat){
                if(briefingShowFlat.camera==null || briefingShowFlat.camera.length === 0){
                  ready=false;
                }
            })
          }

          return ready
        }
    },
    watch: {
        id() {
            this.$store.dispatch('ContentPiece/GET_BY_ID', this.id);
        },
        contentPiece() {
            this.resultLoading = false;
        }
    },
    created() {
        this.$store.dispatch('ContentPiece/GET_BY_ID', this.id);
    },
}
</script>
