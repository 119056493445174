<template>
  <div class="editor">
    <editor-menu-bar
      v-slot="{ commands, isActive, focused }"
      :editor="editor"
    >
      <div
        class="menubar is-hidden"
        :class="{ 'is-focused': focused }"
      >
        <span
          v-for="actionName in activeButtons"
          :key="actionName"
        >
          <span
            v-if="actionName === 'bold'"
            class="menubar__button"
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
          >
            <icon name="bold" />
          </span>
          <span
            v-if="actionName === 'italic'"
            class="menubar__button"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
          >
            <icon name="italic" />
          </span>

          <span
            v-if="actionName === 'strike'"
            class="menubar__button"
            :class="{ 'is-active': isActive.strike() }"
            @click="commands.strike"
          >
            <icon name="strike" />
          </span>

          <span
            v-if="actionName === 'underline'"
            class="menubar__button"
            :class="{ 'is-active': isActive.underline() }"
            @click="commands.underline"
          >
            <icon name="underline" />
          </span>

          <span
            v-if="actionName === 'code'"
            class="menubar__button"
            :class="{ 'is-active': isActive.code() }"
            @click="commands.code"
          >
            <icon name="code" />
          </span>

          <span
            v-if="actionName === 'paragraph'"
            class="menubar__button"
            :class="{ 'is-active': isActive.paragraph() }"
            @click="commands.paragraph"
          >
            <icon name="paragraph" />
          </span>

          <span
            v-if="actionName === 'h1'"
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 1 }) }"
            @click="commands.heading({ level: 1 })"
          >H1</span>

          <span
            v-if="actionName === 'h2'"
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
            @click="commands.heading({ level: 2 })"
          >H2</span>

          <span
            v-if="actionName === 'h3'"
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
            @click="commands.heading({ level: 3 })"
          >H3</span>

          <span
            v-if="actionName === 'bullet_list'"
            class="menubar__button"
            :class="{ 'is-active': isActive.bullet_list() }"
            @click="commands.bullet_list"
          >
            <icon name="ul" />
          </span>

          <span
            v-if="actionName === 'ordered_list'"
            class="menubar__button"
            :class="{ 'is-active': isActive.ordered_list() }"
            @click="commands.ordered_list"
          >
            <icon name="ol" />
          </span>

          <span
            v-if="actionName === 'blockquote'"
            class="menubar__button"
            :class="{ 'is-active': isActive.blockquote() }"
            @click="commands.blockquote"
          >
            <icon name="quote" />
          </span>

          <span
            v-if="actionName === 'code_block'"
            class="menubar__button"
            :class="{ 'is-active': isActive.code_block() }"
            @click="commands.code_block"
          >
            <icon name="code" />
          </span>

          <span
            v-if="actionName === 'horizontal_rule'"
            class="menubar__button"
            @click="commands.horizontal_rule"
          >
            <icon name="hr" />
          </span>

          <span
            v-if="actionName === 'undo'"
            class="menubar__button"
            @click="commands.undo"
          >
            <icon name="undo" />
          </span>

          <span
            v-if="actionName === 'redo'"
            class="menubar__button"
            @click="commands.redo"
          >
            <icon name="redo" />
          </span>
        </span>
      </div>
    </editor-menu-bar>

    <editor-content
      class="editor__content"
      :editor="editor"
    />
  </div>
</template>

<script>
import Icon from './Icons'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Placeholder
} from 'tiptap-extensions'
export default {
  name: 'Editor',
  components: {
    EditorContent,
    EditorMenuBar,
    Icon
  },
  props: {
    initialContent: {
      type: String,
      default: ''
    },
    placeholder: String,
    activeButtons: {
      type: Array,
      validator: function(list) {
        for (let el of list) {
          // The value must match one of these strings
          if (
            [
              'bold',
              'strike',
              'underline',
              'code',
              'paragraph',
              'h1',
              'h2',
              'h3',
              'bullet_list',
              'ordered_list',
              'blockquote',
              'code_block',
              'horizontal_rule',
              'undo',
              'redo'
            ].indexOf(el) === -1
          ) {
            return -1
          }
        }
        return 1
      },
      default: function(){
        //return ['bold', 'italic']
      }
    }
  },
  data() {
    return {
      html: '',
      json: '',
      editor: new Editor({
        extensions: [
          new Link(),
          new BulletList(),
          new ListItem(),
          new OrderedList(),
          new Bold(),
          new Strike(),
          new Underline(),
          new History(),
          new Placeholder({
            emptyNodeClass: 'is-empty',
            emptyNodeText: this.placeholder,
            showOnlyWhenEditable: true,
          }),
        ],
        content: this.initialContent
      })
    }
  },
  watch: {
    initialContent: function(newVal, oldVal) { // watch it
      this.editor.setContent(this.initialContent);
    }
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  created() {
    this.html = this.editor.getHTML()
    this.json = this.editor.getJSON()
    this.editor.on('update', () => {
      this.html = this.editor.getHTML()
      this.json = this.editor.getJSON()
      this.$emit('update', this.html)
    }),
    this.editor.on('blur', () => {
      this.html = this.editor.getHTML()
      this.json = this.editor.getJSON()
      this.$emit('blur', this.html)
    })
  }
}
</script>

<style lang="scss">
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '@/assets/scss/_variables';

.editor{
  position:relative;
  .menubar {
    display:inline-block;
    position:absolute;
    top:-30px;
    right:0px;
    padding-bottom:5px;
      &.is-hidden{
      visibility:hidden;
      transition: opacity 500ms;
      opacity:0;
      &.is-focused {
        visibility:visible !important;
        opacity:1;
      }
    }
    .is-active {
      border:1px solid $gray-400;
    }
  }
  .menubar__button {
      border:1px solid transparent;
      font-weight: 700;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      background: transparent;
      color: $gray-900;
      padding: .5rem;
      margin-right: .2rem;
      cursor: pointer;
  }

  .ProseMirror {
      outline: none !important;
  }

  p.is-empty:first-child::before {
  content: attr(data-empty-text);
  float: left;
  color: $third;
  pointer-events: none;
  height: 0;
  }

  p:last-child {
    margin:0px;
  }
}
</style>