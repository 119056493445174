import axios from '@/axios';

export const StoreFactory = {
  get: (briefingURL, formatSingle) => {
    return {
      namespaced: true,
      state: {
        items: [],
        filter: {},
        pagination: { current_page: null, total: null, per_page: null },
        selected: {},
        isSelectedExportable: true
      },
      mutations: {
        SET_ITEMS(state, items) {
          state.items = items;
        },
        SET_PAGINATION(state, pagination) {
          state.pagination.current_page = pagination.current_page;
          state.pagination.total = pagination.total;
          state.pagination.per_page = pagination.per_page;
        },
        SET_ITEM(state, item) {
          state.items.splice(0, 0, item);
        },
        SET_SELECTED(state, selected) {
          state.selected = selected;
        },
        DELETE_ITEM(state, briefingId) {
          let foundIndex = state.items.findIndex(briefing => briefing.id === briefingId);
          if (foundIndex > -1) {
            state.items.splice(foundIndex, 1);
          }
        },
        SET_IS_SELECTED_EXPORTABLE(state, value) {
          state.isSelectedExportable = value;
        },
        SET_FILTER(state, filter) {
          state.filter = filter;
        }
      },
      actions: {
        async FETCH({ commit, state }) {
          const page = state?.filter?.params == null ? 1 : state?.filter?.params;
          const response = await axios.post(`/${briefingURL}-filtered?page=${page}`, state.filter);

          response.data.data.forEach(briefing => {
            formatSingle(briefing);
          });

          commit('SET_ITEMS', response.data.data);
          commit('SET_PAGINATION', response.data.meta);
        },
        async GET_BY_ID({ commit }, id) {
          try {
            const response = await axios.get(`/${briefingURL}/${id}`);
            const briefing = response.data;
            formatSingle(briefing);
            commit('SET_SELECTED', briefing);
          } catch (err) {
            commit('SET_SELECTED', {});
          }
        },
        async DELETE({ commit }, id) {
          await axios.delete(`/${briefingURL}/${id}`);
          commit('DELETE_ITEM', id);
        },
        async UPDATE({ commit }, briefing) {
          let response = await axios.put(`/${briefingURL}/${briefing.id}`, briefing);

          const updatedBriefing = response.data;
          formatSingle(updatedBriefing);
          commit('SET_SELECTED', updatedBriefing);
        },
        async CLONE({ commit }, params) {
          let response = await axios.post(`/${briefingURL}/${params.id}/clone`, params);
          const newBriefing = response.data;
          formatSingle(newBriefing);
          commit('SET_ITEM', newBriefing);
        },
        async EXPORT({ commit }, id) {
          await axios.get(`/${briefingURL}/${id}/export`);
        },
        async SET_IS_EXPORTABLE({ commit }, value) {
          commit('SET_IS_SELECTED_EXPORTABLE', value);
        }
      },
      getters: {
        FILTER: (state: any) => {
          return state.filter;
        }
      }
    };
  }
};
