
import ContentPieceSingleLine from '@/components/contentpieces/ContentPieceSingleLine.vue';
import Loading from '@/components/shared/Loading.vue'
import {EventBus} from '@/event';
import CalculateDelay from '@/mixins/CalculateDelay.vue';
import AddContentPieceButton from '@/components/contentpieces/AddContentPieceButton.vue';
import Vue from 'vue';
import {ContentPiece} from "@/entity/ContentPiece";
import SearchAndPagination from "@/components/shared/SearchAndPagination.vue";


export default Vue.extend({
  name: "ContentPiecesList",
  components: {
    ContentPieceSingleLine,
    Loading,
    AddContentPieceButton,
    SearchAndPagination
  },
  mixins: [CalculateDelay],
  data() {
    return {
      resultLoading: true
    }
  },
  computed: {
    contentPieces(): ContentPiece[] {
      return this.$store.state.ContentPiece.items;
    }
  },
  async created(): Promise<void> {
    //EventBus.$on('reloadContentPiecesList', async () => await this.loadContentPieces());
  },
  methods: {
    setLoading(value) {
      this.resultLoading = value;
    }
  }
})
