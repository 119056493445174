<template>
  <div
    class="col-4 d-flex align-items-stretch"
  >
    <div class="card w-100">
      <div class="card-header">
        <strong>Szene {{ briefing.scene }}</strong>

        <!--        <span class="float-right"><a
          target="_blank"
          :href="'https://jira.obi.net/browse/'+briefing.jira_export.response.key"
          class="link text-default"
        >{{ briefing.jira_export.response.key }}</a>{{ (briefing.jira_export.status)?' - '+briefing.jira_export.status.name:'' }}</span>-->
      </div>
      <div class="card-body py-2 even-odd-row">
        <div class="row">
          <div class="col-6">
            <strong>Typ</strong>
          </div><div class="col-6">
            Freies Briefing
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <strong>Deadline</strong>
          </div><div class="col-6">
            {{ (briefing.deadline)?briefing.deadline:contentPiece.deadline | moment("DD.MM.Y") }}
          </div>
        </div>


        <div v-if="briefing.camera!=null">
          <div
            v-for="(camera,cameraIndex) in briefing.camera"
            :key="'camera'+cameraIndex"
            class="row"
          >
            <div class="col-6">
              <strong>Kamera {{ camera.id }}</strong>
            </div><div class="col-6">
              <span
                v-for="(variant,variantIndex) in camera.variants"
                :key="'variant'+variantIndex"
              >
                V{{ variant.id }},
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <strong>Bilder gesamt</strong>
            </div>
            <div class="col-6">
              {{ resultedImages }}
            </div>
          </div>
        </div>


        <div
          v-if="briefing.product_bundle!=null"
          class="row"
        >
          <div class="col-6">
            <strong>Zusätzliche Artikel</strong>
          </div>
          <div
            v-for="(briefingProduct,briefingProductIndex) in briefing.product_bundle.products"
            :key="briefingProductIndex"
            class="col-12"
          >
            {{ briefingProduct | getIsoSku }}
          </div>
        </div>

        <div
          v-if="briefing.uploads.length > 0"
          class="row"
        >
          <div class="col-6">
            <strong>Referenzen</strong>
          </div>

          <div class="col-12">
            <div class="row">
              <div
                v-for="(image,briefingUploadIndex) in briefing.uploads"
                :key="briefingUploadIndex"
                class="col-4"
              >
                <img
                  :src="getThumbnailPath(image.hashed_name)"
                  class="border img-fluid mb-4"
                  :alt="image.hashed_name"
                  @error="replaceByDefaultThumb"
                >
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="briefing.camAssets && briefing.camAssets.length > 0"
          class="col-12 mt-4"
        >
          <h3>Cam Assets</h3>
          <div
            v-for="(camAsset,index) in briefing.camAssets"
            :key="index"
          >
            <a
              :href="camAsset.url"
              target="_blank"
            >{{ camAsset.url }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

    import {getThumbnailPath} from "@/composables/Url";

    export default {
        name: "RenderingBriefingSummary",
        props: {
          title: {'type': String, 'default': null},
          contentPiece: {'type': Object, 'default': null},
          briefing: {'type': Object, 'default': null}
        },
        computed:{

          resultedImages(){
              let images = [];

              this.briefing.camera.forEach(function (camera) {
                camera['variants'].forEach(function (variant) {

                    let cameraAndVariant = {
                      'camera': {
                        'id': camera['id'],
                        'text': camera['text']
                      },
                      'variant': {
                        'id': variant['id'],
                        'text': variant['text']
                      }
                    }
                    images.push(cameraAndVariant)
                })

                }.bind(this));



              return images.length;
            }
        },
      methods: {
        getThumbnailPath(hashedName) {
          return getThumbnailPath(hashedName)
        },
        replaceByDefaultThumb(e){
          e.target.src = require('@/assets/images/no-preview.jpg');
        }
      }
    }
</script>
