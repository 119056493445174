<template>
  <div class="col-4 d-flex align-items-stretch">
    <div class="card w-100">
      <div class="card-header">
        <strong>{{ title }}</strong>

        <!--        <span class="float-right"><a
          target="_blank"
          :href="'https://jira.obi.net/browse/'+briefing.jira_export.response.key"
          class="link text-default"
        >{{ briefing.jira_export.response.key }}</a>{{ (briefing.jira_export.status)?' - '+briefing.jira_export.status.name:'' }}</span>-->
      </div>
      <div class="card-body even-odd-row">
        <div class="row">
          <div class="col-8">
            <strong>Deadline</strong>
          </div><div class="col-4">
            {{ (briefing.deadline)?briefing.deadline:contentPiece.deadline | moment("DD.MM.Y") }}
          </div>
        </div>

        <div
          v-for="(option,optionIndex) in briefing.options"
          :key="optionIndex"
          class="row"
        >
          <div class="col-9">
            <strong>{{ option.title }}</strong>
          </div>
          <div class="col-3">
            <font-awesome-icon
              v-if="option.checked==true"
              :icon="['fas', 'check']"
            /><font-awesome-icon
              v-else
              :icon="['fas', 'times']"
            />
          </div>
        </div>

        <div
          v-if="briefing.annotation"
          class="row"
        >
          <div class="col-12 readmore">
            <p class="mb-1">
              <strong>Anmerkung</strong>
            </p>

            <truncate
              clamp="mehr..."
              :length="90"
              class="small"
              less="weniger"
              type="html"
              :text="briefing.annotation"
            />
          </div>
        </div>

        <div
          v-if="briefing.uploads && briefing.uploads.length > 0"
          class="row"
        >
          <div class="col-6">
            <strong>Referenzen</strong>
          </div>

          <div class="col-12">
            <div class="row">
              <div
                v-for="(image,briefingUploadIndex) in briefing.uploads"
                :key="briefingUploadIndex"
                class="col-4"
              >
                <img
                  :src="getThumbnailPath(image.hashed_name)"
                  class="border img-fluid mb-4"
                  :alt="image.hashed_name"
                  @error="replaceByDefaultThumb"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import truncate from 'vue-truncate-collapsed';
    import {getThumbnailPath} from "@/composables/Url";
    export default {
        name: "ColaBriefingSummary",
        components: {
          truncate
        },
        props: {
          title: {'type': String, 'default': null},
          contentPiece: {'type': Object, 'default': null},
          briefing: {'type': Object, 'default': null}
        },
        methods: {
          getThumbnailPath(hashedName) {
            return getThumbnailPath(hashedName)
          },
          replaceByDefaultThumb(e){
            e.target.src = require('@/assets/images/no-preview.jpg');
          }
        }
    }
</script>

<style lang="scss">
  .readmore {
    button {
      border:0px;
      text-decoration:underline;
      background:none;
      outline:none;
    }
  }
</style>
