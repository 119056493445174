import axios from '@/axios';
import {BatchBriefingArticle} from "@/entity/BatchBriefingArticle";

export default {
  namespaced: true,
  actions: {
    async GET_BY_ID({commit, dispatch}, id) {
      try {
        const {data} = await axios.get<BatchBriefingArticle>(`/batch-briefing-article/${id}`)
        commit('Project/ADD_OR_UPDATE_BATCH_BRIEFING', data, {root: true})
      } catch (error) {
        dispatch('Project/AXIOS_ERROR_HANDLER', error, {root: true})
      }
    },
    async DELETE({commit, dispatch}, id) {
      try {
        await axios.delete<BatchBriefingArticle>(`/batch-briefing-article/${id}`)
        await dispatch('Project/BATCH_DELETE', id, {root: true})
      } catch (error) {
        dispatch('Project/AXIOS_ERROR_HANDLER', error, {root: true})
      }
    },
  }
}
