
import Vue from "vue";
import {ImageFormat} from "@/entity/ImageFormat";
import BootstrapFormValidation from "@/mixins/BootstrapFormValidation.vue";

export default Vue.extend({
  name: "ImageFormatSelect",
  components: {},
  mixins: [BootstrapFormValidation],
  props: {
    settings: {
      type: Object, default: () => {
      }
    },
    formatValues: {
      type: Object as () => ImageFormat
    }
  },
  data() {
    return {
      formatSelected: false,
      selectedOrientation: '',
      width: 0,
      height: 0,
      minWidth: 0,
      minHeight: 0,
      errors: {}
    }
  },
  watch: {
    height() {
      if (this.selectedOrientation === 'SQUARE') {
        this.width = this.height;
      }
    },
    errors(){
      let isExportable = true;
      if(Object.keys(this.errors).length > 0) {
        isExportable = false;
      }
      this.$store.dispatch('BriefingRendering/SET_IS_EXPORTABLE', isExportable);
    }
  },
  created(): void {
    this.fillForm();
  },
  methods: {
    fillForm(): void {
      if (!this.formatValues) {
        let defaultOrientation = this.settings.preselectedOrientation;
        this.selectedOrientation = defaultOrientation;
        this.width = this.settings.orientations[defaultOrientation].defaultWidth;
        this.height = this.settings.orientations[defaultOrientation].defaultHeight;
        this.minHeight = this.settings.orientations[defaultOrientation].minHeight;
        this.minWidth = this.settings.orientations[defaultOrientation].minWidth;
      } else {
        this.selectedOrientation = this.formatValues.orientation;
        this.width = this.formatValues.width;
        this.height = this.formatValues.height;
        this.formatSelected = true;
        this.minHeight = this.settings.orientations[this.selectedOrientation].minHeight;
        this.minWidth = this.settings.orientations[this.selectedOrientation].minWidth;
      }
      this.emitDataToParent();
    },
    confirmSwitchingOrientation(orientationName): void {
      if (this.selectedOrientation == 'SQUARE' && orientationName !== 'SQUARE') {
        if (!confirm(this.$t('msg_switch_to_not_square').toString())) {
          return
        }
      }
      this.setSelectedOrientationWithDefaultValues(orientationName);
    },
    setSelectedOrientationWithDefaultValues(orientationName): void {
      this.errors = {};
      this.selectedOrientation = orientationName;
      this.width = this.settings.orientations[orientationName].defaultWidth;
      this.height = this.settings.orientations[orientationName].defaultHeight;
      this.emitDataToParent();
    },
    validateInput(): void {
      this.errors = {};

      if (this.width < this.minWidth) {
        Object.assign(this.errors, {width: [this.$t('msg_error_width', {width: this.minWidth}).toString()]});
      }

      if (this.height < this.minHeight) {
        Object.assign(this.errors, {height: [this.$t('msg_error_height', {height: this.minHeight}).toString()]});
      }

      if (this.selectedOrientation === 'PORTRAIT' && (this.width >= this.height)) {
        Object.assign(this.errors, {height: []});
        Object.assign(this.errors, {width: [this.$t('msg_error_portrait').toString()]});
      }

      if (this.selectedOrientation === 'LANDSCAPE' && (this.height >= this.width)) {
        Object.assign(this.errors, {height: []});
        Object.assign(this.errors, {width: [this.$t('msg_error_landscape').toString()]});
      }

      if (Object.keys(this.errors).length === 0) {
        this.emitDataToParent();
      }
    },
    emitDataToParent(): void {
      const params: ImageFormat = {
        name: this.settings.name,
        orientation: this.selectedOrientation,
        width: this.width,
        height: this.height,
        extension: this.settings.extension
      };

      if (this.formatSelected) {
        this.$emit('createOrUpdateFormat', params);
      } else {
        this.$emit('deleteFormat', params);
      }
    },
  }
})
