import { UserGroup } from '@/auth/user-groups';
import { transformToBackendUser } from '@/composables/UserTransform';
import { UserBackend } from '@/entity/User';
const initialState = {
	user: {
		userName: null,
		fullName: null,
		email: null,
		id: null,
		attributes: {}
	},
	accessToken: null,
	refreshToken: null,
	groups: []
};

const state = Object.assign({}, initialState);

const mutations = {
	SET_USER(state: any, user: any) {
		state.user.userName = user?.userName;
		state.user.email = user?.email;
		state.user.fullName = user?.fullName;
		state.user.id = user?.id;
		state.user.attributes = user.attributes;
	},
	DELETE_USER(state: any) {
		state.user = {
			fullName: null,
			email: null,
			id: null
		};
	},
	SET_ACCESS_TOKEN(state: any, token: string) {
		state.accessToken = token;
	},
	SET_REFRESH_TOKEN(state: any, token: string) {
		state.refreshToken = token;
	},
	ADD_GROUP(state: any, group: any) {
		state.groups.push(group);
	}
};

const getters = {
	IS_USER_LOGGED_IN: (state: any) => {
		let find = state.groups.findIndex(
			(usergroup: UserGroup) => usergroup.name == 'access-briefing-tool'
		);
		return find > -1 ? true : false;
	},
	IS_PROJECTMANAGER: (state: any) => {
		let find = state.groups.findIndex((usergroup: UserGroup) => usergroup.name == 'projectmanager');
		if (find > -1) return true;
		return false;
	},
	userBackend: (state: any): UserBackend => {
		return transformToBackendUser(state.user);
	}
};

const actions = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
