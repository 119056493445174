<template>
  <div class="col-12 col-md-6">
    <div>
      <label>Wähle ein Bild als Grundlage</label>
      <select
        v-model="activeFilters.Raum"
        class="form-control mb-3"
        @change="filterFavorite()"
      >
        <option value="">
          Wähle einen Raum
        </option>
        <option
          v-for="filter in favoriteFilter.Raum"
          :key="filter"
          :value="filter"
        >
          {{ filter }}
        </option>
      </select>
      <b-pagination
        v-model="pagination.currentPage"
        :total-rows="filteredFavorites.total"
        :per-page="pagination.itemsPerPage"
        :limit="20"
        aria-controls="my-table"
      />
    </div>
    <div class="row">
      <div
        v-for="(favorite,index) in activePage"
        :key="index"
        v-lazy-container="{ selector: 'img' }"
        class="col-6 col-md-4 mb-4 showflatgallery"
      >
        <img
          v-if="favorite.acdcAssetId"
          class="img-fluid border"
          :class="isActiveShowFlat(favorite.id)"
          :data-src="getAcdcAssetURL(favorite)"
          :data-loading="LoadingThumb"
          :alt="favorite.baseName"
          @click="selectShowFlat(favorite)"
        >
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/axios";
import clonedeep from 'lodash.clonedeep';

export default {
  name: "FavoriteSelector",
  data() {
    return {
      pagination: {
        itemsPerPage: 9,
        currentPage: 1,
      },
      activeFilters: {},
      favoriteFilter: Object,
      favorites: {},
      filteredFavorites: {},
      favoriteLoading: true,
      LoadingThumb: require('@/assets/images/lazy.jpg'),
    }
  },
  computed: {
    activePage() {
      if(this.filteredFavorites.data && this.filteredFavorites.data.length > 0) {
        const end = this.pagination.currentPage * this.pagination.itemsPerPage
        const start = end - this.pagination.itemsPerPage
        return this.filteredFavorites.data.slice(start, end)
      }
      return 1;
    }
  },
  created() {
    this.fetchShowFlats()
  },
  methods: {
    isActiveShowFlat(id) {
      return
    },
    selectShowFlat(favorite) {
      this.$emit("favoriteSelected", clonedeep(favorite))
    },
    buildShowFlatFilter(favorites) {
      let filterRoom = new Set()
      const sortedShowFlats = {"Raum": {}}
      favorites.forEach(favorite => {
        let filterAttr = "Raum";
        favorite.url = this.getAcdcAssetURL(favorite)
        if (Object.prototype.hasOwnProperty.call(sortedShowFlats[filterAttr], favorite.favorite.roomName)) {
          sortedShowFlats[filterAttr][favorite.favorite.roomName]["total"]++
          sortedShowFlats[filterAttr][favorite.favorite.roomName]["data"].push(favorite)
        } else {
          sortedShowFlats[filterAttr][favorite.favorite.roomName] = {
            data: [],
            total: 1
          }
          sortedShowFlats[filterAttr][favorite.favorite.roomName]["data"].push(favorite)
        }
        filterRoom.add(favorite.favorite.roomName);
      });
      this.favorites = sortedShowFlats

      this.calculatePaginationPerFilterAttribute()

      this.buildAndSetFilterDropDown(sortedShowFlats)
    },
    calculatePaginationPerFilterAttribute() {
      const firstLevelKeys = Object.keys(this.favorites)
      firstLevelKeys.forEach(fk => {
        let secondLevelKeys = Object.keys(this.favorites[fk]);
        secondLevelKeys.forEach(sk => {
          this.favorites[fk][sk]["pages"] = Math.ceil(this.favorites[fk][sk]["total"] / this.pagination.itemsPerPage)
        })
      })
    },
    buildAndSetFilterDropDown(sortedShowFlats) {
      const filterKeys = {}
      const firstLevelKeys = Object.keys(sortedShowFlats)
      firstLevelKeys.forEach(fk => {
        filterKeys[fk] = new Set()
        let secondLevelKeys = Object.keys(sortedShowFlats[fk]);
        secondLevelKeys.forEach(sk => {
          filterKeys[fk].add(sk)
        })
      })
      this.favoriteFilter = filterKeys;
      this.setDefaultFilterSelection(sortedShowFlats)
    },
    setDefaultFilterSelection(sortedShowFlats) {
      if (Object.keys(this.activeFilters).length) {
        this.filterFavorite();
        return;
      }

      const defaultFilter = {}

      const firstLevelKeys = Object.keys(sortedShowFlats)
      let secondLevelKeysOfFirstValue = Object.keys(sortedShowFlats[firstLevelKeys[0]]);
      defaultFilter[firstLevelKeys[0]] = secondLevelKeysOfFirstValue[0];

      this.activeFilters = defaultFilter;
      this.filterFavorite();
    },
    async fetchShowFlats() {
      this.favoriteLoading = true;
      try {
        let result = await axios.get('/favorites');
        if (result.data) {
          this.buildShowFlatFilter(result.data)
        }
      } catch (err) {
        console.error(err);
      }
      this.favoriteLoading = false;
    },
    filterFavorite() {
      this.pagination.currentPage = 1;
      this.filteredFavorites = this.favorites["Raum"][this.activeFilters.Raum];
    },
    dateFormatter(date) {
      return this.$moment(date).format('DD.MM.YYYY');
    },
    getAcdcAssetURL(favoriteAsset) {
      if (!favoriteAsset.acdcAssetId) return null;
      return process.env.VUE_APP_ACDC_URL + favoriteAsset.acdcAssetId + '/' + process.env.VUE_APP_ACDC_PROFILE_STANDARD + '/' + favoriteAsset.baseName;
    }
  }
}
</script>
