<template>
  <div>
  <div
    v-b-modal="'new-briefing'"
    class="float-right btn btn-outline-primary ml-2 mb-4 mb-xl-0"
  >
    <font-awesome-icon :icon="['fas', 'plus']" /> Neu
  </div>
  <b-modal
    id="new-briefing"
    ref="new-briefing-modal"
    no-close-on-backdrop
    hide-footer
    centered
    :title="$t('i18n.headline_create_briefing', { type: 'Eintrag' })"
  >
    <component
      :is="briefingType"
      @hideModal="hideModal()"
    />
  </b-modal>
  </div>
</template>
<script>

import BriefingArticle from '../briefing/forms/BriefingArticleForm';
import BriefingRendering from '../briefing/forms/BriefingRenderingForm';
import Project from "@/components/project/forms/ProjectForm";

export default {
  name: "NewBriefingModal",
  components: {
    BriefingArticle,
    BriefingRendering,
    Project
  },
  props: {
    briefingType: String,
  },
  methods: {
    hideModal() {
      this.$refs['new-briefing-modal'].hide()
    }
  }
}
</script>

<i18n>
{
  "de": {
    "i18n": {
      "headline_create_briefing": "Neuen %{type} erstellen",
      "headline_type": "Briefing Typ",
      "select_placeholder": "- Bitte wählen -"
    }
  }
}
</i18n>
