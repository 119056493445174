<template>
  <div
    v-if="teams.length>0"
  >
    <div
      id="addContentPiece"
      v-b-modal="'new-contentpiece'"
      class="float-right btn btn-outline-primary ml-2 mb-4 mb-xl-0"
    >
      <font-awesome-icon :icon="['fas', 'plus']" /> Neu
    </div>
    <new-content-piece-modal />
  </div>
  <div
    v-else
    class="container add-cp"
  >
    <div
      id="addContentPiece"
      v-tooltip.bottom="$t('i18n.msg_not_a_member')"
      class="disabled"
    >
      <font-awesome-icon :icon="['fas', 'plus']" />
    </div>
  </div>
</template>

<script>

    import NewContentPieceModal from '@/components/contentpieces/NewContentPieceModal';
    import { mapState } from 'vuex'
    export default {
        name: 'AddContentPieceButton',
        components: {
            NewContentPieceModal
        },
        computed:{
            ...mapState({
                teams: state => state.User.groups
            })
        },
    }
</script>

<i18n>
{
  "de": {
    "i18n": {
        "msg_not_a_member": "Du gehörst keinem Team an. Bitte deinen Admin dich einem Team hinzuzufügen!"
    }
  }
}
</i18n>
