import { config } from './keycloak/config'

class UserGroup {
    constructor(public name: string, public keycloakName: string, public isDefault: boolean = false) { }
}

const UserGroups: UserGroup[] = [
    new UserGroup('projectmanager', '/Projectmanager'),
    new UserGroup('designer', '/Designer'),
    new UserGroup('default-group', '', true),
];

if (config.adminAccessRole) UserGroups.push(new UserGroup(config.adminAccessRole, ''))

export { UserGroups, UserGroup }
