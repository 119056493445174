
import Vue from "vue";
import {Project} from "@/entity/Project";
export default Vue.extend({
  name: "ProjectSingleLine",
  props: {
    project: {type: Object as () => Project}
  },
  computed: {
   createdAtFormatted(): string|void {
      if(this.project?.created_at){
        return this.$moment(this.project.created_at).format('DD.MM.YYYY');
      } else {
        return '';
      }
    },
    updatedAtFormatted(): string|void {
      if(this.project?.updated_at){
        return this.$moment(this.project.updated_at).format('DD.MM.YYYY');
      } else {
        return '';
      }
    },
  },
  methods: {
    detailView(): void {
      this.$router.push({name: 'ProjectDetails', params: {'id': this.project.id}})
    },
  }
});
