
import Vue from 'vue';
import Loading from "@/components/shared/Loading.vue";
import AddBriefingButton from "@/components/briefing/AddBriefingButton.vue";
import SearchAndPagination from "@/components/shared/SearchAndPagination.vue";
import ProjectSingleLine from "@/components/project/ProjectSingleLine.vue";

export default Vue.extend({
  name: "ProjectList",
  components: {
    Loading,
    AddBriefingButton,
    SearchAndPagination,
    ProjectSingleLine
  },
  data() {
    return {
      resultLoading: true
    }
  },
  computed: {
    projects() {
      return this.$store.state['Project'].items
    }
  },
  methods: {
    setLoading(value) {
      this.resultLoading = value;
    }
  }
})
