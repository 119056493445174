<script>
export default {
  name: 'CalculateDelay',
  methods: {
    calculatedDelay(index) {
      let timer = '';
      if (index <= 8) {
        timer = index / 10 + 's';
      } else {
        timer = '0s';
      }
      return 'transition-delay: ' + timer;
    },
  }
}
</script>
