
import {EventBus} from "../../event";

import {BriefingArticle} from "@/entity/BriefingArticle";
import Vue from "vue";
import Jira from "@/mixins/Jira.vue";

export default Vue.extend({
  name: "BriefingLowpolySingle",
  mixins: [Jira],
  props: {
    briefing: {
      type: Object as () => BriefingArticle,
      //default: () => {}}
    },
  },
  data() {
    return {
      showButton: true
    }
  },
  computed: {
    canCreateBriefingLowpoly(): boolean {
      return (this.briefing.ordered && this.briefing.briefingLowPoly === null && this.showButton);
    }
  },
  beforeDestroy(): void {
    EventBus.$off('briefingLowpolyCreated');
  },
  methods: {
    openLowpolyModal(): void {
      this.$emit('openLowpolyModal');
    }
  }
})
