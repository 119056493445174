<script>
import {EventBus} from "@/event";

export default {
  name: 'SoloBriefingUpdateForm',
  methods: {
    async update() {
      EventBus.$emit('loadingstatus', 'show');
      try {
        let response = await this.$store.dispatch(this.briefingType + '/UPDATE', this.form);
        EventBus.$emit('loadingstatus', 'completed');
        return response;
      } catch (err) {
        EventBus.$emit('loadingstatus', 'failed');
        return err;
      }

    },
  }
}
</script>
