<template>
  <div>
    <loading v-if="resultLoading" />

    <transition name="fadein">
      <briefing-rendering-details-view
        v-if="!briefingRendering.ordered && !resultLoading"
        :id="id"
        :briefing-rendering="briefingRendering"
      />
      <briefing-solo-details-print
        v-else-if="!resultLoading"
        :id="id"
        :briefing="briefingRendering"
        link-back-name="BriefingRenderingList"
      />
    </transition>
    <transition name="fadein">
      <publish-briefing-solo-bar
        v-if="!resultLoading && !briefingRendering.hasContentPiece"
        :briefing-id="briefingRendering.id"
        :ordered="briefingRendering.ordered"
        :briefing-type="briefingRendering.briefingType"
        briefing-result-name="Content Image Rendering(s)"
      />
    </transition>
  </div>
</template>

<script>
import BootstrapFormValidation from '@/mixins/BootstrapFormValidation';
import PublishBriefingSoloBar from '@/components/shared/PublishBriefingSoloBar';
import Loading from '@/components/shared/Loading';
import BriefingRenderingDetailsView
  from '@/views/briefing/briefing-rendering/BriefingRenderingDetailsView'
import BriefingSoloDetailsPrint from '@/views/briefing/BriefingSoloDetailsPrint';

export default {
  name: "BriefingRenderingDetails",
  components: {
    Loading,
    BriefingRenderingDetailsView,
    PublishBriefingSoloBar,
    BriefingSoloDetailsPrint
  },
  mixins: [BootstrapFormValidation],
  props: {
    contentPieceID: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      resultLoading: true,
    }
  },
  computed: {
    briefingRendering() {
      return this.$store.state.BriefingRendering.selected;
    }
  },
  async beforeMount() {
    await this.$store.dispatch('BriefingRendering/SET_IS_EXPORTABLE', true);
    await this.$store.dispatch('BriefingRendering/GET_BY_ID', this.id);
    this.resultLoading = false;
  },

}
</script>

<style lang="scss">
@import '../../../../node_modules/bootstrap/scss/mixins';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '@/assets/scss/_variables';
</style>



