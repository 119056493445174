import { ConfigType, KeycloakVueProvider } from "./keycloak-vue.types"
import { UserReader } from "./user/user-reader"
import { TestUserHandler } from "./user/test-user-handler"

export const keycloakMockDefaultUser = {
    testUser: {},
    subject: "userId1234",
    token: "",
    tokenParsed: {},
    refreshToken: "",
    timeSkew: 5,
    resourceAccess: {},
    init() {
        return true;
    },
    loadUserProfile() {
        return this.testUser;
    },
    loadUserInfo() { },
    updateToken() { },
    setTestUser(config: ConfigType) {
        const testUserHandler = new TestUserHandler(new UserReader(), config)
        testUserHandler.readUser()
        this.testUser = testUserHandler.getTestUser()

        this.resourceAccess = {
            [config.clientId]: {
                roles: testUserHandler.getRoles()
            }
        }
    },
    login(options?: any) { },
    logout(options?: any) { }

} as KeycloakVueProvider


