<template>
  <div class="container">
    <div>
      <h2>Wartungsmodus</h2>
      <p>
        Diese Seite befindet sich zur Zeit in Wartung.<br>
        Wir bemühen uns, die Wartung so schnell wie möglich abzuschließen.
      </p>
      <p>Bitte schauen Sie später noch einmal vorbei.</p>

      <hr>
      <h2>Maintenance mode</h2>

      <p>
        This page is currently under maintenance.<br>
        We strive to complete the maintenance as soon as possible.
      </p>

      <p>Please try again later.</p>
    </div>
  </div>
</template>
<script>


export default {
  name: "Maintenance",
}
</script>
