import { UserInfoType } from '../keycloak-vue.types';
import * as users from './test-user.json'

export interface DataReader {
    read(): void
}

export class UserReader implements DataReader {
    users: { [key: string]: UserInfoType } = {};

    read(): void {
        this.users = users.users;
    }

    fetchSelectedUser(selectedUserKey: string): UserInfoType {
        this.read();
        return this.users[selectedUserKey] as UserInfoType;
    }
}