<template>
  <div>
    <loading v-if="resultLoading" />
    <transition name="fadein">
      <div v-if="!resultLoading">
        <div v-if="briefingShowFlat.send==0">
          <div class="container">
            <div class="row">
              <div
                id="bsf-details"
                class="container"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="mb-4">
                      <h1 class="mb-4">
                        {{ $t('i18n.headline_briefing') }} Szene
                        {{ briefingShowFlat.scene }}
                      </h1>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="mb-4">
                      <div class="editable">
                        <datepicker
                          :errors="errors"
                          :date="form.deadline"
                          :settings="datepickerSettings"
                          @updateEvent="deadlineUpdated"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <favorite-selector @favoriteSelected="updateFavorite" />
                  <favorite-selected-image :favorite="form.favorite" />
                </div>


                <div
                  v-if="getErrorMsg('show_flat_id')"
                  class="alert alert-danger"
                >
                  {{ $t('i18n.error_show_flat') }}
                </div>


                <div class="col-12">
                  <h2>{{ $t('i18n.cameras_variants_headline') }}</h2>
                  <p class="mb-5">
                    {{ $t('i18n.cameras_variants_desc') }}
                  </p>
                </div>


                <div class="col-12 mt-5 mb-5">
                  <cameras-and-variants
                    v-if="form.favorite"
                    :cameras-and-variants="form.camera"
                    :disabled-camera="true"
                    :standard-camera-name="form.favorite.baseName"
                    @updateCamerasAndVariants="updateCamerasAndVariants"
                  />
                  <div
                    v-else
                    class="alert alert-danger"
                  >
                    {{ $t('i18n.choose_reference_image') }}
                  </div>
                </div>


                <div class="row mb-7">
                  <div class="col-12 col-md-4">
                    <products-bundle
                      :product-bundle="briefingShowFlat.product_bundle"
                      :parent-i-d="id"
                      model-to-sync-with="BriefingShowFlat"
                      :settings="productBundleSettings"
                    />
                  </div>

                  <div class="col-12 col-md-4 references">
                    <items-list
                      :items-prop="briefingShowFlat.references"
                      :settings="referenceSettings"
                    />
                  </div>

                  <div class="col-12 col-md-4">
                    <h3>{{ $t('i18n.headline_references_upload') }}</h3>
                    <span class="text-muted small"><p>{{ $t('i18n.helper_references_upload') }}</p></span>
                    <upload
                      id="upload-briefing-rendering"
                      upload-path="briefing-show-flat"
                      :already-uploaded-files="briefingShowFlat.uploads"
                      :uploadable-id="id"
                    />
                  </div>

                  <div class="col-12 mt-5">
                    <label>{{ $t('i18n.label_description') }}</label>
                  </div>
                  <div class="col-12 mb-5">
                    <div class="editable mb-4 h-100">
                      <editor
                        :initial-content="form.description"
                        :placeholder="$t('i18n.placeholder_description')"
                        :active-buttons="['bold','strike','underline', 'bullet_list', 'ordered_list']"
                        @blur="editorUpdated"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from '@/axios';
import {EventBus} from '@/event';
import Editor from '@/components/shared/Editor.vue';
import FavoriteSelector from '@/components/contentpieces/briefings/FavoriteSelector.vue';
import FavoriteSelectedImage from '@/components/contentpieces/briefings/FavoriteSelectedImage.vue';
import ItemsList from '@/components/shared/ItemsList.vue';
import ProductsBundle from '@/components/shared/ProductsBundle';
import CamerasAndVariants from '@/components/shared/CamerasAndVariants';
import BootstrapFormValidation from '@/mixins/BootstrapFormValidation';
import Datepicker from '@/components/shared/Datepicker';
import Upload from '@/components/shared/Upload';

import Loading from '@/components/shared/Loading';

export default {
  name: "BriefingShowFlat",
  components: {
    Editor,
    ItemsList,
    ProductsBundle,
    CamerasAndVariants,
    Loading,
    Datepicker,
    Upload,
    FavoriteSelector,
    FavoriteSelectedImage
  },
  mixins: [BootstrapFormValidation],
  props: {
    contentPieceID: String,
    id: String
  },
  data() {
    return {
      newBundle: false,
      items: [],
      errors: {},
      form: {},
      referenceInput: '',
      resultLoading: true,
      briefingShowFlat: Object,
      showFlatLoading: true,
      referenceSettings: {
        'headlinePlaceholder': this.$t('i18n.headline_references'),
        'description': '<p>' + this.$t('i18n.helper_references') + '</p>',
        'itemName': this.$t('i18n.placeholder_references'),
        'validationClass': 'reference',
        'eventName': 'referencesChanged',
        'validationRules': 'required'
      },
      productBundleSettings: {
        'headlinePlaceholder': this.$t('i18n.headline_additonal_articles'),
        'description': '<p>' + this.$t('i18n.helper_additonal_articles') + '</p>',
        'productBundleType': '0',
        'canBePublished': false
      }
    }
  },
  computed: {
    datepickerSettings() {
      let values = {
        'validationClass': 'deadline',
        'text_label': this.$t('i18n.label_deadline'),
        'text_placeholder': this.$t('i18n.placeholder_deadline', [this.form.content_piece.deadlineFormated]),
        'text_helper': this.$t('i18n.helper_deadline')
      }
      return values;
    },
  },
  watch: {
    id() {
      this.getBriefingShowFlat()
    },
    briefingShowFlat() {
      if (this.briefingShowFlat != null) {
        this.form = JSON.parse(JSON.stringify(this.briefingShowFlat));
      }
    },
  },
  created() {
    this.loadData();
    this.$on('camerasChanged', (cameras) => this.updateCameras(cameras));
    this.$on('referencesChanged', (references) => this.updateReferences(references));
  },
  methods: {
    updateFavorite(favorite) {
      this.$set(this.form, 'favorite', favorite)
      this.updateBriefingShowFlat()
    },
    addReference() {
      if (this.form.references == null) {
        this.form.references = [];
      }
      this.form.references.push(this.referenceInput);
      this.referenceInput = '';
      this.updateBriefingShowFlat();
    },
    deleteReference(index) {
      this.form.references.splice(index, 1);
      //WIP should be removed after successful axios call
    },
    isActiveShowFlat(id) {
      if (this.form.show_flat_id == id) {
        return 'active'
      }
      return
    },
    getBriefingShowFlat() {
      return new Promise(resolve => {
        axios.get('/briefing-show-flat/' + this.id).then((result) => {
          resolve(this.briefingShowFlat = result.data)
          EventBus.$emit('autosave', {'status': true, 'datetime': ''});
        })
      });
    },

    async updateBriefingShowFlat() {
      let params = {
        ...this.form
      };
      EventBus.$emit('loadingstatus', 'show');
      try {
        await axios.put('/briefing-show-flat/' + this.id, params);
        EventBus.$emit('loadingstatus', 'completed');
        this.errors = {};
      } catch (error) {
        if (error.response.data.hasOwnProperty('errors')) {
          this.errors = error.response.data.errors;
        }
        EventBus.$emit('loadingstatus', 'failed');
      }
    },
    async loadData() {
      await this.getBriefingShowFlat()
      this.resultLoading = false;
    },
    editorUpdated(val) {
      this.form.description = val;
      this.updateBriefingShowFlat();
    },
    deadlineUpdated(val) {
      this.errors = {};
      this.form.deadline = val;
      this.updateBriefingShowFlat();
    },
    updateReferences(references) {
      this.form.references = references;
      this.updateBriefingShowFlat();
    },
    updateCamerasAndVariants(value) {
      this.form.camera = value;
      this.updateBriefingShowFlat();
    },

  }
}
</script>

<style lang="scss">
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '@/assets/scss/_variables';

.showflatgallery {
  img {
    border: 4px solid transparent;
    cursor: pointer;

    &.active {
      border-color: $primary;
    }
  }
}
</style>


<i18n>
{
  "de": {
    "i18n": {
      "headline_briefing": "3D Referenzbild Briefing",
      "label_name": "UseCase/Form",
      "helper_name": " ",
      "label_show_flat": "Wähle ein Bild als Grundlage",
      "error_show_flat": "Es muss ein Referenzbild gewählt werden",
      "choose_reference_image": "Wähle ein ein Referenzbild für die Kamera, um die Szenen festlegen zu können.",
      "label_deadline": "Deadline",
      "placeholder_deadline": "{0} (übernommen vom Content Piece)",
      "helper_deadline": "Die Deadline muss von einem Projekt-Manager bestätigt werden",
      "label_description": "Zusätzliche Informationen",
      "placeholder_description": "Gibt es sonst noch etwas Wichtiges zu diesem Briefing?",
      "headline_references": "Referenzen (Links / Text)",
      "placeholder_references": "Referenz",
      "helper_references": "Welche Referenzen helfen uns dabei das bestmögliche Ergebnis für das Rendering zu erreichen?",
      "headline_additonal_articles": "Zusätzliche Artikel in der Szene",
      "helper_additonal_articles": "Welche Artikel neben den Materialien aus dem Content Piece sollen zu sehen sein?",
      "placeholder_additonal_articles": "- Keine Artikel -",
      "headline_references_upload": "Referenzen (Bilder / PDF)",
      "helper_references_upload": "Lade hier Bilder oder Dokumente hoch die uns helfen diese Szene zu entwickeln",
      "cameras_variants_headline": "Beschreibe die Anpassungen, die du an dem Referenzbild machen möchtest",
      "cameras_variants_desc": "Du kannst mehrere Varianten von deinem ausgewählten Referenzbild erstellen."
    }
  }
}
</i18n>
