<template>
  <div>
    <label>{{ settings.text_label }}</label>
    <date-picker
      v-model="formattedDate"
      class="d-block"
      :input-class="getValidationClass(settings.validationClass)+' mx-input form-control'"
      :editable="false"
      :type="settings.type"
      :placeholder="settings.text_placeholder"
      :format="dateFormat"
      @change="deadlineUpdated"
    />
    <div
      v-if="getErrorMsg(settings.validationClass)"
      class="text-danger small"
      v-html="getErrorMsg(settings.validationClass)"
    />
    <div class="form-text text-muted small">
      {{ settings.text_helper }}
    </div>
  </div>
</template>


<script>
import BootstrapFormValidation from '@/mixins/BootstrapFormValidation';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/de';

export default {
  name: "CustomDatepicker",
  components: {
    DatePicker
  },
  mixins: [BootstrapFormValidation],
  props: {
    errors: Object,
    date: String,
    settings: Object
  },
  data() {
    return {
      value: null,
    }
  },
  watch: {
    date() {
      this.value = this.date
    }
  },
  computed: {
    formattedDate: {
      get() {
        if (this.value == null) return null

        const val = new Date(this.value)
        const offset = val.getTimezoneOffset();
        return new Date(val.getTime() + offset * 60 * 1000);
      },
      set(val) {
        if (val == null) {
          this.value = null;
          return;
        }
        const offset = val.getTimezoneOffset();
        this.value = new Date(val.getTime() - offset * 60 * 1000)
      }
    },
    dateFormat() {
      return 'DD.MM.YYYY'
    },
  },
  created() {
    this.value = this.date
  },
  methods: {
    deadlineUpdated() {
      let formattedToIsoString = null;

      if (this.value != null) {
        formattedToIsoString = this.value.toISOString()
      }

      this.$emit('updateEvent', formattedToIsoString)
    }
  }
}
</script>

<style lang="scss">

.mx-datepicker {
  width: 100%;

  .mx-input {
    border-radius: 0px;

    &.is-invalid {
      background-image: none;
    }
  }
}

.mx-datepicker-popup {
  z-index: 1051;
}
</style>
