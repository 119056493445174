import { UserGroup } from "../user-groups"
import { UserVuex, VuexStoreType } from "./keycloak-vue.types"
import { StoreRepository } from "./store-repository"

class KeycloakVuex implements StoreRepository {

    constructor(protected store: VuexStoreType) { }

    deleteUserFromStore(): void {
        this.store.commit('User/DELETE_USER');
        this.store.commit('User/SET_REFRESH_TOKEN', null);
        this.store.commit('User/SET_ACCESS_TOKEN', null);
    }

    setUserInfo(userInfo: UserVuex): void {
        this.store.commit('User/SET_USER', userInfo);
    }

    setToken(accessToken: string, refreshToken: string): void {
        this.store.commit('User/SET_ACCESS_TOKEN', accessToken);
        this.store.commit('User/SET_REFRESH_TOKEN', refreshToken);
    }

    addUserGroup(userGroup: UserGroup): void {
        this.store.commit('User/ADD_GROUP', userGroup);
    }

    setKeycloakError(message: string): void {
        this.store.commit('SET_KEYCLOAK_ERROR', message);
    }
}

export { KeycloakVuex };
