<template>
  <div class="h-100">
    <router-link class="back-arrow" :to="linkBack">
      <font-awesome-icon :icon="['fas', 'chevron-left']" />
    </router-link>

    <component :is="briefingType" :id="briefingId" :content-piece-i-d="contentPieceId" />
  </div>
</template>
<script>
import BriefingCustomerInstruction from '@/components/contentpieces/briefings/BriefingCustomerInstruction.vue';
import BriefingVideo from '@/components/contentpieces/briefings/BriefingVideo.vue';
import BriefingText from '@/components/contentpieces/briefings/BriefingText.vue';
import BriefingStockImage from '@/components/contentpieces/briefings/BriefingStockImage.vue';
import BriefingShowFlat from '@/components/contentpieces/briefings/BriefingShowFlat.vue';
import BriefingArticle from '@/components/briefing/details/BriefingArticleDetails.vue';
import BriefingRendering from '@/components/briefing/details/BriefingRenderingDetails.vue';
import { briefingTypeToURL } from '@/composables/BriefingTypeToURL';

export default {
  name: 'BriefingDetails',
  components: {
    BriefingRendering,
    BriefingCustomerInstruction,
    BriefingVideo,
    BriefingArticle,
    BriefingText,
    BriefingStockImage,
    BriefingShowFlat
  },
  data() {
    return {
      briefingId: this.$route.params.briefingid,
      contentPieceId: this.$route.params.cpid,
      briefingType: this.$route.params.briefingType
    };
  },
  computed: {
    linkBack() {
      let route;

      switch (this.$route.name) {
        case 'ContentPieceBriefingDetails':
          route = { name: 'ContentPieceDetails', params: { cpid: this.contentPieceId } };
          break;
        case 'BriefingDetails':
          route = { path: briefingTypeToURL(this.briefingType) };
          break;
      }

      return route;
    }
  }
};
</script>
