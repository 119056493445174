
import Vue from 'vue';
import { Project, UpdateProject } from '@/entity/Project';
import { BatchBriefingArticle, UpdateBatchBriefingArticle } from '@/entity/BatchBriefingArticle';
import BootstrapFormValidation from '@/mixins/BootstrapFormValidation.vue';
import { EventBus } from '@/event';
import axios from '@/axios';
import Datepicker from '@/components/shared/Datepicker.vue';
import ConfirmModal from '@/components/project/ConfirmModal.vue';
import Editor from '@/components/shared/Editor.vue';
import Upload from '@/components/shared/Upload.vue';
import Uploads from '@/components/shared/Uploads.vue';
import Products from '@/components/shared/Products.vue';
import { replaceSpecialChars } from '@/composables/Typo';
import { RoomType, ROOM_TYPES } from '@/enum/room.enum.ts';
export default Vue.extend({
  name: 'BatchBriefingForm',
  components: {
    Datepicker,
    Editor,
    Upload,
    Uploads,
    ConfirmModal,
    Products
  },
  mixins: [BootstrapFormValidation],
  props: {
    index: { type: Number, default: 0 },
    id: { type: String, default: '' }
  },
  data() {
    return {
      collapsedView: true,
      form: {} as UpdateBatchBriefingArticle,
      errors: {},
      resultLoading: true,
      deadlineSettings: {
        validationClass: 'deadline',
        text_label: this.$t('label_deadline'),
        text_placeholder: this.$t('placeholder_deadline'),
        text_helper: this.$t('helper_deadline')
      },
      articlesSettings: {
        headlinePlaceholder: this.$t('headline_articles'),
        description: '<p>' + this.$t('helper_articles') + '</p>',
        productBundleType: '0',
        canBePublished: false
      },
      confirmModalOpen: false,
      roomTypes: [...ROOM_TYPES]
    };
  },
  computed: {
    cleanName(): string | null {
      return this.form.name;
    },
    batchBriefingsCount(): number | null {
      return this.$store.state['Project'].selected.batchSortOrder.length;
    },
    briefing(): BatchBriefingArticle {
      const index = this.$store.state['Project'].selected.batchBriefingArticles.findIndex(
        batchBriefingArticle => this.id == batchBriefingArticle.id
      );
      return this.$store.state['Project'].selected.batchBriefingArticles[index];
    },
    ModelsCount(): Number {
      return this.briefing.productUniques.length;
    },
    LowPolyModelsCount(): Number {
      if (this.briefing.createLowPoly) {
        return this.briefing.productUniques.length;
      } else {
        return 0;
      }
    }
  },
  watch: {
    cleanName: function(): void {
      this.form.name = replaceSpecialChars(this.form.name);
    }
  },
  async created(): Promise<void> {
    this.initForm();
    EventBus.$on('setCollapsedView', this.setCollapsedView);
  },
  destroyed() {
    EventBus.$off('setCollapsedView');
  },
  methods: {
    initForm() {
      this.form = {
        name: this.briefing.name,
        description: this.briefing.description,
        deadline: this.briefing.deadline,
        createLowPoly: this.briefing.createLowPoly,
        lastModifierUser: this.$store.getters['User/userBackend'],
        room: this.briefing.room
      };
    },
    setCollapsedView(val): void {
      if (this.briefing.id === val.id) {
        this.collapsedView = val.value;
      }
    },
    updateDescription(desc: string): void {
      this.form.description = desc;
      this.update();
    },
    deadlineUpdated(dateString: string): void {
      this.errors = {};
      this.form.deadline = dateString;
      this.update();
    },
    updateCreateLowPoly() {
      this.form.createLowPoly = !this.form.createLowPoly;
      this.update();
    },
    async update(): Promise<void> {
      EventBus.$emit('loadingstatus', 'show');
      this.errors = {};
      try {
        await axios.put<Project>(`/batch-briefing-article/${this.briefing.id}`, this.form);
        await this.updateBatchBriefing();
        EventBus.$emit('loadingstatus', 'completed');
      } catch (error) {
        this.errors = error.response.data.errors;
        EventBus.$emit('loadingstatus', 'failed');
      }
    },
    closeConfirmModal(): void {
      this.confirmModalOpen = false;
    },
    async updateBatchBriefing(): Promise<void> {
      await this.$store.dispatch('BatchBriefingArticle/GET_BY_ID', this.briefing.id);
    },
    async deleteBriefing(): Promise<void> {
      const confirmDelete: boolean = confirm('Soll dieser Block wirklich gelöscht werden?');

      if (confirmDelete) {
        EventBus.$emit('loadingstatus', 'show');
        await this.$store.dispatch('BatchBriefingArticle/DELETE', this.briefing.id);
        EventBus.$emit('loadingstatus', 'completed');
      }
    },
    async moveOrderUp(): Promise<void> {
      await this.$store.dispatch('Project/BATCH_MOVE_UP', this.briefing.id);
    },
    async moveOrderDown(): Promise<void> {
      await this.$store.dispatch('Project/BATCH_MOVE_DOWN', this.briefing.id);
    }
  }
});
