<script>
export default {
  name: 'Image',
  methods: {
    productImageURL(articleNumber) {
     return '//images.obi.info/file_db/services/serve_product.php?firma=6&article_nr=' + articleNumber;
    },
    fallbackPlaceholder(e) {
      e.target.src = require("@/assets/images/fallback.jpg");
    }
  }
}
</script>
