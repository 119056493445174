
import Datepicker from "@/components/shared/Datepicker.vue";
import Editor from "@/components/shared/Editor.vue";
import BootstrapFormValidation from "@/mixins/BootstrapFormValidation.vue";
import {Project, UpdateProject} from "@/entity/Project";
import axios from "@/axios";
import {EventBus} from "@/event";
import Vue from "vue";
import {replaceSpecialChars} from "@/composables/Typo";

export default Vue.extend({
  name: "ProjectDetailsMain",
  components: {
    Datepicker,
    Editor
  },
  mixins: [BootstrapFormValidation],
  props: {
    project: {type: Object as () => Project}
  },
  data() {
    return {
      collapsedView: true,
      form: {} as UpdateProject,
      errors: {},
      resultLoading: true,
      deadlineSettings: {
        'validationClass': 'deadline',
        'text_label': this.$t('label_deadline'),
        'text_placeholder': this.$t('placeholder_deadline'),
        'text_helper': this.$t('helper_deadline')
      },
    }
  },
  computed:{
    cleanName(): string | null {
      return this.form.name;
    }
  },
  watch: {
    cleanName: function (): void {
      this.form.name = replaceSpecialChars(this.form.name);
    }
  },
  async created(): Promise<void> {
    this.initForm();
  },
  methods: {
    initForm() {
      this.form = {
        name: this.project.name,
        description: this.project.description,
        deadline: this.project.deadline,
        lastModifierUser: this.$store.getters["User/userBackend"]
      };
    },
    updateDescription(desc: string): void {
      this.form.description = desc
      this.update();
    },
    deadlineUpdated(dateString: string): void {
      this.errors = {};
      this.form.deadline = dateString;
      this.update();
    },
    async update(): Promise<void> {
      EventBus.$emit('loadingstatus', 'show');
      this.errors = {}
      try {
        const {data} = await axios.put<Project>(`/project/${this.project.id}`, this.form)
        EventBus.$emit('loadingstatus', 'completed');
      } catch (e) {
        this.errors = e
        EventBus.$emit('loadingstatus', 'failed');
      }
    }
  }
});
