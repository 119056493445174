<template>
  <b-modal
    id="new-briefing-lowpoly"
    ref="new-briefing-modal"
    no-close-on-backdrop
    hide-footer
    centered
    :title="$t('i18n.headline_create_briefing')"
  >
    <briefing-lowpoly-form
      :briefing-article-id="briefingArticleId"
      @hideModal="hideModal()"
    />
  </b-modal>
</template>
<script>


import BriefingLowpolyForm from '../briefing/forms/BriefingLowpolyForm';

export default {
  name: "NewBriefingLowpolyModal",
  components: {
    BriefingLowpolyForm
  },
  props: {
    briefingArticleId: String,
  },
  methods: {
    hideModal() {
      this.$refs['new-briefing-modal'].hide()
    }
  }
}
</script>

<i18n>
{
  "de": {
    "i18n": {
      "headline_create_briefing": "Neues Lowpoly Briefing erstellen",
      "headline_type": "Briefing Typ"
    }
  }
}
</i18n>
