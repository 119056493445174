
import {BaseEntity} from "@/entity/BaseEntity";

export default {
  name: 'Jira',
  methods: {
    jiraURL(briefing : BaseEntity) : string {
      const jiraKey = briefing?.jira_export?.response?.key
      if(jiraKey){
        return `https://jira.obi.net/browse/${jiraKey}`
      }
      return ""
    },
    jiraStatus(briefing : BaseEntity) : string {
      return (briefing?.jira_export?.status) ? ' - ' + briefing.jira_export.status.name : ''
    }
  }
}
