<template>
  <div class="row">
    <div class="col-12">
      <div class="card w-100">
        <div class="card-header">
          <strong>{{ headline }}</strong>
        </div>

        <div class="card-body">
          <div class="row mb-3">
            <div class="col-4">
              Bild
            </div>
            <div class="col-4">
              Nummer
            </div>
            <div class="col-4">
              Menge
            </div>
          </div>
          <div
            v-for="(product,index) in productBundle.products"
            :key="index"
            class="row"
          >
            <div class="col-4">
              <img
                :src="product.sdb_product.imagelink"
                class="border img-fluid mb-2"
                width="150"
                alt=""
                @error="fallbackPlaceholder"
              >
            </div>
            <div class="col-4">
              {{ product.sdb_product.nationalArticleIdInclCheckDigit }}
            </div>
            <div class="col-4">
              {{ product.qty }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
  import Image from "@/mixins/Image";
  export default {
      name: "PrintProductBundle",
      mixins: [Image],
      props: {
        headline: {
          type: String,
          default: "Artikelliste"
        },
      productBundle: Object
      }
    }
  </script>

