<script>
export default {
  name: 'SanitizeInput',
  methods: {
    sanitize(input) {
      return input.replace(/\p{Extended_Pictographic}/gu, '')
    }
  }
}
</script>
