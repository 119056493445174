
import Vue from "vue";
import {mapState} from "vuex";
import {bool} from "joi";

export default Vue.extend({
  name: "SearchAndPagination",
  props: {
    vuexModuleName: {type: String, default: ''},
  },
  data() {
    return {
      resultLoading: true,
      currentPage: 1,
      perPage: 25,
      searchQry: null,
      showNonProjectBriefings: false
    }
  },
  computed: {
    pagination(): Object {
      return this.$store.state[this.vuexModuleName].pagination
    },
    showNonProjectBriefingsFilter(): boolean {
      return this.vuexModuleName === 'BriefingArticle';
    },
    paginationLinksLimit(): number {
      if (this.showNonProjectBriefingsFilter) return 6
      return 10
    }
  },
  watch: {
    currentPage: function (): void {
      this.loadBriefings();
    },
    perPage: function (): void {
      this.currentPage = 1;
      this.loadBriefings();
    },
  },
  async created() {
    await this.loadBriefings();
  },
  methods: {
    async loadBriefings(): Promise<void> {
      this.$emit('loading', true);
      this.$store.commit(this.vuexModuleName + '/SET_FILTER', this.getFilterParams())
      await this.$store.dispatch(this.vuexModuleName + '/FETCH')
      this.setCurrentPage();
      this.$emit('loading', false);
    },
    setCurrentPage(): void {
      this.currentPage = this.$store.state[this.vuexModuleName].pagination.current_page;
    },
    getFilterParams(): Object {
      let filterParams = {
        page: this.currentPage,
        perPage: this.perPage,
        searchQry: this.searchQry,
      }
      if (this.showNonProjectBriefingsFilter) {
        filterParams['showNonProjectBriefings'] = this.showNonProjectBriefings;
      }
      return filterParams;
    },
    clearSearch() {
      this.searchQry = null;
      this.loadBriefings();
    },
    search() {
      this.currentPage = 1;
      this.loadBriefings();
    }
  }
})
