
import Vue from 'vue';
import axios from '@/axios';
import BootstrapFormValidation from '@/mixins/BootstrapFormValidation.vue';
import Datepicker from '@/components/shared/Datepicker.vue';
import {EventBus} from '@/event';
import {CreateProject} from '@/entity/Project';
import TeamSelect from "@/components/project/forms/TeamSelect.vue";
import Editor from '@/components/shared/Editor.vue';
import {replaceSpecialChars} from "@/composables/Typo";

export default Vue.extend({
  name: "ProjectForm",
  components: {
    TeamSelect,
    Datepicker,
    Editor,
  },
  mixins: [BootstrapFormValidation],
  data() {
    return {
      form: {} as CreateProject,
      datepickerSettings: {
        'validationClass': 'deadline',
        'text_label': this.$t('label_deadline'),
        'text_placeholder': this.$t('placeholder_deadline')
      },
      errors: {},
      exceptionMessage: ""
    }
  },
  computed:{
    cleanName(): string {
      return this.form.name;
    }
  },
  watch: {
    cleanName: function (): void {
      this.form.name = replaceSpecialChars(this.form.name);
    }
  },
  created(): void {
    this.initForm()
  },
  methods: {
    updateTeam(team: string): void {
      this.form.team = team;
    },
    updateDescription(desc: string): void {
      this.form.description = desc
    },
    initForm(): void {
      const user = this.$store.getters["User/userBackend"]
      this.form = {
        name: '',
        description: '',
        deadline: null,
        team: '',
        user: user,
        lastModifierUser: user
      }
    },
    deadlineUpdated(dateString: string): void {
      this.errors = {};
      this.form.deadline = dateString;
    },
    async save(): Promise<void> {
      EventBus.$emit('loadingstatus', 'show');
      try {
        let result = await axios.post('/project', this.form);
        this.$emit('hideModal');
        this.initForm()
        EventBus.$emit('loadingstatus', 'completed');

        await this.$router.push({
          name: 'ProjectDetails',
          params: {
            briefingType: 'project',
            id: result.data.id
          }
        });
      } catch (error) {
        if(("exception" in error.response.data)){
          this.exceptionMessage = error.response.data.message;
        } else {
          this.errors = error.response.data.errors;
        }
        EventBus.$emit('loadingstatus', 'failed');
      }
    },
  }
})
