<template>
  <div>
    <div
      :id="briefing.id"
      class="row shadow bg-white mb-4 cp mx-1 mx-sm-0"
    >
      <div
        class="col-12 col-sm-3 bg-green text-white p-2 cp-info small"
        :class="[briefing.ordered ? 'bg-success':'bg-primary']"
      >
        <span class="d-inline d-sm-block mb-2 small font-weight-bold">{{
          briefing.created_at | moment("DD.MM.Y")
        }}</span>
        <span class="d-inline d-sm-block text-truncate w-100">{{ briefing.user.name }}</span>
        <span class="d-inline d-sm-block text-truncate w-100"><i>{{ briefing.team }}</i></span>


        <div v-if="briefing.briefingType==='BriefingArticle' && briefing.projectInfo !== null">
          <hr class="mt-3 mb-3">
          <router-link
            class="text-white link"
            :to="{ name: 'ProjectDetails', params: {id: briefing.projectInfo.projectId, blockid: briefing.projectInfo.batchBriefingArticleId}}"
          >
            <div class="text-truncate w-100">
              {{ briefing.projectInfo.projectName }}
            </div>
          </router-link>
        </div>


        <div
          v-if="briefing.ordered && briefing.jira_export"
          class="jira-export small p-2"
        >
          <a
            target="_blank"
            :href="jiraURL(briefing)"
            class="link text-white"
          >{{
            briefing.jira_export.response.key
          }}</a>{{
            (briefing.jira_export.status != null) ? ' - ' + briefing.jira_export.status.name : ''
          }}
        </div>
      </div>

      <div class="col-12 col-sm-2 text-center d-flex justify-content-center">
        <img
          v-if="briefing.product"
          class="img-fluid product-img align-self-center"
          :src="productImageURL(briefing.product.sdbProduct.articleNumber)"
          @error="fallbackPlaceholder"
        >
      </div>


      <div class="col-12 col-sm-7 py-3 description">
        <h4
          class="cursor-pointer d-inline"
          @click="detailView()"
        >
          <div v-if="briefing.listTitle">
            {{ briefing.listTitle }}
          </div>
          <div v-else>
            -
          </div>
        </h4>

        <div
          v-if="briefing.product"
          class="cp-desc mt-3"
        >
          {{ briefing.product.sdbProduct.nationalArticleIdInclCheckDigit }}
        </div>

        <briefing-lowpoly-single
          v-if="briefing.briefingType==='BriefingArticle'"
          :briefing="briefing"
          @openLowpolyModal="openLowpolyModal()"
        />

        <div
          class="cp-open"
          @click="detailView()"
        >
          <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </div>

        <div
          v-if="!briefing.ordered"
          class="cp-delete text-danger btn"
          @click="deleteBriefing()"
        >
          <font-awesome-icon :icon="['fas', 'trash']" />
        </div>

        <div
          v-if="briefing.briefingType==='BriefingRendering'"
          class="cp-clone btn"
          @click="cloneBriefing()"
        >
          <font-awesome-icon :icon="['fas', 'copy']" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {EventBus} from '@/event';
import Jira from "@/mixins/Jira";
import Image from "@/mixins/Image";
import BriefingLowpolySingle from "../shared/BriefingLowpolySingle.vue";
export default {
  name: "BriefingSingleLine",
  components: {
    BriefingLowpolySingle
  },
  mixins: [Jira, Image],
  props: {
    briefing: {type: Object, 'default': {}},
  },
  methods: {
    detailView() {
      return this.$router.push({path: this.briefing.detailViewURL});
    },
    async deleteBriefing() {
      const confirmDelete = confirm("Soll dieses Briefing wirklich gelöscht werden?");
      if (confirmDelete === true) {
        EventBus.$emit('loadingstatus', 'show');
        try {
          await this.$store.dispatch(this.briefing.briefingType + '/DELETE', this.briefing.id)
        } catch (err) {
          console.error(err)
        }
        EventBus.$emit('loadingstatus', 'completed');
      }
    },
    async cloneBriefing() {
      const confirmClone = confirm("Soll dieses Briefing dupliziert werden?");
      if (confirmClone === true) {
        EventBus.$emit('loadingstatus', 'show');

        let params = {
          id : this.briefing.id,
          user: this.$store.getters["User/userBackend"]
        };

        try {
          await this.$store.dispatch(this.briefing.briefingType + '/CLONE', params )
        } catch (err) {
          console.error(err)
        }
        EventBus.$emit('loadingstatus', 'completed');
      }
    },
    openLowpolyModal() {
      this.$emit('openLowpolyModal', this.briefing.id);
    }
  }
}
</script>

<style lang="scss" scoped>
.cp {
  position: relative;
  transition: box-shadow 500ms, margin 500ms, padding 500ms;

  // Hover animation only on larger screens
  @include media-breakpoint-up(sm) {
    &:hover {
      -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3) !important;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3) !important;
    }
  }


  .product-img {
    max-height: 150px;
  }

  .description {
    h4 {
      text-decoration: underline;
    }

    .cp-desc {
      word-break: break-word;
    }

    .cp-open {
      position: absolute;
      right: 10px;
      bottom: 10px;
      color: $third;
      cursor: pointer;
      font-size: 20px;
    }

    .cp-delete {
      position: absolute;
      color: $third;
      top: 5px;
      right: 0px;
      cursor: pointer;
    }

    .cp-clone {
      position: absolute;
      color: $third;
      top: 5px;
      right: 30px;
      cursor: pointer;
    }


  }





  .cp-info {

    @include media-breakpoint-up(sm) {
      min-height: 169px;
    }

    .id {
      color: lighten($primary, 20);
    }

    .jira-export {
      position: absolute;
      bottom: 0px;
      right: 0px;
      left:0px;
      background:rgba(255,255,255,0.2);
    }

  }

  .cp-menu {
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 5px;
    font-size: 1.3em;
    z-index: 2;

    .dropdown-item {
      svg {
        float: right;
        margin: 3px 0 0 0;
      }
    }
  }

  .badge {
    margin: 0 2px;
  }
}
</style>
