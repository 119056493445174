import { ConfigType } from "./keycloak-vue.types"
import Keycloak from "keycloak-js";

const getKeycloakInstance = (config: ConfigType): Keycloak.KeycloakInstance => {
    return Keycloak({
        url: config.url,
        realm: config.realm,
        clientId: config.clientId
    })
}

export { getKeycloakInstance }
