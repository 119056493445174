import axios from '@/axios';
import { ContentPiece } from '@/entity/ContentPiece';
import { ContentPiecesList } from '@/entity/Response';

export default {
	namespaced: true,
	state: {
		items: [],
		pagination: { current_page: null, total: null, per_page: null },
		selected: {},
		filter: {}
	},
	mutations: {
		SET_ITEMS(state, items) {
			state.items = items;
		},
		SET_PAGINATION(state, pagination) {
			state.pagination.current_page = pagination.current_page;
			state.pagination.total = pagination.total;
			state.pagination.per_page = pagination.per_page;
		},
		SET_SELECTED(state, selected) {
			state.selected = selected;
		},
		SET_FILTER(state, filter) {
			state.filter = filter;
		}
	},
	actions: {
		async FETCH({ commit, state }) {
			try {
				const page = state?.filter?.params == null ? 1 : state?.filter?.params;
				const response = await axios.post<ContentPiecesList>(
					`/content-pieces-filtered?page=${page}`,
					state.filter
				);
				commit('SET_ITEMS', response.data.data);
				commit('SET_PAGINATION', response.data.meta);
			} catch (e) {
				commit('SET_ITEMS', []);
			}
		},
		async GET_BY_ID({ commit }, id) {
			try {
				const { data } = await axios.get<ContentPiece>(`/content-pieces/${id}`);
				commit('SET_SELECTED', data);
			} catch (e) {
				commit('SET_SELECTED', []);
			}
		}
	}
};
