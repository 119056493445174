<template>
  <div>
    <router-link
      class="back-arrow"
      :to="{ name: 'ContentPiecesList' }"
    >
      <font-awesome-icon :icon="['fas', 'chevron-left']" />
    </router-link>

    <div class="container pb-8">
      <div class="row mb-4">
        <div class="col-12">
          <!--           <span
            class="btn btn-outline-default d-print-none float-right"
            @click="printPage"
          >Drucken</span> -->
          <h2>Zusammenfassung aktueller Stand</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card w-100">
            <div class="card-header">
              <strong>Contentpiece</strong>

              <span
                v-if="contentPiece.jira_export"
                class="float-right"
                ><a
                  target="_blank"
                  :href="'https://jira.obi.net/browse/' + contentPiece.jira_export.response.key"
                  class="link text-default"
                  >{{ contentPiece.jira_export.response.key }}</a
                >{{
                  contentPiece.jira_export.status != null
                    ? ' - ' + contentPiece.jira_export.status.name
                    : ''
                }}</span
              >
            </div>
            <div class="card-body py-2">
              <div class="row">
                <div class="col-6 even-odd-row">
                  <div class="row">
                    <div class="col-5">
                      <strong>Name</strong>
                    </div>
                    <div class="col-7">
                      {{ contentPiece.name }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-5">
                      <strong>J-ID</strong>
                    </div>
                    <div class="col-7">
                      {{ contentPiece.internal_id }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-5">
                      <strong>Topic</strong>
                    </div>
                    <div class="col-7">
                      {{ contentPiece.topic }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-5">
                      <strong>Team</strong>
                    </div>
                    <div class="col-7">
                      {{ contentPiece.team }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-5">
                      <strong>Raum</strong>
                    </div>
                    <div class="col-7">
                      {{ contentPiece.room }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-5">
                      <strong>Deadline</strong>
                    </div>
                    <div class="col-7">
                      {{ contentPiece.deadline | moment('DD.MM.Y') }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-5">
                      <strong>Ersteller</strong>
                    </div>
                    <div class="col-7">
                      {{ userName }}
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-5">
                      <strong>Datum der Erstellung:</strong>
                    </div>
                    <div class="col-7">
                      {{ contentPiece.created_at | moment('DD.MM.Y') }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="contentPiece.product_bundle"
                  class="col-3"
                >
                  <div class="row">
                    <div class="col-12 py-1">
                      <strong>Artikelliste</strong>
                    </div>
                  </div>
                  <table class="table table-striped">
                    <tr>
                      <th>SKU</th>
                      <th>Qty</th>
                    </tr>
                    <tr
                      v-for="(product, productIndex) in contentPiece.product_bundle.products"
                      :key="'product' + productIndex"
                    >
                      <td>{{ product | getIsoSku }}</td>
                      <td>{{ product.qty }}</td>
                    </tr>
                  </table>
                </div>
                <div
                  v-if="contentPiece.product_bundle_option"
                  class="col-3"
                >
                  <div class="row">
                    <div class="col-12 py-1">
                      <strong>Optionen</strong>
                    </div>
                  </div>
                  <table class="table table-striped">
                    <tr>
                      <th>SKU</th>
                      <th>Qty</th>
                    </tr>
                    <tr
                      v-for="(option, optionIndex) in contentPiece.product_bundle_option.products"
                      :key="'option' + optionIndex"
                    >
                      <td>{{ option | getIsoSku }}</td>
                      <td>{{ option.qty }}</td>
                    </tr>
                  </table>
                </div>
                <div
                  v-if="contentPiece.tool_bundle"
                  class="col-3"
                >
                  <div class="row">
                    <div class="col-12 py-1">
                      <strong>Hilfsmittel</strong>
                    </div>
                  </div>
                  <table class="table table-striped">
                    <tr>
                      <th>SKU</th>
                      <th>Qty</th>
                    </tr>
                    <tr
                      v-for="(tool, toolIndex) in contentPiece.tool_bundle.products"
                      :key="'tool' + toolIndex"
                    >
                      <td>{{ tool | getIsoSku }}</td>
                      <td>{{ tool.qty }}</td>
                    </tr>
                  </table>
                </div>
                <div
                  v-if="contentPiece.description"
                  class="col-6 readmore"
                >
                  <p><strong>Background Informationen</strong></p>

                  <truncate
                    clamp="mehr..."
                    :length="200"
                    class="small"
                    less="weniger"
                    type="html"
                    :text="contentPiece.description"
                  />
                </div>
                <div
                  v-if="contentPiece.instructions.length > 0"
                  class="col-6"
                >
                  <p><strong>Anleitung</strong></p>

                  <div
                    v-for="(instruction, index) in contentPiece.instructions"
                    :key="index"
                    class="row"
                  >
                    <div class="col-12">
                      <b>{{ instruction.human_sort_order }} {{ instruction.title }}</b>
                      <div
                        class="mb-2"
                        v-html="instruction.description"
                      />

                      <img
                        v-for="(image, instructionIndex) in instruction.uploads"
                        :key="instructionIndex"
                        :src="getThumbnailPath(image.hashed_name)"
                        class="border img-fluid"
                        style="width: 70px; margin: 0 10px 0 0; height: auto"
                        :alt="image.hashed_name"
                        @error="replaceByDefaultThumb"
                      />
                      <hr />
                    </div>
                  </div>
                </div>
                <div
                  v-if="contentPiece.uploads.length > 0"
                  class="col-6"
                >
                  <p><strong>Referenzen</strong></p>

                  <div class="row">
                    <div
                      v-for="(image, cpUploadIndex) in contentPiece.uploads"
                      :key="cpUploadIndex"
                      class="col-3"
                    >
                      <img
                        :src="getThumbnailPath(image.hashed_name)"
                        class="border img-fluid mb-4"
                        :alt="image.hashed_name"
                        @error="replaceByDefaultThumb"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          contentPiece.briefing_renderings.length > 0 || contentPiece.briefing_show_flats.length > 0
        "
        class="row my-4"
      >
        <div class="col-12">
          <h3>3D Briefings</h3>
        </div>
      </div>

      <div class="row">
        <rendering-briefing-summary
          v-for="(briefingrendering, briefingrenderingIndex) in contentPiece.briefing_renderings"
          :key="'briefingrendering' + briefingrenderingIndex"
          :content-piece="contentPiece"
          :briefing="briefingrendering"
        />
        <rendering-briefing-summary
          v-for="(briefingshowflat, briefingshowflatIndex) in contentPiece.briefing_show_flats"
          :key="'briefingshowflat' + briefingshowflatIndex"
          :content-piece="contentPiece"
          :briefing="briefingshowflat"
        />
      </div>

      <div
        v-if="
          contentPiece.briefing_customer_instruction ||
          contentPiece.briefing_text ||
          contentPiece.briefing_video
        "
        class="row my-4"
      >
        <div class="col-12">
          <h3>COLA Briefings</h3>
        </div>
      </div>

      <div class="row">
        <cola-briefing-summary
          v-if="contentPiece.briefing_customer_instruction"
          :briefing="contentPiece.briefing_customer_instruction"
          :content-piece="contentPiece"
          title="Anleitung"
        />

        <cola-briefing-summary
          v-if="contentPiece.briefing_text"
          :briefing="contentPiece.briefing_text"
          :content-piece="contentPiece"
          title="Text"
        />

        <cola-briefing-summary
          v-if="contentPiece.briefing_video"
          :briefing="contentPiece.briefing_video"
          :content-piece="contentPiece"
          title="Video"
        />

        <cola-briefing-summary
          v-if="contentPiece.briefing_stock_image"
          :briefing="contentPiece.briefing_stock_image"
          :content-piece="contentPiece"
          title="Stock image"
        />
      </div>
    </div>
  </div>
</template>
<script>
import truncate from 'vue-truncate-collapsed';
import RenderingBriefingSummary from '@/components/shared/summary/RenderingBriefingSummary';
import ColaBriefingSummary from '@/components/shared/summary/ColaBriefingSummary';
import { getThumbnailPath } from '@/composables/Url';

export default {
  name: 'ContentPieceDetailsPrint',
  components: {
    truncate,
    RenderingBriefingSummary,
    ColaBriefingSummary,
  },
  props: {
    id: String,
    contentPiece: Object,
  },
  computed: {
    userName() {
      if (this.contentPiece.hasOwnProperty('user') && this.contentPiece.user !== null) {
        if (this.contentPiece.user.hasOwnProperty('name')) {
          return this.contentPiece.user.name;
        }
      }
      return '';
    },
  },
  methods: {
    printPage() {
      window.print();
    },
    getThumbnailPath(hashedName) {
      return getThumbnailPath(hashedName);
    },
    replaceByDefaultThumb(e) {
      e.target.src = require('@/assets/images/no-preview.jpg');
    },
  },
};
</script>

<style lang="scss">
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '@/assets/scss/_variables';

.readmore {
  button {
    border: 0;
    text-decoration: underline;
    background: none;
    outline: none;
  }
}
</style>
