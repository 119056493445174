<template>
  <div>
    <ul
      v-if="viewStyle=='List'"
      class="uploaded list-group"
    >
      <li
        v-for="(file,index) in files"
        :key="index"
        v-b-tooltip.hover.right="file.hashed_name"
        class="list-group-item listitem d-flex justify-content-between align-items-center"
      >
        <img
          :src="getThumbnailPath(file.hashed_name)"
          class="border mr-1"
          style="height:50px; width:auto;"
          @error="replaceByDefaultThumb"
        >

        <span>{{ file.file_name }}</span>
      </li>
    </ul>


    <div
      v-if="viewStyle=='Grid'"
      class="row upload-grid"
    >
      <div
        v-for="(file,index) in files"
        :key="index"
        class="col-2"
      >
        <img
          :src="getThumbnailPath(file.hashed_name)"
          class="border img-fluid"
          style="width:170px; height:auto;"
          :alt="file.hashed_name"
          @error="replaceByDefaultThumb"
        >

        <p class="small text-break text-muted mt-2">
          {{ file.file_name }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
	import clonedeep from 'lodash.clonedeep';
  import {getThumbnailPath} from "@/composables/Url";

	export default {
		name: "Uploads",
		props:{
      viewStyle: {type: String, default: 'List'},
      uploadedFiles: {type: Array, default: () => []}
		},
		data() {
			return {
				files: [],
			}
		},
		created() {
			if(this.uploadedFiles){
				this.files = clonedeep(this.uploadedFiles);
			}
		},
    methods: {
      getThumbnailPath(hashedName) {
        return getThumbnailPath(hashedName)
      },
      replaceByDefaultThumb(e){
        e.target.src = require('@/assets/images/no-preview.jpg');
      }
    }
	}
</script>

<style lang="scss" scoped>

	.uploaded {
		.list-group-item {
			padding: 0.4rem;
			font-size:0.85em;

			span {
				overflow:hidden;
				text-overflow:ellipsis;
				white-space: nowrap;
			}
		}
	}
</style>
