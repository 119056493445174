<template>
  <div
    :id="id"
    :ref="id"
  >
    <router-link
      class="back-arrow"
      :to="{ name: 'ContentPiecesList' }"
    >
      <font-awesome-icon :icon="['fas', 'chevron-left']" />
    </router-link>
    <div
      id="cp-details"
      class="container"
    >
      <div class="row mb-3">
        <div class="col-12">
          <h1 class="mb-0">
            {{ $t('i18n.headline_contentpiece') }}
          </h1>
          <p class="mb-4">
            {{ id }}
          </p>
          <div class="pb-2">
            <div class="editable">
              <label>{{ $t('i18n.label_name') }}</label>
              <input
                id="cp-title"
                v-model="form.name"
                class="form-control w-100"
                :class="getValidationClass('name')"
                type="text"
                maxlength="80"
                @change="save"
              />
              <div class="form-text text-muted small">
                {{ $t('i18n.helper_name') }}
              </div>
              <div
                class="invalid-feedback"
                v-html="getErrorMsg('name')"
              />
            </div>
          </div>
        </div>
      </div>

      <one-id-select
        class="mb-4"
        :bearer="bearer"
        :selected-id="form.internal_id"
        overlay="false"
      />

      <div class="row mb-3">
        <div class="col-12">
          <div class="pb-2">
            <div class="editable">
              <label>{{ $t('i18n.label_topic') }}</label>
              <input
                id="cp-topic"
                v-model="form.topic"
                class="form-control w-100"
                type="text"
                @change="save"
              />
              <div class="form-text text-muted small">
                {{ $t('i18n.helper_topic') }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-12 col-md-3">
          <div class="pb-2">
            <div class="editable">
              <label>{{ $t('i18n.headline_team') }}</label>
              <select
                id="cp-team"
                v-model="form.team"
                class="form-control"
                :class="getValidationClass('team')"
                @change="save"
              >
                <option :value="null">
                  {{ $t('i18n.select_placeholder') }}
                </option>
                <option
                  v-for="team in teams"
                  :key="team.name"
                  :value="team.name"
                >
                  {{ team.name }}
                </option>
              </select>
              <div
                class="invalid-feedback"
                v-html="getErrorMsg('team')"
              />
              <div class="form-text text-muted small">
                {{ $t('i18n.helper_team') }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-3">
          <div class="editable">
            <label>{{ $t('i18n.headline_room') }}</label>
            <select
              id="cp-room"
              v-model="form.room"
              class="form-control"
              :class="getValidationClass('room')"
              @change="roomUpdated($event)"
            >
              <option>{{ $t('i18n.select_placeholder') }}</option>
              <option
                v-for="room in roomTypes"
                :key="room"
                :value="room"
              >
                {{ room }}
              </option>
            </select>

            <div
              class="invalid-feedback"
              v-html="getErrorMsg('room')"
            />
          </div>
        </div>

        <div class="col-12 col-md-3">
          <div class="editable">
            <datepicker
              :errors="errors"
              :date="form.deadline"
              :settings="deadlineSettings"
              @updateEvent="deadlineUpdated"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="editable">
            <datepicker
              :errors="errors"
              :date="form.week"
              :settings="weekSettings"
              @updateEvent="weekUpdated"
            />
          </div>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-12">
          <label>{{ $t('i18n.label_description') }}</label>
        </div>
        <div class="col-12 col-lg-7 pb-3 pb-lg-0">
          <div
            id="editor"
            class="mb-2 editable h-100"
          >
            <editor
              id="cp-desc"
              :placeholder="$t('i18n.placeholder_description')"
              :initial-content="form.description"
              :active-buttons="['bold', 'strike', 'underline', 'bullet_list', 'ordered_list']"
              @blur="editorUpdated"
            />
          </div>
        </div>
        <div class="col-12 col-lg-5">
          <div class="alert-warning alert mb-0">
            <ul class="pl-2">
              <li
                v-for="(help, index) in $t('i18n.helper_description')"
                :key="index"
              >
                {{ help }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mb-2">
          <h3>{{ $t('i18n.headline_instructions') }}</h3>
          <p>{{ $t('i18n.helper_instructions') }}</p>
        </div>
      </div>

      <instruction
        id="upload-instructions"
        :instructions="form.instructions"
        :content-piece-i-d="contentPiece.id"
      />

      <div class="row mt-5 row-eq-height">
        <div class="col-12 col-lg-6 col-xl-4 mb-5">
          <products-bundle
            v-if="!contentPiece.product_bundle"
            model-to-sync-with="ContentPiece"
            :product-bundle="contentPiece.product_bundle_option"
            :parent-i-d="contentPiece.id"
            :settings="optionsProductBundleSettings"
          />
          <div v-else>
            <h3>{{ $t('i18n.headline_options_bundle') }}</h3>
            <span class="text-muted small">
              <p>
                {{ $t('i18n.not_available', { dependence: $t('i18n.headline_products_bundle') }) }}
              </p>
            </span>
          </div>
        </div>

        <div class="col-12 col-lg-6 col-xl-4 mb-5">
          <products-bundle
            v-if="!contentPiece.product_bundle_option"
            model-to-sync-with="ContentPiece"
            :product-bundle="contentPiece.product_bundle"
            :parent-i-d="contentPiece.id"
            :settings="articlesProductBundleSettings"
          />
          <div v-else>
            <h3>{{ $t('i18n.headline_products_bundle') }}</h3>
            <span class="text-muted small">
              <p>
                {{ $t('i18n.not_available', { dependence: $t('i18n.headline_options_bundle') }) }}
              </p>
            </span>
          </div>
        </div>

        <div class="col-12 col-lg-6 col-xl-4 mb-5">
          <products-bundle
            model-to-sync-with="ContentPiece"
            :product-bundle="contentPiece.tool_bundle"
            :parent-i-d="contentPiece.id"
            :settings="toolsProductBundleSettings"
          />
        </div>

        <div class="col-12 mb-2">
          <hr class="my-5" />
          <h3>{{ $t('i18n.headline_references') }}</h3>
          <span class="text-muted small"
            ><p>{{ $t('i18n.helper_references') }}</p></span
          >

          <upload
            id="upload-content-piece"
            upload-path="content-pieces"
            view-style="Grid"
            :already-uploaded-files="contentPiece.uploads"
            :uploadable-id="contentPiece.id"
          />
        </div>

        <div class="col-12 mb-6">
          <hr class="my-5" />

          <h2 class="mb-3 headline-with-badge">
            Briefings
            <span class="badge badge-pill badge-third text-white">{{
              contentPiece.briefing_renderings.length
            }}</span>
          </h2>

          <div class="row">
            <add-briefing-tile
              class="col-12 col-md-4 col-lg-3"
              :content-piece="contentPiece"
            />

            <briefing-tile
              v-for="(briefing, index) in contentPiece.briefing_renderings"
              :key="'renderingBriefing' + index"
              :briefing="briefing"
              briefing-type="rendering"
              @reloadContentpiece="reloadContentpiece"
            />

            <briefing-tile
              v-for="(briefing, index) in contentPiece.briefing_show_flats"
              :key="'showFlatBriefing' + index"
              :briefing="briefing"
              briefing-type="show_flat"
              @reloadContentpiece="reloadContentpiece"
            />

            <briefing-tile
              v-if="contentPiece.briefing_customer_instruction"
              :briefing="contentPiece.briefing_customer_instruction"
              briefing-type="customer_instruction"
              @reloadContentpiece="reloadContentpiece"
            />

            <briefing-tile
              v-if="contentPiece.briefing_video"
              :briefing="contentPiece.briefing_video"
              briefing-type="video"
              @reloadContentpiece="reloadContentpiece"
            />

            <briefing-tile
              v-if="contentPiece.briefing_text"
              :briefing="contentPiece.briefing_text"
              briefing-type="text"
              @reloadContentpiece="reloadContentpiece"
            />

            <briefing-tile
              v-if="contentPiece.briefing_stock_image"
              :briefing="contentPiece.briefing_stock_image"
              briefing-type="stock_image"
              @reloadContentpiece="reloadContentpiece"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '@/axios';
import BriefingTile from '@/components/contentpieces/briefings/Tile.vue';
import AddBriefingTile from '@/components/contentpieces/briefings/AddBriefingTile.vue';
import Editor from '@/components/shared/Editor.vue';
import ProductsBundle from '@/components/shared/ProductsBundle';
import Instruction from '@/components/shared/Instruction';
import BootstrapFormValidation from '@/mixins/BootstrapFormValidation';
import Datepicker from '@/components/shared/Datepicker';
import Upload from '@/components/shared/Upload';
import { EventBus } from '@/event';
import { mapState } from 'vuex';
import SanitizeInput from '@/mixins/SanitizeInput';
import { replaceSpecialChars, internalID } from '@/composables/Typo';
import types from '@/data/briefing-types.json';
import { RoomType, ROOM_TYPES } from '@/enum/room.enum.ts';

export default {
  name: 'ContentPieceDetailsView',
  components: {
    BriefingTile,
    AddBriefingTile,
    Editor,
    ProductsBundle,
    Instruction,
    Datepicker,
    Upload,
  },
  mixins: [BootstrapFormValidation, SanitizeInput],
  props: {
    id: String,
    contentPiece: Object,
  },
  data() {
    return {
      oneIdEventListener: null,
      briefingTypes: types,
      errors: {},
      resultLoading: true,
      optionsProductBundleSettings: {
        headlinePlaceholder: this.$t('i18n.headline_options_bundle'),
        description: '<p>' + this.$t('i18n.helper_options_bundle') + '</p>',
        productBundleType: '2',
        canBePublished: true,
      },
      articlesProductBundleSettings: {
        headlinePlaceholder: this.$t('i18n.headline_products_bundle'),
        description: '<p>' + this.$t('i18n.helper_products_bundle') + '</p>',
        productBundleType: '0',
        canBePublished: true,
      },
      toolsProductBundleSettings: {
        headlinePlaceholder: this.$t('i18n.headline_tools_bundle'),
        description: '<p>' + this.$t('i18n.helper_tools_bundle') + '</p>',
        productBundleType: '1',
        canBePublished: true,
      },
      deadlineSettings: {
        validationClass: 'deadline',
        text_label: this.$t('i18n.label_deadline'),
        text_placeholder: this.$t('i18n.placeholder_deadline'),
        text_helper: this.$t('i18n.helper_deadline'),
      },
      weekSettings: {
        type: 'week',
        validationClass: 'week',
        text_label: this.$t('i18n.label_week'),
        text_placeholder: this.$t('i18n.placeholder_week'),
        text_helper: this.$t('i18n.helper_week'),
      },
      form: {},
      bearer: '',
      roomTypes: [...ROOM_TYPES],
    };
  },
  computed: {
    ...mapState({
      teams: (state) => state.User.groups,
    }),
  },
  watch: {
    '$store.state.User.accessToken': {
      handler() {
        this.setBearerToken();
      },
    },

    contentPiece() {
      this.initForm();
    },
    'form.name': {
      handler() {
        this.form.name = replaceSpecialChars(this.form.name);
      },
      deep: true,
    },
    'form.topic': {
      handler() {
        this.form.topic = replaceSpecialChars(this.form.topic);
      },
      deep: true,
    },
  },
  created() {
    this.initForm();
  },
  destroyed() {
    window.removeEventListener('obi:oneid:v1:default:id-selected', this.oneIdEventListener);
  },
  mounted() {
    // this needs to be defined per each content piece
    // the exact func reference needs to get passed to removeEventListener(), otherwise the call cannot delete the Event Listener
    this.oneIdEventListener = (evt) => {
      this.form.internal_id = evt.detail?.selectedId?.contentId;
      this.save();
    };
    window.addEventListener('obi:oneid:v1:default:id-selected', this.oneIdEventListener);

    this.setBearerToken();
  },
  methods: {
    setBearerToken() {
      setTimeout(() => {
        this.bearer = this.$store.state.User.accessToken;
      }, 200);
    },
    initForm() {
      this.form = { ...this.contentPiece };
    },
    async save() {
      this.errors = {};
      EventBus.$emit('loadingstatus', 'show');
      try {
        await axios.put('/content-pieces/' + this.id, this.form);
        EventBus.$emit('loadingstatus', 'completed');
      } catch (error) {
        this.errors = error?.response?.data?.errors;
        EventBus.$emit('loadingstatus', 'failed');
      }
    },
    editorUpdated(input) {
      this.form.description = this.sanitize(input);
      this.save();
    },
    deadlineUpdated(val) {
      this.errors = {};
      this.form.deadline = val;
      this.save();
    },
    roomUpdated(event) {
      this.errors = {};
      this.form.room = event.target.value;
      this.save();
    },
    weekUpdated(val) {
      this.errors = {};
      this.form.week = val;
      this.save();
    },
    reloadContentpiece() {
      this.$store.dispatch('ContentPiece/GET_BY_ID', this.id);
    },
  },
};
</script>

<style lang="scss">
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '@/assets/scss/_variables';

.editor,
.editor__content,
.editor__content div {
  height: 100%;
}
</style>

<i18n>
{
  "de": {
    "i18n": {
      "headline_contentpiece": "Content Piece",
      "label_name": "Name des Content Pieces",
      "helper_name": "Der Name dieses Content Pieces (Umlaute werden automatisch ersetzt). Maximal 30 Zeichen.",
      "label_topic": "Übergeordnetes Thema",
      "helper_topic": "Das übergeordnete Thema dieses Content Pieces (Umlaute werden automatisch ersetzt)",
      "label_internal_id": "Content Piece ID",
      "placeholder_internal_id": "z.B. J_12345678",
      "helper_internal_id": "Die interne \"J\" ID. Maximal 10 stellig.",
      "label_deadline": "Deadline",
      "placeholder_deadline": "Klicken für Datumsauswahl",
      "helper_deadline": "Die Deadline für das ContentPiece. Wirkt sich auch auf Briefings aus, wenn diese keine separate Deadline haben.",
      "label_week": "Ausspielungswoche",
      "placeholder_week": "Klicken für Wochenauswahl",
      "helper_week": "In welcher Kalenderwoche soll das Content Piece ausgespielt werden?",
      "label_description": "Background-Informationen",
      "placeholder_description": "Beantworte die Fragen auf der rechten Seite um gute Background-Informationen zu liefern.",
      "helper_description": [
        "Inwiefern kann ich dabei besonders kreativ werden?",
        "Hat das Projekt einen besonders hohen Individualisierungsfaktor - warum?",
        "Warum ist die Umsetzung ein besonderes Erlebnis, das man ausprobieren sollte – welcher Schritt warum?",
        "Handelt es sich um ein besonderes Material? Was spricht für das Material?",
        "In welcher Variante und/ oder für welchen UseCase wird die Idee gezeigt? Warum wurde diese Kombination, Farbgebung, o.ä. gewählt?"
      ],
      "headline_instructions": "Anleitung",
      "helper_instructions": "Wie wird dieses Content Piece gebaut?",
      "headline_briefings": "Briefings",
      "headline_options_bundle": "Optionen",
      "helper_options_bundle": "<strong>Optionen</strong> stehen dem Kunden zur <strong>Auswahl</strong> und werden in der Regel nicht zusammen erworben -<strong> Entweder/Oder Entscheidung</strong> (z.B. die 5 top-Grills)",
      "headline_products_bundle": "Materialien",
      "helper_products_bundle": "<strong>Materialien</strong> beschreiben Artikel die einen logischen Zusammenhang haben und idR als <strong>Bundle</strong> gekauft werden (z.B. die 5 Artikel für den Bau des Hochbeets)",
      "headline_tools_bundle": "Hilfsmittel",
      "helper_tools_bundle": "Die <strong>Hilfsmittel</strong> sind darüber hinaus empfohlene OBI Artikel, die viele schon Zuhause haben (z.B. Pinsel) – Bei Hey OBI nicht im Produkt-Slider",
      "headline_references": "Referenzen (Uploads)",
      "helper_references": "Lade hier Bilder oder Dokumente hoch",
      "select_placeholder": "- Bitte wählen -",
      "headline_team": "Team",
      "helper_team": "Welches Team ist hauptsächlich für dieses ContentPiece verantwortlich?",
      "not_available": "Nicht mehr verfügbar durch Abhängigkeit \"{dependence}\"",
      "headline_room": "Raum"
    }
  }
}
</i18n>
